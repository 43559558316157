import { Dispatch, SetStateAction, useState } from 'react';

export type AuthState =
  | 'login'
  | 'confirmation'
  | 'forgotPassword'
  | 'resetPassword'
  | 'changePassword';

export type UseAuthStateReturnValue = {
  setAuthState: Dispatch<SetStateAction<AuthState>>;
  authState: AuthState;
  setUsername: Dispatch<SetStateAction<string | null>>;
  username: string | null;
};

export const useAuthState = (): UseAuthStateReturnValue => {
  const [authState, setAuthState] = useState<AuthState>('login');
  const [username, setUsername] = useState<string | null>(null);

  return {
    authState,
    setAuthState,
    username,
    setUsername,
  };
};
