//

const sharedFetcherDatabase = {};
export default sharedFetcherDatabase;

export const registerSharedFetcher = (id, fetchResults) => {
  sharedFetcherDatabase[id] = fetchResults;
  return {
    id,
  };
};
