export const determineByID = arr =>
  arr.reduce(
    (x, y) => ({
      ...x,
      [y.id]: y,
    }),
    {},
  );

export const determineAllRoles = arr => arr.map(elem => elem.id);

export const determineByPermissions = (byID, permissions) =>
  permissions.reduce((acc, current) => {
    const roles = [];
    for (const id in byID) {
      if (byID[id].permissionSet.includes(current)) {
        roles.push(byID[id].role);
      }
    }
    return {
      ...acc,
      [current]: roles,
    };
  }, {});

export const getAllPermissions = byID => {
  const allPermissions = [];
  Object.keys(byID).forEach(key => {
    const permissionSet = byID[key].permissionSet;
    permissionSet.forEach(p => {
      if (!allPermissions.includes(p)) {
        allPermissions.push(p);
      }
    });
  });
  return allPermissions;
};
