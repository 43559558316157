import produce from 'immer';

import { KycStatusResponse } from '../../types';
import { KycStatusAPIResponse } from './useUserKycStatus';

export const transformKycStatus = (data: KycStatusAPIResponse): KycStatusResponse => {
  return produce<KycStatusAPIResponse, KycStatusResponse>(data, draft => {
    if (data.kyc?.approved?.timestamp && draft.kyc.approved) {
      draft.kyc.approved.timestamp = new Date(data.kyc.approved.timestamp);
    }

    if (data.kyc?.accepted?.timestamp && draft.kyc.accepted) {
      draft.kyc.accepted.timestamp = new Date(data.kyc.accepted.timestamp);
    }

    if (data.kyc?.created && draft.kyc) {
      draft.kyc.created = new Date(data.kyc.created);
    }

    if (data.kyc?.updated && draft.kyc) {
      draft.kyc.updated = new Date(data.kyc.updated);
    }

    draft.documents = draft.documents.map(doc => {
      return {
        ...doc,
        timestamp: new Date(doc.timestamp),
      };
    });
  }) as unknown as KycStatusResponse;
  // Casting because the Immer typings don't seem to support using this as a transformer
};
