import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List } from '@mui/material';
import {
  createElement,
  ElementType,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';

import { useToggle } from '../../hooks';
import { GlobalNavbarDrawerContext } from './DrawerOpenProvider';
import { useRecursivePermissions } from './hooks';
import { useChildrenActive } from './hooks/useChildrenActive';
import { NavListItem } from './NavListItem';
import { NavbarListItemIcon } from './NavListItemIcon';
import { NavListItemText } from './NavListItemText';
import { NavProps, Variant } from './types';
import { getListItemColor } from './utils';

const NavExpandList = styled(List)`
  padding-top: 0;
  padding-bottom: 0;
`;

const NavExpandLess = styled(ExpandLess)<{ variant: Variant }>`
  color: ${getListItemColor};

  height: 16px;
  width: 16px;
`;

const NavExpandMore = styled(ExpandMore)<{ variant: Variant }>`
  color: ${getListItemColor};

  height: 16px;
  width: 16px;
`;
export interface NavDropdownProps<C extends ElementType> extends NavProps {
  component: C;
  path?: string;
  variant: Variant;
}

export function NavDropdown<C extends ElementType>(
  props: PropsWithChildren<NavDropdownProps<C>>,
): ReactElement | null {
  const { component, subRoutes = [], icon, text, variant } = props;
  const { isDrawerOpen, setDrawerOpen } = useContext(GlobalNavbarDrawerContext);

  const [isExpanded, toggleIsExpanded, setIsExpanded] = useToggle();
  const areChildrenActive = useChildrenActive({
    subRoutes,
  });

  useEffect(() => {
    if (!isDrawerOpen) {
      setIsExpanded(false);
    } else if (areChildrenActive) {
      setIsExpanded(true);
    }
  }, [areChildrenActive, isDrawerOpen, setIsExpanded]);

  const hasPermission = useRecursivePermissions(props);

  // Clicking on a list while the drawer is closed, should cause it to open,
  // so that the user can see the dropdown immediately.
  const handleClick = useCallback(() => {
    setDrawerOpen(true);
    toggleIsExpanded();
  }, [setDrawerOpen, toggleIsExpanded]);

  if (!hasPermission) {
    return null;
  }

  const iconComponent = icon ? createElement(icon) : null;

  let parentVariant;
  switch (variant) {
    case 'secondary':
      parentVariant = 'primary' as Variant;
      break;
    case 'tertiary':
      parentVariant = 'secondary' as Variant;
      break;
    default:
      parentVariant = 'primary' as Variant;
  }

  const navItems = subRoutes.map(config =>
    createElement(component, { ...config, variant, key: config.text }),
  );

  return (
    <NavExpandList>
      <NavListItem
        button
        isActive={areChildrenActive}
        onClick={handleClick}
        variant={parentVariant}
      >
        {variant === 'primary' && (
          <NavbarListItemIcon variant={parentVariant} title={text}>
            {iconComponent}
          </NavbarListItemIcon>
        )}
        <NavListItemText primary={text} variant={parentVariant} />
        {isExpanded ? (
          <NavExpandLess variant={parentVariant} />
        ) : (
          <NavExpandMore variant={parentVariant} />
        )}
      </NavListItem>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {navItems}
      </Collapse>
    </NavExpandList>
  );
}
