import { useQuery } from '@tanstack/react-query';
import { defaultFeatureToggle, FeatureToggle } from 'src/app/constants';
import { fetch } from 'src/app/services';

import { useEnvironment } from '../useEnvironment';

let internalFeatureToggle = defaultFeatureToggle;

const url = new URL('/api/feature-toggles', window.location.origin);

export const useFeatureToggle = (): { data: FeatureToggle; isLoading: boolean } => {
  const { value: currentEnv } = useEnvironment();

  const { data, isLoading } = useQuery<FeatureToggle>({
    queryKey: ['feature-toggles', currentEnv],
    queryFn: async () => {
      const res = await fetch(url.href);
      return (await res.json()).data as FeatureToggle;
    },
  });

  if (data) {
    internalFeatureToggle = data;

    return { data, isLoading };
  }

  // When the default feature toggle values are used for local devleopment the
  // `isLoading` property needs to be explicitly set to false to prevent the
  // unresolved value returned by the query from creating an infinite fetch loop.
  return {
    data: internalFeatureToggle,
    isLoading: false,
  };
};
/**
 * Use for non-React areas for feature toggle access
 * @returns {FeatureToggle}
 */
export const getFeatureToggles = (): FeatureToggle => internalFeatureToggle;
