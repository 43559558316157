import { isArray } from 'lodash';
import { useEnvironment } from 'src/app/hooks';

import { Permission } from '../constants/permissions';
import { useWhoAmI } from './useWhoAmI';

export const usePermission = (
  permissions: Permission | null | Array<Permission | null>,
): boolean => {
  const env = useEnvironment();
  const { data, isLoading } = useWhoAmI();

  // Loading, will catch up quickly and re-render. We don't want to kick the user out irreversibly while loading.
  if (isLoading) {
    return true;
  }

  const adminRights = data?.role.adminRights ?? [];
  const permissionSet = data?.role.datasrcPermissionGroups[env?.value];

  let permissionsArray = permissions;
  if (!isArray(permissions)) {
    permissionsArray = [permissions];
  }

  // Un-permissioned item
  if (permissionsArray?.length === 0) {
    return true;
  }

  if (!adminRights) {
    return false;
  }
  if (adminRights.some((adminRight: Permission) => permissionsArray?.includes(adminRight)))
    return true;

  if (!permissionSet) {
    return false;
  }

  return (permissionSet.permissionList as Permission[]).some(
    permission => permissionsArray?.includes(permission),
  );
};
