import { CopyText } from './copyText';

const { ACCOUNT_ERRORS, BALANCE_ERRORS, SUCCESSFUL, VALIDATION_ERRORS } = CopyText;

export const BatchViewDropdownMap = {
  accountErrors: ACCOUNT_ERRORS,
  balanceErrors: BALANCE_ERRORS,
  successful: SUCCESSFUL,
  validationErrors: VALIDATION_ERRORS,
};

export const BatchTypes = {
  FINTRAN_ADD: 'FINTRAN_ADD',
};

export const BatchPreviewViews = {
  ACCOUNT_ERRORS: 'accountErrors',
  BALANCE_ERRORS: 'balanceErrors',
  SUCCESSFUL: 'successful',
  VALIDATION_ERRORS: 'validationErrors',
};

export const STATUS_CHANGE_REASON = [
  'ACAT',
  'LEGAL_AML',
  'DUPLICATE_ACCOUNT',
  'DECEASED',
  'ACCOUNT_TRANSFERRED',
  'MARGIN_CALLS',
  'PAYMENT_FAILURE',
  'FINRA_RESTRICTIONS',
  'PDT_RESTRICTIONS',
  'PARTNER_RESTRICTED',
  'COMPLIANCE_RESTRICTED',
  'OTHER',
];

export const ACCOUNT_EXCLUSION_REASON = [
  'ESCHEATMENT',
  'CANCEL_REBILL',
  'OFAC_SANCTIONED',
  'LEVY',
  'ESTATE_ACCOUNT',
  'OTHER',
];
