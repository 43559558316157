import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';
import { Instrument } from 'src/app/types';

const endpoint = new URL('/api/instruments', window.location.origin).href;

export const useInstruments = <T = Instrument[]>(
  options?: UseQueryOptions<Instrument[], Error, T>,
): UseQueryResult<T, Error> => {
  return useQuery<Instrument[], Error, T>({
    queryKey: ['instruments', 'equity'],
    queryFn: async ({ signal }) => {
      const instruments = await fetch(endpoint, { signal });
      return instruments.json() as Promise<Instrument[]>;
    },

    ...options,
  });
};
