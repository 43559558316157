//
import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  showDeleteModal: false,
};

const handlers = {
  TOGGLE_DELETE_BENEFICIARIES_MODAL: (state, action) =>
    immer(state, draftState => {
      draftState.showDeleteModal = !state.showDeleteModal;
    }),
};

export const beneficiariesPageReducer = generateReducer(initialState, handlers);
