import { getCompleteAccountNo } from 'common/utils/getCompleteAccountNo';
import Account from 'services/modules/Account';

const REGEX_ID = /^[A-Za-z\d]{8}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{12}\.\d{13}$/;
const REGEX_NO = /^([A-Za-z\d]{4})(\d{1,6})$/;

const REGEX_COMBO = new RegExp(
  '^' +
    REGEX_ID.source.substring(1, REGEX_ID.source.length - 1) +
    '|' +
    REGEX_NO.source.substring(1, REGEX_NO.source.length - 1) +
    '$',
);

export default {
  id: 'ACCOUNT',
  label: 'Accounts',
  applicableQueries: REGEX_COMBO,
  maxSuggestions: 3,
  fetchResults: (query, settings) => {
    if (REGEX_ID.test(query)) {
      return Account.get(query)
        .then(account => [
          {
            path: `/users/${account.userID}/accounts/${account.id}`,
            primary: account.accountNo,
            secondary: account.accountType.description,
            tertiary: account.parentIB?.name ?? '',
          },
        ])
        .catch(() => []);
    }

    const accountNo = getCompleteAccountNo(query);
    if (!accountNo) return Promise.resolve([]);

    return Account.getByAccountNo(accountNo)
      .then(account => [
        {
          path: `/users/${account.userID}/accounts/${account.accountID}${settings.usersNavAccount}`,
          primary: account.accountNo,
          secondary: `${account.firstName} ${account.lastName}`,
        },
      ])
      .catch(() => []);
  },
  tips: ['Did you know? You can type a shortened account number, like "DWJJ5".'],
};
