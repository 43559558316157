import { ReactNode } from 'react';

import { AuthContext } from './context';
import { useAuth } from './hooks';

export interface AuthProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProps): JSX.Element => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
