export const CopyText = {
  ACCOUNT_ADDED_SUCCESSFULLY: 'Account Added Successfully',
  ACCOUNT_ERRORS: 'Account Errors',
  ACCOUNTS: 'Accounts',
  ACCOUNT_NO: 'Account No.',
  ACH: 'ACH',
  ACTIVE: 'Active',
  ADD_NEW_PARTNER: 'Add New Partner',
  ADD_NEW_POSITION: 'Add New Position',
  ADD_NEW_TEAM_MEMBER: 'Add New Team Member',
  ADD_NEW_TRANSACTION_TYPE: 'Add New Transaction Type',
  ALL: 'All',
  ALLOCATIONS: 'Allocations',
  APPROVE: 'Approve',
  APPROVED: 'Approved',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_PDT: 'Are you sure you want to delete this pattern day trade?',
  AUTHORIZED_USERS: 'Authorized Users:',
  AUTOPILOT: 'Autopilot',
  BALANCE_ERRORS: 'Balance Errors',
  BANK_ACCOUNTS: 'Bank Accounts',
  CALL_AMOUNT: 'Call $',
  CANCEL: 'Cancel',
  CASH: 'CASH',
  CHANGES_REQUIRE_CM_REQUEST: 'Changes to this data require a CM Request',
  CHANGE_ROLE: 'Change Role',
  CHANGE_STATUS: 'Change Status',
  CHANGING_ROLE_OF_PARTNERS_MEMBER:
    "You are attempting to change the role of a member of a Partner's team!",
  CLOSE: 'Close',
  COMPLIANCE: 'Compliance',
  CORRESPONDENT_REPORT: 'Correspondent Report',
  CREATED: 'Created',
  CREATE_BENEFICIARIES: 'Create Beneficiaries',
  CREATE_TRANSACTION_TYPE: 'Create Transaction Type',
  CREATING_PARTNERS_MEMBER: "You are attempting to create a member of a Partner's team!",
  DATE_TIME: 'Date/Time',
  DELETE_INSTRUMENT: 'Delete Instrument',
  DEPOSIT: 'Deposit',
  DESELECT: 'Deselect',
  DETAILS: 'Details',
  DISCLAIMERS: `Disclaimers`,
  DISCLIAMERS_PRETEXT: `The following instrument list reflects investment products and securities that are currently supported by the DriveWealth Platform.`,
  DISCLAIMERS_ONE: `DriveWealth, in its sole discretion, may periodically amend the instrument list.`,
  DISCLAIMERS_TWO: `DriveWealth is not responsible for the selection of particular investment products and securities offered
    by any Introducing Party.`,
  DISCLIAMERS_THREE: `DriveWealth does not provide tax, suitability, or investment advice of any kind.`,
  DISCLAIMERS_FOUR: `Certain types of investment products and particular securities, including those on the instrument list,
    may not be available in all jurisdictions and may be subject to trading restrictions or other limitations.`,
  DISCLAIMERS_FIVE: `The Introducing Firm is solely responsible for selecting the investment products and securities to be
    made available to its customers via its front-end platform and for any suitability determinations related
    to such selections.`,
  DISCLAIMERS_SIX: `The Introducing Firm is solely responsible for determinations that selected investment products and
    securities comply with the Introducing Firm's Applicable Law. Prior to enabling trading in such
    products, DriveWealth may request additional support from the Introducing Firm that selected
    investment products and securities comply with Applicable Law.`,
  DISCLAIMERS_SEVEN: ` All inquiries regarding the instrument list should be directed to the Introducing Firm’s designated DriveWealth
    representative. Pricing terms and other conditions may vary for new instruments.
  `,
  DISPLAY_NOTES: 'Display Notes',
  DOC_KYC: 'Documentary Verification',
  DONE: 'Done',
  EDIT: 'Edit',
  EDIT_PARTNER: 'Edit Partner',
  EDIT_TEAM_MEMBER: 'Edit Team Member',
  EDIT_TRANSACTION_TYPE: 'Edit Transaction Type',
  END_DATE: 'End Date',
  END_OF_DAY_REPORT: 'End of Day Report',
  ENROLLED: 'Enrolled',
  ENTER_CONFIRM_NEW_PASSWORD: 'Enter and confirm a new password',
  ENTER_CHAR_RESTRICT: 'Enter at least 3 characters to start searching',
  ENTER_PASSWORD_CODE: 'Enter The Password Reset Code',
  ENTER_PASSWORD_RESET_CODE_LABEL: "Enter the password reset code we sent to the user's email",
  ENTER_USERNAME_LABEL: 'Reset Back Office password for user',
  ENTITY_TOKEN: 'Entity Token',
  EQUITY_FRACTION: 'Current %',
  EQUITY_REQUIRED: 'Required $',
  ERROR_HEADER: 'An Error has Occurred',
  EXECUTED_AWAY_ORDER: 'Executed Away Order',
  EXPORT_ALL_ACCOUNT_AND_BALANCE_ERRORS: 'Export all Account and Balance Errors',
  EXPORT_ALL_CSV: 'Export all as CSV',
  EXPORT_ORIGINAL_FILE: 'Export file with errors',
  EXPORT_SUCCESSFUL: 'Export Successful',
  FILTER_BY_MONEY_TYPE: 'Filter by Money Type',
  FILTER_BY_PARTNER: 'Filter by Partner',
  FILTER_BY_ROLE: 'Filter by Role',
  FILTER_BY_STATUS: 'Filter by Status',
  FINTRANTYPE_CREATED: 'A new WLP FinTran Type has been successfully created.',
  FINTRANTYPE_UPDATED: 'The WLP FinTran Type you selected has been successfully updated.',
  FPSL: 'Fully Paid Securities Lending',
  FRONT_ALL_CAPS: 'FRONT',
  FUNDS: 'Funds',
  GENERATE_REPORT: 'Generate Report',
  GET_REPORT: 'Get Report',
  GFV_MESSAGE: `This Account has been restricted for 90 days due to non-compliance with GFV protocol. 
  This Account will be reactivated in 90 days from the last violation.`,
  GO: 'GO',
  ID: 'ID',
  INACTIVE: 'Inactive',
  INSTRUMENTS_LIST: 'Instruments List',
  INSTRUMENTS_LIST_DOWNLOAD: 'instruments_list',
  INSTRUMENT_SUCCESSFULLY_DELETED: 'Instrument Successfully Deleted',
  KYC_CREATED: 'KYC Created',
  KYC_UPDATED: 'KYC Updated',
  KYC_VERIFICATION_THANKS:
    'Thank you for submitting your KYC ' + 'verification. Verifications are not ' + 'immediate.',
  LAST_EVALUATION_TOKEN: 'Last Evaluation Token',
  LIQUIDATE_ALL_POSITIONS: 'Liquidate All Positions',
  LOADING_LATEST_REPORTS: 'Loading latest reports',
  MANAGE_PARTNERS: 'Manage Partners',
  MANAGE_TEAM: 'Manage Team',
  MARGIN_CALL_SORT_PLACEHOLDER: 'Sort Margin Calls by...',
  MARGIN_CALL_TITLE: 'Margin Calls',
  MONEY: 'Money',
  MONEY_TYPE: 'Money Type',
  MULTI_FACTOR_AUTHENTICATION_OPTIONS: 'Multi-Factor Authentication Options',
  NEW_NOTE: 'New note',
  NEW_ORDER: 'New Order',
  NO: 'No',
  NOTES: 'Notes',
  NOT_ENROLLED: 'Not Enrolled',
  NOT_SUBMITTED: 'Not Submitted',
  NO_BATCH_ERRORS_READY_TO_SUBMIT:
    'No errors, you can proceed to submit the ' + 'batch using the Submit Transactions Button',
  NO_DOC_KYC: 'No Documentation Verification',
  NO_DOC_SUBMITTED: 'No documents will be submitted for KYC verification',
  NO_FINTRAN_BATCH_ERRORS_READY_TO_SUBMIT:
    'No account or balance errors, you can proceed to ' +
    'submit the batch using the Submit Transactions ' +
    'Button',
  NO_MARGIN_DATA_AVAILABLE: 'No margin data available',
  NO_MARGIN_DATA_SUGGESTION:
    'This usually happens when an account has a leverage of 1 ' +
    'but is a margin account, please check the Account Details',
  NUMBER_OF_REVIEWS: 'Number of Reviews',
  N_A: 'N/A',
  OPEN_ACCOUNT: 'Open Account',
  ORDER_DATE: 'Order Date',
  ORDER_NO: 'Order No.',
  OTHER: 'Other',
  OTHER_PLEASE_SPECIFY: 'Other. Please Specify',
  PARTNER: 'Partner',
  PARTNER_CREATED: 'A new Partner has been successfully created.',
  PARTNER_PROFILE_SETTINGS: 'Partner Profile Settings',
  PARTNER_UPDATED: 'The Partner you selected has been successfully updated.',
  PASSWORD_RESTRICTIONS_LABEL:
    '(Password must be more than 8 characters long ' +
    'and include numbers and special characters)',
  PASSWORD_SUCCESSFULLY_UPDATED: 'Password successfully reset for ',
  PENDING: 'Pending',
  PERCENTAGE: 'Percentage',
  PLEASE_CLICK_HERE_TO_EXIT: 'Please click here to exit.',
  PLEASE_ENTER_A_DESCRIPTION: 'Please enter a Description',
  PLEASE_ENTER_BATCH_CODE: 'Please enter Batch Code',
  PLEASE_ENTER_CLEARING_ACCOUNT: 'Please enter Clearing Account',
  PLEASE_ENTER_COMPANY_NAME: 'Please enter Company Name',
  PLEASE_ENTER_CURRENCY: 'Please enter Currency',
  PLEASE_ENTER_FINTRANTYPE_ID: 'Please enter FinTranTypeID',
  PLEASE_ENTER_GL_CODE: 'Please enter glCode',
  PLEASE_ENTER_GL_SUB_CODE: 'Please enter glSubCode',
  PLEASE_ENTER_MONEY_TYPE: 'Please enter Money Type',
  PLEASE_ENTER_PARTNER_ID: 'Please enter Partner ID',
  PLEASE_ENTER_WLP_FINTRANTYPE_ID: 'Please enter wlpFinTranTypeID',
  PLEASE_ENTER_WLP_ID: 'Please enter wlpID',
  PLEASE_FIX_AND_RETRY: 'Please Fix Errors and Retry',
  POPOUT: 'Popout',
  PORTFOLIOS: 'Portfolios',
  PROCESS_ITEM: 'Process Item',
  PRODUCTS: 'Products',
  QUANTITY: 'Quantity',
  RECO_DATE: 'Reco Date',
  RECONCILIATIONS: 'Reconciliations',
  RECURRING: 'Recurring',
  REJECTED: 'Rejected',
  REMOVE: 'Remove',
  RESEND: 'Resend',
  RESUBMIT: 'Resubmit',
  RESET_YOUR_PASSWORD: "Reset A Back Office User's Password",
  RETURN: 'Return',
  RE_VERIFY_KYC: 'Re-verify KYC',
  RE_VERIFY_WITHOUT_DOCUMENTS:
    'Re-verify without documents or exit the modal and ' +
    'upload a valid KYC document below then try again.',
  REVIEW_COMMENTS: 'Review Comments',
  ROLE: 'Role',
  ROLE_CHANGED: 'Role changed',
  SAVE: 'Save',
  SEARCH_BY_FINTRANTYPE_ID: 'Search by wlpFinTranTypeID',
  SEARCH_BY_NAME: 'Search by Name',
  SELECT: 'Select',
  SELECT_DOCUMENT_FROM_BELOW: 'Please select a document from below for KYC Re-verification',
  SELECT_DOC_OR_NON_DOC_KYC: 'Please select documentary or non-documentary verification',
  SELECT_FOR_KYC: 'Select For KYC Re-verification',
  SEND_PASSWORD_CODE_BUTTON: 'Send Password Reset Code',
  SETTLE: 'Settle',
  SETTLEMENTS: 'Settlements',
  SET_NEW_PASSWORD_BUTTON: 'Set New Password',
  SHARES: 'shares',
  SIDE: 'Side',
  SMS_IS_DEFAULT_IF_UNCHECKED: 'If left unchecked, SMS will be used as the default.',
  SPECIFY_REASON_FOR_CLOSING: 'Please Specify the Reason for Closing the Account',
  SPECIFY_REASON_FOR_RE_OPENING: 'Please Specify the Reason for Re-opening the Account',
  SSN: 'SSN',
  START_DATE: 'Start Date',
  STATUS: 'Status',
  STATUS_CHANGED_SUCCESSFULLY: 'Status changed successfully.',
  SUBMIT_BATCH_TRANSACTIONS: 'Submit Batch Transactions',
  SUBMIT_BATCH_WITH_NEGATIVES: 'Submit Batch Transactions, Including Negatives',
  SUBSCRIPTIONS: 'Subscriptions',
  SUCCESS: 'Success!',
  SUCCESSFUL: 'Successful',
  SUCCESSFULLY_SUBMITTED: 'Successfully Submitted',
  SYMBOL: 'Symbol',
  TEAM_MEMBER_EDITED: 'Team Member has been edited',
  THE_PREFERRED_METHOD_IS_SMS: 'The preferred method is SMS.',
  THIS_PAGE_IS_READ_ONLY: 'This page is read-only',
  TICKET_AUDIT_ORDER_QTY_MODIFIED:
    'Order quantity was modified during the ' + 'fracking process due to NBBO change.',
  TIN: 'TIN',
  TOTAL_AMOUNT: 'Total Amount',
  TRANSFER_FROM: 'Transfer From',
  TRANSFER_TO: 'Transfer To',
  TYPE: 'Type',
  UPDATE: 'Update',
  UPDATED: 'Updated',
  UPLOADED: 'Uploaded',
  UNENROLLED: 'Unenrolled',
  USER_CREATED: 'User created',
  USER_INFO: 'User Info',
  VALIDATION_ERRORS: 'Validation Errors',
  VALID_KYC_DOCS: 'Valid KYC Documents are:',
  VERIFICATION_TYPE: 'Verification Type',
  VIEW_BATCH: 'View Batch',
  VIRTUAL_ACCOUNTS_ALTERNATE_NAME: 'Alternate Name',
  VIRTUAL_ACCOUNTS_CONFIG_NAME: 'Config Name',
  VIRTUAL_ACCOUNTS_CONFIRM_RETURN: 'Confirm Return',
  VIRTUAL_ACCOUNTS_NAME_CHANGE: 'Name Change',
  VIRTUAL_ACCOUNTS_CONFIG_CHANGE: 'Config Change',
  VIRTUAL_ACCOUNTS_DEPOSIT_UPDATED: 'Deposit Updated successfully',
  VIRTUAL_ACCOUNTS_PROCESS_ITEMS: 'Process Items',
  VIRTUAL_ACCOUNTS_REASON: 'Reason',
  VIRTUAL_ACCOUNTS_REASON_TO_APPROVE: 'Select a reason to approve',
  VIRTUAL_ACCOUNTS_REASON_ADD_APPROVE: 'Please add the reason to approve',
  VIRTUAL_ACCOUNTS_REASON_REMOVE: 'Please add the reason to return',
  VIRTUAL_ACCOUNTS_REASON_FOR_RETURN: 'Reason for Return',
  VIRTUAL_ACCOUNTS_FILTER_BY_ID: 'Filter by Account #',
  VIRTUAL_ACCOUNTS_TITLE: 'Virtual Accounts',
  WATCHLIST_QUESTION: 'Watchlist?',
  WIRE: 'WIRE',
  WITH_AUTHORIZED_USERS: 'with authorized users',
  WLP_FINTRAN_TYPES: 'WLP FinTran Types',
  YES: 'Yes',
  YES_TAXPAYER: 'Yes, client is a U.S. citizen or resident alien',
};
