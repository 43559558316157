import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { DisclosureAPIResponse } from '../types';

export const useGetDisclosures = <T = DisclosureAPIResponse[]>(
  options?: UseQueryOptions<DisclosureAPIResponse[], Error, T>,
): UseQueryResult<T, Error> => {
  return useQuery<DisclosureAPIResponse[], Error, T>({
    queryKey: ['disclosures'],
    queryFn: async () => {
      const endpoint = new URL(`/api/disclosures`, window.location.origin).href;

      const res = await fetch(endpoint);

      const disclosures = await res.json();
      return disclosures.disclosures;
    },

    ...options,
  });
};
