import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';

export const SemanticStyle = createGlobalStyle`
// ----------------- Input overrides ----------------- //
.ui.input {
  height: 36px !important;
  & input:hover {
    border: 1px solid #377BFC !important;
    opacity: 0.85;
    transition: all 0.1s ease-in;
  }
  & input:focus {
    border: 1px solid #0053ED !important;
    opacity: 0.85;
    transition: all 0.1s ease-in;
  }
}

.ui.input > input {
  font-size: 1.2rem;
  // this corresponds to corePrimary3 in the design system
  background-color: #3e434c !important;
  // this corresponds to white in the design system
  color: #ffffff !important;
  border-radius: 5px !important;
}


.ui.input.error input {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

.ui.right.labeled.input > div.ui.label {
   // this corresponds to corePrimary3 in the design system
  background-color: #3e434c !important;
  // this corresponds to white in the design system
  color: #ffffff !important;
}

// ----------------- Form overrides ----------------- //
.ui.form .field > label {
  color: white !important;
}

.ui.checkbox label, .ui.checkbox   + label {
  color: white !important;
}

.ui.labeled.input:not([class*='corner labeled']) .label:first-child + input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ui[class*='right labeled'].input > input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ui.labeled.input:not([class*='corner labeled']) .label:first-child + input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ui[class*='right labeled'].input > input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"] {
  font-family: Space Grotesk !important;
  color: #ffffff !important;
  background-color: #3e434c !important;
  border-radius: 5px !important;
}

// ----------------- Menu overrides ----------------- //
.ui.inverted.pointing.menu .active.item:after {
    background-color: #444b52 !important;
}


.item.disabled {
    color: inherit !important;
}


.ui.inverted.menu .item.disabled, .ui.inverted.menu .item.disabled:hover {
        color: inherit !important;
}

// ----------------- Message overrides ----------------- //
.ui.message p {
  opacity: 1 !important;
}

.ui.success.message,
.ui.positive.message,
.ui.error.message,
.ui.warning.message {
  border: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: white !important;

  .header {
    color: white !important;
  }
}

.ui.success.message a,
.ui.positive.message a,
.ui.error.message a,
.ui.warning.message a {
  color: white !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.ui.success.message,
.ui.positive.message {
  // maps to positive1
  background: #045441 !important;
}

.ui.error.message {
  // maps to negative1
  background: #990633 !important;
}

.ui.warning.message {
  // maps to warning1
  background: #B35600 !important;
}

// ----------------- Table overrides ----------------- //
.ui.table {
  color: inherit !important;
}

.ui.table thead th {
 color: inherit !important;
}

.ui.inverted.table {
  background-color: #242C34 !important;
}

// ----------------- Button overrides ----------------- //
.ui.button {
  font-family: 'Space Grotesk' !important;
}

.ui.primary.buttons .button, .ui.primary.button,
.ui.secondary.buttons .button, .ui.secondary.button {
  color: white !important;
  border-radius: 18px !important;
  font-weight: normal !important;
}

.ui.primary.buttons .button, .ui.primary.button {
  // maps to primaryBase color
  background-color: #0059FF !important;
}

.ui.primary.buttons .button:hover, .ui.primary.button:hover {
  // maps to accent 3
  background-color: #6DA0FF !important;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.25), inset 0 0 0 1px rgba(0,0,0,.25) !important;
}

.ui.primary.buttons .button:disabled, .ui.primary.button:disabled {
  opacity: 0.3 !important;
}

.ui.secondary.buttons .button, .ui.secondary.button {
  // maps to corePrimary 4
  background-color: #4C5159 !important;
}

.ui.secondary.buttons .button:hover, .ui.secondary.button:hover {
  // maps to corePrimary 2
  background-color: #2E333D !important;
  box-shadow: none !important;
}


.ui.buttons:not(.basic):not(.inverted)>.button:hover,
.ui.buttons>.ui.button:not(.basic):not(.inverted):hover {
	box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0,0,0,0.14) !important;
}

.ui.buttons .button:not(:last-child) {
  margin-right: -1px !important;
}

.ui.labeled.icon.buttons .button, .ui.labeled.icon.button {
  border-radius: 18px !important;
}

// ----------------- Header overrides ----------------- // 
h1, h2, h3, h4, h5 {
  font-weight: 400;
  font-family: 'Space Grotesk';
  color: #ffffff;
}

h1 {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.1px;
  text-align: left;
}

h2 {
  font-size: 33px;
  line-height: 40px;
  letter-spacing: 0.1px;
  text-align: left;
}

h3 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.1px;
  text-align: left;
}

h4 {
  font-size: 23px;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-align: left;
}

h5 {
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.1px;
  text-align: left;
}

.ui.header { 
  color: inherit !important; 
}

// ----------------- Icon overrides ----------------- //
i.red.icon {
  // maps to accentNegative Base
  color: #FF0A54 !important;
}

i.blue.icon {
 color: #FF8CD8 !important;
}

i.violet.icon {
 color: #8052FF !important;
}

i.green.icon {
  color: #00D390 !important;
}

i.teal.icon {
  color: #2E9BBD !important;
}

i.orange.icon {
  color: #FF9500 !important;
}

// ----------------- Label overrides ----------------- //
.ui.violet.labels .label, .ui.violet.label {
  background-color: #8052FF !important;
  border-color: #8052FF !important;
  color: #FFFFFF !important;
}

.ui.red.labels .label, .ui.red.label {
  background-color: #FF0A54 !important;
  border-color: #FF0A54 !important;
  color: #FFFFFF !important;
}

.ui.green.labels .label, .ui.green.label {
  background-color: #00D390 !important;
  border-color: #00D390 !important;
  color: #FFFFFF !important;
}

.ui.blue.labels .label, .ui.blue.label {
  background-color: #2E9BBD !important;
  border-color: #2E9BBD !important;
  color: #FFFFFF !important;
}

.ui.teal.labels .label, .ui.teal.label {
  background-color: #86E3C6 !important;
  border-color: #86E3C6 !important;
  color: #045441 !important;
}

.ui.orange.labels .label, .ui.orange.label {
  background-color: #FF881A !important;
  border-color: #FF881A !important;
  color: #FFFFFF !important;
}

.ui.labeled.input:not([class*='corner labeled']) .label:first-child + input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.transaction-tag > .ui.basic.teal.label,
.ui.basic.teal.label {
  background: none #A2E1F4 !important;
  color: #125367 !important;
  border-color: #A2E1F4!important;
}

.transaction-tag > .ui.basic.teal.labels a.label:hover,
.transaction-tag > a.ui.basic.teal.label:hover,
.ui.basic.teal.labels a.label:hover,
a.ui.basic.teal.label:hover {
  background-color: #125367 !important;
  color: #A2E1F4!important;
  border-color: #125367 !important;
}

.transaction-tag > .ui.basic.green.label,
.ui.basic.green.label {
  background: none #86E3C6 !important;
  color: #045441 !important;
  border-color: #86E3C6 !important;
}

.transaction-tag > .ui.basic.red.label,
.ui.basic.red.label {
  background: none #ffcedd !important;
  color: #990633 !important;
  border-color: #990633 !important;
}

.transaction-tag > .ui.basic.orange.label,
.ui.basic.orange.label {
  background: none #FFB066 !important;
  border-color: #FFB066 !important;
  color: #434652 !important;
}

.transaction-tag > .ui.basic.orange.labels a.label:hover,
.transaction-tag > a.ui.basic.orange.label:hover,
.ui.basic.orange.labels a.label:hover,
a.ui.basic.orange.label:hover {
  background-color: #B35600 !important;
  color: #ffffff !important;
  border-color: #B35600 !important;
}

.transaction-tag > .ui.basic.violet.label,
.ui.basic.violet.label {
  background-color: #E8D5FF !important;
  color: #8E025E !important;
  border-color: #E8D5FF !important;
}

.transaction-tag > .ui.basic.violet.labels a.label:hover,
.transaction-tag > a.ui.basic.violet.label:hover,
.ui.basic.violet.labels a.label:hover,
a.ui.basic.violet.label:hover {
  background-color: #4A1E9E !important;
  color: #ffffff !important;
  border-color: #4A1E9E !important;
}

.transaction-tag > .ui.basic.blue.label,
.ui.basic.blue.label {
  background-color: #FF8CD8 !important;
  color: #FFFFFF !important;
  border-color: #FF8CD8 !important;
}

.transaction-tag > .ui.basic.blue.labels a.label:hover,
.transaction-tag > a.ui.basic.blue.label:hover,
.ui.basic.blue.labels a.label:hover,
a.ui.basic.blue.label:hover {
  background-color: #8E025E !important;
  color: #ffffff !important;
  border-color: #8E025E !important;
}

// ----------------- Segment overrides ----------------- // 
.ui.inverted.segment {
  // this corresponds to corePrimary2 in the design system
  background-color: #2e333d !important;
}

// ----------------- Reset overrides ----------------- // 
button {
  font-family: 'Space Grotesk' !important;
}

b,
strong {
  font-weight: 700;
}

// ----------------- Accordion overrides ----------------- // 
.ui.accordion .title:not(.ui) {
  color: #fff !important;
}

// ----------------- Dimmer overrides ----------------- // 
.ui.dimmer .modal > .content > .ui.loader {
  &:before {
    border-color: rgba(0, 0, 0, 0.1) !important;
  }

  &:after {
    border-color: #767676 transparent transparent !important;
  }
}

// ----------------- Dropdown overrides ----------------- // 
.ui.dropdown {
  height: 40px !important;
  max-height: 100%  !important;
  background-color: #3e434c !important;
}

.ui.search.dropdown.selection {
  background-color: #3e434c !important;
}

.ui.search.dropdown.selection > input.search {
  background-color: #3e434c !important;
}

.ui.selection.dropdown {
  height: 40px !important;
  min-height: 40px !important;
  background-color: #3e434c !important;
  color: #ffffff !important;
  border-radius: 5px !important;
  .search.active {
    background-color: #3e434c !important;
    color: #ffffff !important;
  }
  > input {
    background-color: #3e434c !important;
    color: #ffffff !important;
  }
  > div {
    color: #ffffff !important;
    > div {
      color: #ffffff !important;
      border-top: 0px !important;
      &:hover {
        // this corresponds to the hover state of primary3 with a 30% opacity
        background-color: rgba(55, 123, 252, 0.3) !important;
        border-radius: 5px !important;
      }
    }
  }
}

.ui.multiple.dropdown {
  padding: 0  !important;
  height: auto !important;
}

.ui.selection.button.dropdown {
  border-radius: 16px !important;
}

.ui.selection.dropdown {
  &:hover {
    border: 1px solid #377BFC  !important;
  }
  &:focus {
    border: 1px solid #0053ED !important;
  }
}

.ui.selection.active.dropdown {
    border: 1px solid #0053ED !important;
    box-shadow: 0px 8px 16px 0px rgb(9 12 15 / 40%) !important;
}

.ui.dropdown .menu > .header {
  color: #ffffff;
}

.ui.dropdown .menu > .item:hover {
  background-color: rgba(55,123,252,0.3) !important;
  color: #ffffff !important;
}

.ui.floating.dropdown .menu,
.ui.dropdown .menu {
    margin-top: 4px !important;
    // corresponds to corePrimary 3 in our design system
    background: #3e434c !important;
    box-shadow: 0px 8px 16px rgba(9, 12, 15, 0.4) !important;
    border-radius: 8px !important;
    & > .item {
      color: #fff  !important;
    }
  }

.ui.form .field.error .ui.dropdown .text {
  background: transparent !important;
}

.ui.selection.active.dropdown .menu {
    border-color: transparent !important;
    box-shadow: 0px 8px 16px 0px rgb(9 12 15 / 40%) !important;
}

.ui.selection.active.dropdown .menu {
    border-color: transparent !important;
    box-shadow: 0px 8px 16px 0px rgb(9 12 15 / 40%) !important;
}

.ui.dropdown .menu > .item:hover {
  background-color: #3c5481 !important;
}

.ui.dropdown .menu > .active.item {
  color: inherit !important;
}

.ui.pointing.dropdown > .menu:after {
  display: none !important;
}

.ui.selection.dropdown > .dropdown.icon {
  line-height: 1 !important;
}

// ----------------- Modal overrides ----------------- // 
.ui.page.modals.transition.visible {
    display: flex !important;
}

// Colors the modals to look like MUI modals
.ui.modal {
  margin-top: 0 !important;
  margin-right: auto !important;
  margin-left: auto !important;

  background-color: #2e333d !important;
  color: #ffffff !important;

  > .header {
    background-color: #2e333d !important;
    color: #ffffff !important;
  }

  > .content {
    background-color: #2e333d !important;
    color: #ffffff !important;
  }

  > .actions {
    background-color: #2e333d !important;
    color: #ffffff !important;
  }

  label {
    color: #ffffff !important;
  }
}

// ----------------- Popup overrides ----------------- // 
.ui.popup {
  border: 0;
  color: #fff !important;
  background: #3E434C !important;
}

.ui.popup:before {
  background: #3E434C !important;
  box-shadow: none !important;
}
`;
