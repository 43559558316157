import { Auth } from 'aws-amplify';
import { store } from 'src/store';

// TODO: Look at our errors and see if this is reality.
// Are our errors standardized?
export type HttpErrorOptions = {
  status: number;
  statusText: string;
  message?: string;
};

export class HttpError extends Error {
  public status: number;

  public statusText: string;

  constructor(options: HttpErrorOptions) {
    super(options.message);

    this.status = options.status;
    this.statusText = options.statusText;
  }
}

/**
 * fetch should generally be used instead of this call.
 * This call does not have the datasrc set.
 * @param input
 * @param init
 * @returns
 */
export const initialFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();
  const accessToken = currentSession.getAccessToken().getJwtToken();

  const headers = new Headers(init?.headers);
  headers.append('z-auth-token', idToken);
  headers.append('authorization', `Bearer ${accessToken}`);

  const data = await window.fetch(input, { ...init, headers });

  if (!data.ok) {
    const message = await data.text();
    throw new HttpError({ status: data.status, statusText: data.statusText, message });
  }

  return data;
};

export const fetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  // Get the current env, otherwise we need to sub and wait
  const currentEnv = await new Promise<string>(res => {
    const currentState = store.getState();

    if (currentState.settings?.ENV) {
      res(currentState.settings.ENV);

      return;
    }

    const unsub = store.subscribe(() => {
      const current = store.getState().settings.ENV;

      if (current) {
        unsub();
        res(current as string);
      }
    });
  });
  const headers = new Headers(init?.headers);
  headers.append('z-datasrc', currentEnv);

  const requestInit: RequestInit = { ...init, headers };

  return initialFetch(input, requestInit);
};
