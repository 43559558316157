import produce from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  deposits: [],
  showEditModal: null,
  showDeleteModal: false,
  depositsMap: {},
  userID: '',
};

const handlers = {
  GET_DEPOSITS_SUCCESS: (state, action) => {
    return produce(state, draft => {
      if (action.payload.deposits.length) {
        draft.deposits = action.payload.deposits.map(deposit => deposit.id);
      } else {
        draft.deposits = [];
      }
      draft.depositsMap = action.payload.deposits.reduce((acc, current) => {
        acc[current.id] = {
          data: current,
        };
        return acc;
      }, {});
      draft.userID = action.payload.userID;
    });
  },

  TOGGLE_DELETE_MODAL_SUCCESS: (state, action) => {
    const copy = { ...state };
    copy.showDeleteModal = !copy.showDeleteModal;
    return copy;
  },

  UPDATE_DEPOSIT_SUCCESS: (state, action) => {
    const copy = { ...state };
    const map = { ...copy.depositsMap };
    const merged = { ...map[action.payload.deposit.id].data, ...action.payload.deposit };
    map[action.payload.deposit.id] = { data: merged };
    copy.depositsMap = map;
    return copy;
  },

  TOGGLE_EDIT_MODAL_SUCCESS: (state, action) => {
    const copy = { ...state };
    if (copy.showEditModal) {
      copy.showEditModal = null;
    } else {
      copy.showEditModal = action.payload.depositID;
    }
    return copy;
  },

  GET_DETAILS_SUCCESS: (state, action) => {
    const copy = { ...state };
    const mapCopy = { ...copy.depositsMap };
    const details = action.payload.details;
    mapCopy[action.payload.details.id] = { data: details };
    copy.depositsMap = mapCopy;
    return copy;
  },

  DELETE_DEPOSIT_SUCCESS: (state, action) => {
    const copy = { ...state };
    const map = { ...copy.depositsMap };
    const deposit = { ...map[action.payload.depositID].data };
    deposit.active = false;
    deposit.nextDeposit = null;
    map[action.payload.depositID].data = deposit;
    copy.depositsMap = map;
    return copy;
  },

  GET_DETAILS_START: null,
  UPDATE_DEPOSIT_START: null,
  DELETE_DEPOSIT_START: null,
  GET_DETAILS_ERROR: null,
  UPDATE_ERROR: null,
  DELETE_ERROR: null,
  GET_DEPOSITS_ERROR: null,
  GET_DEPOSITS_START: null,
};

export const depositsReducer = generateReducer(initialState, handlers);
