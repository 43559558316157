import { accountClose } from './accountClose';
import { accountEdit } from './accountEdit';
import { accountExclusion } from './accountExclusion';
import { cancelRebill } from './cancelRebill';
import { cashTransfer } from './cashTransfer';
import { deposit } from './deposit';
import { executedAwayOrder } from './executedAwayOrder';
import { finTran } from './finTran';
import { instrumentAdd } from './instrumentAdd';
import { instrumentEdit } from './instrumentEdit';
import { lockUnlockBatches } from './lockUnlock';
import { optionsLiquidation } from './optionsLiquidation';
import { orderAdd } from './orderAdd';
import { orderCancel } from './orderCancel';
import { orderStatus } from './orderStatus';
import { positionAdjust } from './positionAdjust';
import { promotionCashTransfer } from './promotionCashTransfer';
import { promotionShareTransfer } from './promotionShareTransfer';
import { shareTransfer } from './shareTransfer';
const configs = {
  ACCOUNT_EDIT: accountEdit,
  RECON_ACCOUNT_EXCLUSION: accountExclusion,
  ACCOUNT_CLOSE: accountClose,
  CANCEL_REBILL: cancelRebill,
  CASH_TRANSFER: cashTransfer,
  DEPOSIT_CREATE: deposit,
  EXECUTED_AWAY_ORDER_ADD: executedAwayOrder,
  FINTRAN_ADD: finTran,
  INSTRUMENT_ADD: instrumentAdd,
  INSTRUMENT_EDIT: instrumentEdit,
  LOCK_UNLOCK_POSITION: lockUnlockBatches,
  ORDER_ADD: orderAdd,
  ORDER_CANCEL: orderCancel,
  ORDER_STATUS: orderStatus,
  POSITION_ADJUST: positionAdjust,
  PROMOTION_CASH_TRANSFER: promotionCashTransfer,
  PROMOTION_SHARE_TRANSFER: promotionShareTransfer,
  SHARE_TRANSFER: shareTransfer,
  OPTIONS_LIQUIDATION: optionsLiquidation,
};

export { configs };
