import { HubCapsule } from '@aws-amplify/core';
import { useQueryClient } from '@tanstack/react-query';
import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Watches Cognito for any sign out events and clears local storage
 */
export const useLogout = (): void => {
  const history = useHistory();
  const queryClient = useQueryClient();

  useEffect(() => {
    const authListener = (data: HubCapsule) => {
      if (data.payload.event === 'signOut') {
        history.push('/');
        queryClient.clear();
      }
    };

    Hub.listen('auth', authListener);

    return () => {
      Hub.remove('auth', authListener);
    };
  }, [history, queryClient]);
};
