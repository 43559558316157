export const shapeAccountsObj = data => {
  const accountsObj = data.accounts.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});
  return accountsObj;
};

export const shapeListByUserid = data => {
  const listObj = data.accounts.reduce((acc, current) => {
    if (!acc[current.userID]) {
      acc[current.userID] = [current.id];
    } else {
      acc[current.userID].push(current.id);
    }
    return acc;
  }, {});
  return listObj;
};

export const shapeAccountNumberToAccountID = data => {
  const mapNumToIDObj = data.accounts.reduce((acc, current) => {
    acc[current.accountNo] = current.id;
    return acc;
  }, {});
  return mapNumToIDObj;
};
