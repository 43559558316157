import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  instrumentGroupListByID: {},
  instrumentGroupList: [],
  instrumentGroupDetailsByID: {},
};

const handlers = {
  // get instrument group list actions
  GET_INSTRUMENT_GROUPS_LIST_START: null,
  GET_INSTRUMENT_GROUPS_LIST_ERROR: null,
  GET_INSTRUMENT_GROUPS_LIST_SUCCESS: (state, action) =>
    immer(state, draftState => {
      const byId = action.payload.instrumentGroupsList.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      draftState.instrumentGroupListByID = byId;
      draftState.instrumentGroupList = action.payload.instrumentGroupsList;
    }),

  // get instrument group actions
  GET_INSTRUMENT_GROUP_START: null,
  GET_INSTRUMENT_GROUP_ERROR: null,
  GET_INSTRUMENT_GROUP_SUCCESS: (state, action) =>
    immer(state, draftState => {
      draftState.instrumentGroupDetailsByID[action.payload.instrumentGroupID] =
        action.payload.instrumentGroupDetails;
    }),

  // create instrument group actions
  CREATE_INSTRUMENT_GROUP_START: null,
  CREATE_INSTRUMENT_GROUP_ERROR: null,
  CREATE_INSTRUMENT_GROUP_SUCCESS: (state, action) =>
    immer(state, draftState => {
      draftState.instrumentGroupListByID[action.payload.data.id] = action.payload.data;
      draftState.instrumentGroupDetailsByID[action.payload.data.id] = action.payload.data;
      draftState.instrumentGroupList.push(action.payload.data);
    }),

  // update instrument group actions
  UPDATE_INSTRUMENT_GROUP_START: null,
  UPDATE_INSTRUMENT_GROUP_ERROR: null,
  UPDATE_INSTRUMENT_GROUP_SUCCESS: (state, action) => {
    return immer(state, draftState => {
      draftState.instrumentGroupDetailsByID[action.payload.instrumentGroupID] =
        action.payload.details;
      const index = draftState.instrumentGroupList.findIndex(
        group => group.id === action.payload.details.id,
      );
      draftState.instrumentGroupList[index] = action.payload.details;
    });
  },
};

export default generateReducer(initialState, handlers);
