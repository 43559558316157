import { copyText } from '../internationalization';

const {
  formValidation: { isARequiredField, todayOrBefore, notWeekend },
} = copyText;

export const isRequiredField = (fieldName: string): string =>
  isARequiredField.replace('%FIELD%', fieldName);

export const isTodayOrBefore = (fieldName: string): string =>
  todayOrBefore.replace('%FIELD%', fieldName);

export const isNotWeekend = (fieldName: string) => notWeekend.replace('%FIELD%', fieldName);
