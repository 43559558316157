import { Box, DriveHubLogo } from '@drivehub/ui-components';
import { ListItemText, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { copyText } from 'src/app/internationalization';
import { AuthStateContext } from './AuthContext';
import { DWLogo } from './DWLogo';

const {
  authorization: {
    secureYourDriveHubAccountPartOne,
    secureYourDriveHubAccountPartTwo,
    passwordMustHaveAtLeast,
    sixteenCharacters,
    oneNumber,
    oneUppercaseCharacter,
    oneLowercaseCharacter,
  },
} = copyText;

const backgroundImageUrl = '/static/login-background.svg';

export type AuthFormCardProps = {
  children: React.ReactNode;
};

export type PageProps = {
  isChangePasswordPage: boolean;
};

export type AuthPageProps = AuthFormCardProps & PageProps;

export const AuthenticationPage = (props: AuthPageProps): JSX.Element => {
  const { children, isChangePasswordPage } = props;
  const theme = useTheme();
  const isSMOrBelow = useMediaQuery(theme.breakpoints.down('sm'));
  const isMDOrBelow = useMediaQuery(theme.breakpoints.down('md'));

  const { username } = useContext(AuthStateContext);

  const logoRegionHeight = isSMOrBelow ? 32 : 37;
  const formMargin = isSMOrBelow ? '10%' : 0;
  const logoWidth = isSMOrBelow ? '55vw' : '20vw';

  const flexDirection = isSMOrBelow ? 'column' : 'row';
  const logoRegionFlexBasis = `${logoRegionHeight}%`;
  const formBackground = `url(${backgroundImageUrl})`;
  const logoRegionMargin = isMDOrBelow ? 18 : 30;
  const logoMarginTop = isMDOrBelow ? 3 : 0;
  const logoMarginRight = isMDOrBelow ? 0 : 14;
  const TextBoxWidth = isMDOrBelow ? '95%' : '55%';
  const logoDWPosition = isSMOrBelow
    ? {}
    : { position: 'absolute', top: '24px', left: '48px', height: '36px' };
  const logoDWPositionChangePassword = isSMOrBelow
    ? { position: 'relative', bottom: '20px', left: '', top: '' }
    : { position: 'absolute', bottom: '', left: '48px', top: '24px' };

  return (
    <Box height="100vh" display="flex" flexDirection={flexDirection}>
      {isChangePasswordPage ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flexBasis={logoRegionFlexBasis}
          mb={logoRegionMargin}
          position="relative"
        >
          <Box sx={logoDWPositionChangePassword}>
            <a href="https://www.drivewealth.com">
            <DWLogo height="100%" width="100%" />
            </a>
          </Box>
          <Box width="37%" height="6vh" mt={logoMarginTop} mr={logoMarginRight}>
            <DriveHubLogo />
          </Box>

          <Box width={TextBoxWidth} height="6vh">
            <Typography variant="h5" mb={4}>
              {secureYourDriveHubAccountPartOne}
              <Typography variant="h5" display="inline" color="secondary">
                {username}
              </Typography>
              {secureYourDriveHubAccountPartTwo}
            </Typography>

            <Typography variant="body2">{passwordMustHaveAtLeast}</Typography>
            <Box ml={2.5}>
              <ListItemText sx={{ display: 'list-item', color: 'white', fontSize: '10px' }}>
                <Typography variant="body2">{sixteenCharacters}</Typography>
              </ListItemText>
              <ListItemText sx={{ display: 'list-item', color: 'white', fontSize: '10px' }}>
                <Typography variant="body2">{oneNumber}</Typography>
              </ListItemText>
              <ListItemText sx={{ display: 'list-item', color: 'white', fontSize: '10px' }}>
                <Typography variant="body2">{oneUppercaseCharacter}</Typography>
              </ListItemText>
              <ListItemText sx={{ display: 'list-item', color: 'white', fontSize: '10px' }}>
                <Typography variant="body2">{oneLowercaseCharacter}</Typography>
              </ListItemText>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          bgcolor="colors.corePrimaryBase"
          display="flex"
          flexDirection="column"
          justifyContent={isSMOrBelow ? 'space-between' : 'center'}
          alignItems="center"
          flexBasis={logoRegionFlexBasis}
          position="relative"
          py={4}
        >
          <Box sx={logoDWPosition}>
            <a href="https://www.drivewealth.com">
            </a>
          </Box>
          <Box width={logoWidth} height="6vh">
            <DriveHubLogo />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          background: formBackground,
          bgcolor: 'colors.corePrimaryBase',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isSMOrBelow ? 'flex-start' : 'center',
        }}
      >
        <Box mr={formMargin} ml={formMargin}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
