import account from './account';
import admin from './admin';
import advisor from './advisor';
import broker from './broker';
import client from './client';
import order from './order';
import payment from './payment';

// The order listed here will be the order displayed in search results
const resultTypes = [account, order, client, advisor, broker, admin, payment];

export default resultTypes;
