export default function httpFetch(method, endpoint, headers, body, cb) {
  fetch(endpoint, {
    method,
    headers,
    body,
  }).then(
    response => {
      const resHeaders = {};
      for (const pair of response.headers.entries()) {
        resHeaders[pair[0]] = pair[1];
      }

      const contentType = resHeaders['Content-Type'] || resHeaders['content-type'] || '';
      if (contentType === 'application/octet-stream') {
        response.blob().then(responseData => {
          cb(response.status, resHeaders, responseData);
        });
      } else {
        response.text().then(responseData => {
          cb(response.status, resHeaders, responseData);
        });
      }
    },
    error => {
      cb(-1, {}, error.toString());
    },
  );
}
