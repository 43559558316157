import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'src/app/hooks';
import { copyText } from 'src/app/internationalization';
import { fetch } from 'src/app/services';

const {
  generics: { refreshVersionSnackback },
} = copyText;

type VersionInformation = {
  version: string;
  gitHash: string;
  buildTime: string;
};

export const useCheckVersion = (): UseQueryResult<VersionInformation> => {
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  return useQuery<VersionInformation>({
    queryKey: ['version'],
    queryFn: async () => {
      const endpoint = new URL('/api/version', window.location.origin).href;

      const data = await fetch(endpoint);

      return data.json() as Promise<VersionInformation>;
    },

    onSuccess: data => {
      if (data.version !== __APP_VERSION__) {
        enqueueSnackbar(refreshVersionSnackback, {
          variant: 'info',
          persist: true,
          preventDuplicate: true,
        });
      }
    },
    refetchInterval: 1000 * 60 * 10,
  });
};
