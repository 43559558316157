import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { AcknowledgmentAPIResponse } from '../types';

export const useGetAcknowledgments = (
  userId: string | undefined,
): UseQueryResult<AcknowledgmentAPIResponse, Error> => {
  return useQuery<AcknowledgmentAPIResponse, Error>({
    queryKey: ['acknowledgments', userId],
    queryFn: async () => {
      const endpoint = new URL(
        `/api/dashboard/users/${userId}/acknowledgments`,
        window.location.origin,
      ).href;

      const res = await fetch(endpoint);

      return res.json() as Promise<AcknowledgmentAPIResponse>;
    },

    enabled: userId !== undefined,
  });
};
