import generateReducer from 'modules/generateReducer';

export const initialState = {
  isOpen: false,
  cachedAccounts: [],
  searchSide: '',
  from: {},
  to: {},
  selectedShares: [],
  statuses: {},
  batch: true,
};

const handlers = {
  ST_MODAL_OPEN: state => ({
    ...state,
    isOpen: true,
  }),
  ST_ACCOUNT_SEARCH: (state, action) => ({
    ...state,
    searchSide: action.payload.side || '',
  }),
  ST_ACCOUNT_SEARCH_ERROR: null,

  ST_SELECT_ACCOUNT: (state, action) => ({
    ...state,
    [action.payload.side]: {
      ...state[action.payload.side],
      accountNo: action.payload.accountNo,
    },
  }),

  ST_ADD_ACCOUNT: (state, action) => ({
    ...state,
    cachedAccounts: [...state.cachedAccounts, action.payload.account],
  }),

  ST_SET_DETAILS: (state, action) => ({
    ...state,
    [action.payload.side]: {
      ...state[action.payload.side],
      ...action.payload.details,
    },
  }),

  ST_SELECT_SHARE: (state, action) => ({
    ...state,
    selectedShares: [...state.selectedShares, action.payload.symbol],
  }),

  ST_DESELECT_SHARE: (state, action) => {
    const actionSymbol = action.payload.symbol;
    return {
      ...state,
      selectedShares: state.selectedShares.filter(symbol => symbol !== actionSymbol),
    };
  },

  ST_SELECT_ALL_SHARES: (state, action) => ({
    ...state,
    selectedShares: state.from.equityPositions.map(position => position.symbol),
  }),

  ST_DESELECT_ALL_SHARES: (state, action) => ({
    ...state,
    selectedShares: [],
  }),

  ST_SUCCESS: (state, action) => ({
    ...state,
    amount: 0,
    batch: true,
    statuses: action.payload.statuses,
  }),

  ST_ERROR: (state, action) => ({
    ...state,
    statuses: action.payload ? action.payload.statuses : {},
  }),

  ST_SWAP: (state, action) => ({
    ...state,
    from: state.to,
    to: state.from,
    selectedShares: [],
  }),

  ST_CLEAR: state => ({
    ...state,
    statuses: {},
    from: {},
    to: {},
    isOpen: false,
  }),

  ST_END_LOADING: null,
  ST_TRANSFER_START: null,
};

export const shareTransferReducer = generateReducer(initialState, handlers);
