//
import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  changingRoles: [],
  errors: [],
  showRolePopup: false,
  showPermissionPopup: false,
  newRoles: [],
  addRoleMode: false,
  newPermissions: [],
  addPermissionMode: false,
  addPermissionError: false,
  editRole: null,
};

const handlers = {
  SAVE_CHANGES_START: null,
  TOGGLE_PERMISSION: (state, action) =>
    immer(state, draft => {
      const incoming = action.payload.permission;
      if (incoming.roleID) {
        const roleGroup = draft.changingRoles.find(role => role.roleID === incoming.roleID);
        if (roleGroup) {
          if (roleGroup.permissionSet.includes(incoming.permission)) {
            roleGroup.permissionSet.splice(roleGroup.permissionSet.indexOf(incoming.permission), 1);
            if (!roleGroup.permissionSet.length) {
              const groupIndex = draft.changingRoles.findIndex(
                role => role.roleID === incoming.roleID,
              );
              draft.changingRoles.splice(groupIndex, 1);
            }
          } else {
            roleGroup.permissionSet.push(incoming.permission);
          }
        } else {
          draft.changingRoles.push({
            roleID: incoming.roleID,
            permissionSet: [incoming.permission],
            role: incoming.role,
          });
        }
      } else {
        const currentRoleIndex = draft.newRoles.findIndex(r => r.role === incoming.role);
        const currentRole = draft.newRoles[currentRoleIndex];
        if (currentRole.permissionSet.includes(incoming.permission)) {
          currentRole.permissionSet.splice(
            currentRole.permissionSet.indexOf(incoming.permission, 1),
          );
        } else {
          currentRole.permissionSet.push(incoming.permission);
        }
      }
    }),
  SAVE_CHANGES_END: (state, action) =>
    immer(state, draft => {
      draft.changingRoles = [];
      draft.newRoles = [];
      draft.addRoleMode = false;
      draft.addPermissionMode = false;
      draft.newPermissions = [];
    }),
  SAVE_CHANGES_ERROR: (state, action) =>
    immer(state, draft => {
      draft.errors = action.payload.errors;
    }),
  CLOSE_ERRORS_MODAL: (state, action) =>
    immer(state, draft => {
      draft.errors = [];
    }),
  TOGGLE_ROLE_POPUP: (state, action) =>
    immer(state, draft => {
      draft.showRolePopup = !draft.showRolePopup;
    }),
  TOGGLE_PERMISSION_POPUP: (state, action) =>
    immer(state, draft => {
      draft.showPermissionPopup = !draft.showPermissionPopup;
    }),
  ADD_NEW_ROLE: (state, action) =>
    immer(state, draft => {
      draft.newRoles.push(action.payload.role);
      draft.addRoleMode = true;
      draft.showRolePopup = false;
    }),
  ADD_NEW_PERMISSION: (state, action) =>
    immer(state, draft => {
      draft.newPermissions.push(action.payload.permission);
      draft.addPermissionMode = true;
      draft.showPermissionPopup = false;
    }),
  TOGGLE_PERMISSION_ERROR: (state, action) =>
    immer(state, draft => {
      draft.addPermissionError = !draft.addPermissionError;
    }),
  REMOVE_SCROLL_TO: (state, action) =>
    immer(state, draft => {
      draft.addPermissionMode = false;
      draft.addRoleMode = false;
    }),
  OPEN_EDIT_MODAL: (state, action) =>
    immer(state, draft => {
      draft.editRole = action.payload.role;
    }),
  CLOSE_EDIT_MODAL: (state, action) =>
    immer(state, draft => {
      draft.editRole = null;
    }),
  EDIT_ROLE_ERROR: null,
};

export const permissionsReducer = generateReducer(initialState, handlers);
