import generateReducer from 'modules/generateReducer';

export const initialState = {
  user: {},
  prar: {},
  stats: {
    totalUsers: null,
    languages: [],
  },
  workflowID: null,
  workflowTTL: null,
  statusOverride: null,
  specificUser: null,
};

const handlers = {
  USER_WORKFLOW_TASK_SUCCESS: (state, action) => ({
    ...state,
    user: action.payload.user,
    prar: action.payload.prar,
    workflowID: action.payload.id,
    workflowTTL: action.payload.ttl,
    statusOverride: null,
    specificUser: action.payload.specificUser,
  }),
  USER_WORKFLOW_TASK_START: null,
  USER_WORKFLOW_STATS_SUCCESS: (state, action) => ({ ...state, stats: action.payload.stats }),
  USER_WORKFLOW_STATS_START: null,
  USER_WORKFLOW_PROPOSE_STATUS: (state, action) => ({
    ...state,
    statusOverride: action.payload.status,
  }),
  USER_WORKFLOW_CANCEL_TASK: (state, action) => ({
    ...state,
    workflowID: null,
    workflowTTL: null,
  }),
  USER_WORKFLOW_CLEAR_TASK: (state, action) => ({
    ...state,
    workflowID: null,
    workflowTTL: null,
  }),

  USER_WORKFLOW_ERROR: null,
  USER_WORKFLOW_TASK_COMPLETE_START: null,
  USER_WORKFLOW_TASK_COMPLETE_SUCCESS: null,
};

export const principalWorkflowReducer = generateReducer(initialState, handlers);
