import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { ReconciliationDetail } from '../types';

const endpoint = new URL('/api/funding/reconciliations/', window.location.origin).href;

export type UseReconciliationdetailsOptions = {
  id: string;
};

export const useReconciliationDetails = (
  { id }: UseReconciliationdetailsOptions,
  options?: UseQueryOptions<ReconciliationDetail>,
): UseQueryResult<ReconciliationDetail> => {
  return useQuery<ReconciliationDetail>({
    queryKey: ['reconciliation-details', id],
    queryFn: async () => {
      const response = await fetch(`${endpoint}${id}`);

      return response.json() as Promise<ReconciliationDetail>;
    },

    ...options,
  });
};
