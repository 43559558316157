export const MM_STATUSES = {
  APPROVED: 'APPROVED',
  FAILED: 'FAILED',
  ON_HOLD: 'ON_HOLD',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  RIA_APPROVED: 'RIA_APPROVED',
  RIA_PENDING: 'RIA_PENDING',
  RIA_REJECTED: 'RIA_REJECTED',
  STARTED: 'STARTED',
  SUCCESSFUL: 'SUCCESSFUL',
  RETURNED: 'RETURNED',
  REMITTED: 'REMITTED',
  RETURN_PENDING: 'RETURN_PENDING',
};

export const DIRECTIONS = {
  DEPOSITS: 'deposits',
  REDEMPTIONS: 'redemptions',
  SUBSCRIPTIONS: 'subscriptions',
  VIRTUAL: 'virtual',
};

export const PAGE_LIMIT = 200;

export const SUCCESS_TAB_MODAL_COPY_TEXT = {
  HEADER: 'Notice',
  MESSAGE:
    'Successful Payment Reports are currently unavailable on our Partner Dashboard. Please contact support for reporting on successful payments.',
  CLOSE: 'Close',
};

export const TAB_RESETS = {
  tableID: 'payments',
  columnID: 'ib',
};

export const SUBSCRIPTIONS_RAW = 'SUBSCRIPTION_TRANS';

/* TODO: On_Hold - this todo is from deposits - still relevant???
	Add this status as part of this file in the near future,
	when Instant Funding Account is available and in the back end.
*/

export const paymentConfig = {
  redemptions: {
    name: 'redemptions',
    finTranType: 'CSD',
    statuses: [
      {
        id: 'STARTED',
        color: 'grey',
        icon: '',
        label: 'Started',
        title: 'Started',
        static: false,
        status: 0,
        hidden: true,
        successiveStatuses: [],
      },
      {
        id: 'RIA_PENDING',
        color: 'orange',
        icon: 'alarm mute',
        label: 'RIA Pending',
        title: 'RIA Pending',
        static: false,
        status: 11,
        hidden: false,
        successiveStatuses: ['RIA_APPROVED', 'RIA_REJECTED'],
      },
      {
        id: 'RIA_APPROVED',
        color: 'yellow',
        icon: 'flag checkered',
        label: 'RIA Approved',
        title: 'RIA Approved',
        static: false,
        status: 12,
        hidden: false,
        successiveStatuses: ['PENDING', 'REJECTED'],
      },
      {
        id: 'RIA_REJECTED',
        color: 'olive',
        icon: '',
        label: 'RIA Rejected',
        title: 'RIA Rejected',
        static: false,
        status: 13,
        hidden: true,
        successiveStatuses: ['FAILED'],
      },
      {
        id: 'PENDING',
        color: 'orange',
        icon: 'undo',
        label: 'Pending',
        title: 'Pending',
        static: false,
        status: 1,
        hidden: false,
        successiveStatuses: ['APPROVED', 'ON_HOLD', 'REJECTED'],
      },
      {
        id: 'ON_HOLD',
        color: 'violet',
        icon: 'alarm mute',
        label: 'On Hold',
        title: 'On Hold',
        static: false,
        status: 16,
        hidden: false,
        successiveStatuses: ['APPROVED', 'FAILED'],
      },
      {
        id: 'APPROVED',
        color: 'teal',
        icon: 'check',
        label: 'Approve',
        title: 'Approved',
        static: false,
        status: 14,
        hidden: false,
        successiveStatuses: ['SUCCESSFUL', 'ON_HOLD', 'FAILED'],
      },
      {
        id: 'FAILED',
        color: 'red',
        icon: 'dont',
        label: 'Reject',
        title: 'Rejected',
        status: 3,
        static: true,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'SUCCESSFUL',
        color: 'green',
        icon: 'thumbs up',
        label: 'Successful',
        title: 'Successful',
        status: 2,
        static: true,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'REJECTED',
        color: 'grey',
        icon: '',
        label: 'Rejected',
        title: 'Rejected',
        static: false,
        status: 15,
        hidden: true,
        successiveStatuses: [],
      },
      {
        id: 'RETURNED',
        color: 'purple',
        icon: '',
        label: 'Returned',
        title: 'Returned',
        static: false,
        status: 5,
        hidden: false,
        successiveStatuses: ['FAILED'],
      },
    ],
    paymentTypes: [
      {
        id: 'ACH',
        label: 'ACH',
      },
      {
        id: 'CHECK',
        label: 'Check',
      },
      {
        id: 'WIRE',
        label: 'Wire',
      },
      {
        id: 'INSTANT_FUNDING',
        label: 'Instant Funding',
      },
    ],
  },
  deposits: {
    name: 'deposits',
    finTranType: 'CSR',
    statuses: [
      {
        id: 'STARTED',
        color: 'grey',
        icon: '',
        label: 'Started',
        title: 'Started',
        static: false,
        status: 0,
        hidden: true,
        successiveStatuses: [],
      },
      {
        id: 'RIA_PENDING',
        color: 'orange',
        icon: 'alarm mute',
        label: 'RIA Pending',
        title: 'RIA Pending',
        static: false,
        status: 11,
        hidden: false,
        successiveStatuses: ['RIA_APPROVED', 'RIA_REJECTED'],
      },
      {
        id: 'RIA_APPROVED',
        color: 'yellow',
        icon: '',
        label: 'RIA Approved',
        title: 'RIA Approved',
        static: false,
        status: 12,
        hidden: true,
        successiveStatuses: ['PENDING'],
      },
      {
        id: 'RIA_REJECTED',
        color: 'olive',
        icon: '',
        label: 'RIA Rejected',
        title: 'RIA Rejected',
        static: false,
        status: 13,
        hidden: true,
        successiveStatuses: ['FAILED'],
      },
      {
        id: 'PENDING',
        color: 'orange',
        icon: 'undo',
        label: 'Pending',
        title: 'Pending',
        static: false,
        status: 1,
        hidden: false,
        successiveStatuses: ['APPROVED', 'REJECTED'],
      },
      {
        id: 'APPROVED',
        color: 'teal',
        icon: 'check',
        label: 'Approve',
        title: 'Approved',
        static: false,
        status: 14,
        hidden: false,
        successiveStatuses: ['SUCCESSFUL', 'ON_HOLD', 'REJECTED'],
      },
      {
        id: 'FAILED',
        color: 'red',
        icon: 'dont',
        label: 'Reject',
        title: 'Rejected',
        status: 3,
        static: true,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'SUCCESSFUL',
        color: 'green',
        icon: 'thumbs up',
        label: 'Successful',
        title: 'Successful',
        status: 2,
        static: true,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'REJECTED',
        color: 'grey',
        icon: '',
        label: 'Rejected',
        title: 'Rejected',
        static: false,
        status: 15,
        hidden: true,
        successiveStatuses: [],
      },
      {
        id: 'RETURNED',
        color: 'purple',
        icon: '',
        label: 'Returned',
        title: 'Returned',
        static: false,
        status: 5,
        hidden: false,
        successiveStatuses: ['FAILED'],
      },
    ],
    paymentTypes: [
      {
        id: 'ACH',
        label: 'ACH',
      },
      {
        id: 'ACH_MANUAL',
        label: 'ACH Manual',
      },
      {
        id: 'INSTANT_FUNDING',
        label: 'Instant Funding',
      },
    ],
  },
  virtual: {
    name: 'virtual',
    finTranType: 'CSR',
    statuses: [
      {
        id: 'All',
        color: 'grey',
        icon: '',
        label: 'All',
        title: 'All',
        static: true,
        status: 0,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'Started',
        color: 'grey',
        icon: '',
        label: 'Started',
        title: 'Started',
        static: true,
        status: 0,
        hidden: false,
        successiveStatuses: [],
      },

      {
        id: 'Successful',
        color: 'yellow',
        icon: 'thumbs up',
        label: 'Pending',
        title: 'Pending',
        status: 2,
        static: false,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'Return_Pending',
        color: 'orange',
        icon: 'undo',
        label: 'Return Pending',
        title: 'Return Pending',
        static: true,
        status: 19,
        hidden: false,
        successiveStatuses: ['APPROVED', 'REJECTED'],
      },
      {
        id: 'Returned',
        color: 'purple',
        icon: '',
        label: 'Returned',
        title: 'Returned',
        static: true,
        status: 5,
        hidden: false,
        successiveStatuses: ['FAILED'],
      },
      {
        id: 'Remitted',
        color: 'green',
        icon: '',
        label: 'Successful',
        title: 'Successful',
        static: true,
        status: 18,
        hidden: false,
        successiveStatuses: [],
      },
    ],
    paymentTypes: [
      {
        id: 'ACH_SUSPENSE',
        label: 'ACH',
      },
      {
        id: 'WIRE_SUSPENSE',
        label: 'Wire',
      },
    ],
  },
  subscriptions: {
    name: 'subscriptions',
    finTranType: null,
    statuses: [
      {
        id: 'STARTED',
        color: 'grey',
        icon: '',
        label: 'Started',
        title: 'Started',
        static: false,
        status: 0,
        hidden: true,
        successiveStatuses: [],
      },
      {
        id: 'PENDING',
        color: 'orange',
        icon: 'undo',
        label: 'Pending',
        title: 'Pending',
        static: false,
        status: 1,
        hidden: false,
        successiveStatuses: ['APPROVED', 'FAILED'],
      },
      {
        id: 'APPROVED',
        color: 'teal',
        icon: 'check',
        label: 'Approve',
        title: 'Approved',
        static: false,
        status: 14,
        hidden: false,
        successiveStatuses: ['SUCCESSFUL'],
      },
      {
        id: 'FAILED',
        color: 'red',
        icon: 'dont',
        label: 'Reject',
        title: 'Failed',
        status: 3,
        static: true,
        hidden: false,
        successiveStatuses: [],
      },
      {
        id: 'SUCCESSFUL',
        color: 'green',
        icon: 'thumbs up',
        label: 'Successful',
        title: 'Successful',
        status: 2,
        static: false,
        hidden: false,
        successiveStatuses: ['FAILED'],
      },
      {
        id: 'RETURNED',
        color: 'purple',
        icon: '',
        label: 'Returned',
        title: 'Returned',
        static: false,
        status: 5,
        hidden: false,
        successiveStatuses: ['FAILED'],
      },
    ],
    paymentTypes: [],
  },
};

// all the possible payment types
export const TYPES_CONFIGS = {
  ACH: {
    id: 'ACH',
    label: 'ACH',
  },
  // only deposits
  ACH_MANUAL: {
    id: 'ACH_MANUAL',
    label: 'ACH Manual',
  },
  // only redemptions
  CHECK: {
    id: 'CHECK',
    label: 'Check',
  },
  // only redemptions
  WIRE: {
    id: 'WIRE',
    label: 'Wire',
  },
  INSTANT_FUNDING: {
    id: 'INSTANT_FUNDING',
    label: 'Instant Funding',
  },
};
