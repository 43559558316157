import Order from 'services/modules/Order';
import { formatWordDateAndTime } from 'src/app/utils';

const REGEX_NO = /^([A-Za-z]{4})(\d{1,6})$/;
const REGEX_ORDER_ID =
  /^[A-Za-z]{2}\.[A-Za-z\d]{8}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{12}$/;

const REGEX_COMBO = new RegExp(
  '^' +
    REGEX_NO.source.substring(1, REGEX_NO.source.length - 1) +
    '|' +
    REGEX_ORDER_ID.source.substring(1, REGEX_ORDER_ID.source.length - 1) +
    '$',
);

export default {
  id: 'ORDER',
  label: 'Orders',
  applicableQueries: REGEX_COMBO,
  maxSuggestions: 3,
  fetchResults: query => {
    if (REGEX_ORDER_ID.test(query)) {
      return Order.getByOrderID(query)
        .then(order => [
          {
            path: `/reporting/ticket-audit/${order.orderNo}`,
            primary: order.orderNo,
            secondary: `${order.symbol}, ${formatWordDateAndTime(new Date(order.created))}`,
            tertiary: order.accountNo,
          },
        ])
        .catch(() => []);
    }

    const matches = query.match(REGEX_NO);

    if (!matches) return Promise.resolve([]);
    const orderNo = matches[1] + matches[2].padStart(6, '0');

    return Order.getByOrderNo(orderNo)
      .then(order => [
        {
          path: `/reporting/ticket-audit/${orderNo}`,
          primary: orderNo,
          secondary: `${order.symbol}, ${formatWordDateAndTime(new Date(order.created))}`,
          tertiary: order.accountNo,
        },
      ])
      .catch(() => []);
  },
  tips: ['Type any order number to jump to its Ticket Audit report.'],
};
