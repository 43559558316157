import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
`;

const Header = styled.h1`
  font-weight: 300;
`;

export const Unauthorized = () => (
  <Container>
    <div>
      <LockIcon sx={{ width: 48, height: 48 }} />
      <Header>You are not authorized to view that page.</Header>
    </div>
  </Container>
);
