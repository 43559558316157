import { ErrorMessages } from 'common/constants/errorMessages';
const { GENERIC_ERROR_MESSAGE } = ErrorMessages;
/**
 * Create a new status message.
 *
 * @param message An array of message strings to add.
 *
 * @param id Unique identifier for this list of messages. Uses action type
 * if left undefined.
 */
export const createStatusMessage = (message, id) => {
  return {
    createStatusMessage: {
      message,
      id,
    },
  };
};

export const createErrorMessage = (
  error = {
    errorCode: '-1',
    message: GENERIC_ERROR_MESSAGE,
  },
  id,
) => {
  // if we have an errorCode and message, construct the errorString from both. Otherwise, use the message if it is present but the errorCode is not. Otherwise, use the generic message
  const errorString =
    error.errorCode && error.message
      ? `${error.errorCode}: ${error.message}`
      : !error.errorCode && error.message
      ? error.message
      : GENERIC_ERROR_MESSAGE;
  return {
    createStatusMessage: {
      message: [typeof error === 'string' ? error : errorString],
      id,
    },
  };
};

/**
 * Clear status messages by id.
 *
 * @param id Identifier of the list of messages to clear. Uses action type
 * if left undefined.
 */
export const clearStatusMessages = id => ({
  clearStatusMessages: { id },
});

const statusMessageReducer = (messages = {}, action) => {
  if (action.createStatusMessage) {
    const id = action.createStatusMessage.id || action.type;
    const message = action.createStatusMessage.message;

    return {
      ...messages,
      [id]: messages[id] ? [...messages[id], ...message] : [...message],
    };
  }

  if (action.clearStatusMessages) {
    const id = action.clearStatusMessages.id || action.type;

    return {
      ...messages,
      [id]: [],
    };
  }

  return messages;
};

export default statusMessageReducer;
