import { FC } from 'react';

import { isEnvironmentKey } from '../../hooks';
import { ExternalNavItem } from './ExternalNavItem';
import { NavDropdown } from './NavDropdown';
import { NavItem } from './NavItem';
import { NavProps } from './types';

// TODO: This can actually be consolidated with PrimaryNav, the leaf items need to be able to be passed in
export const SecondaryNav: FC<NavProps> = props => {
  const { isExternal, path, subRoutes = [], isHighlighted } = props;

  if (subRoutes.length > 0) {
    return (
      <NavDropdown
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        component={NavItem}
        // eslint-disable-next-line react/destructuring-assignment
        variant={isHighlighted ? 'tertiary' : 'secondary'}
      />
    );
  }

  if (isExternal && isEnvironmentKey(path)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ExternalNavItem {...props} path={path} variant="secondary" />;
  }

  // Standalone
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NavItem {...props} path={path as string} variant="secondary" />;
};
