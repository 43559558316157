import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Header = styled.p`
  margin-bottom: 0.5em;
`;

export const NotFound = () => (
  <Container>
    <Content>
      <SearchOutlinedIcon sx={{ height: 48, width: 48, mr: 2 }} />
      <div>
        <Header>Whoops. Page Not Found.</Header>
        <p>Please use the search box above to find clients, orders, accounts, and more.</p>
      </div>
    </Content>
  </Container>
);
