import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/app/services';
import { APIRole } from 'src/app/types';

const endpoint = new URL('/api/manage-roles', window.location.origin).href;

export const useAddRole = (): UseMutationResult<
  APIRole,
  { errorCode: number; message: string },
  APIRole
> => {
  const queryClient = useQueryClient();

  const mutationFn = async (variables: APIRole) => {
    const roleCreateVariables: APIRole = {
      adminRights: variables.adminRights,
      datasrcPermissionGroups: variables.datasrcPermissionGroups,
      defaultDatasrc: variables.defaultDatasrc,
      name: variables.name,
      partnerWhitelist: variables.partnerWhitelist,
    };
    const data = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(roleCreateVariables),
      headers: {
        'Content-type': 'application/json',
      },
    });
    return (await data.json()).data as APIRole;
  };

  return useMutation<APIRole, { errorCode: number; message: string }, APIRole>({
    mutationFn,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['manage-roles'] });
    },
  });
};
