import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { SettlementDetail } from '../types';

const endpoint = new URL('/api/funding/settlements/instant-funding/', window.location.origin).href;

export type UseSettlementdetailsOptions = {
  id: string;
};

export const useSettlementDetails = (
  { id }: UseSettlementdetailsOptions,
  options?: UseQueryOptions<SettlementDetail>,
): UseQueryResult<SettlementDetail> => {
  return useQuery<SettlementDetail>({
    queryKey: ['settlement-details', id],
    queryFn: async () => {
      const response = await fetch(`${endpoint}${id}`);

      return response.json() as Promise<SettlementDetail>;
    },

    ...options,
  });
};
