import { Box } from '@drivehub/ui-components';
import { isNull } from 'lodash';
import { ReactNode, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext, useUser } from 'src/app/hooks/useAuth';
import { neverReached } from 'src/app/utils';

import { AuthStateContext, AuthStateProvider } from './AuthContext';
import { AuthenticationPage } from './AuthenticationPage';
import { ChangePassword } from './ChangePassword';
import { ConfirmationCode } from './ConfirmationCode';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { ResetPassword } from './ResetPassword';

export type AuthenticationControllerProps = {
  children: ReactNode;
};

export const AuthForm = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  const { authState, setAuthState, setUsername } = useContext(AuthStateContext);
  const user = useUser();
  const { challenge } = useContext(AuthContext);

  // Watch the challenges for changes and route appropriately
  useEffect(() => {
    if (challenge?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setAuthState('changePassword');
    } else if (challenge?.challengeName === 'SMS_MFA') {
      setAuthState('confirmation');
    }
  }, [challenge, setAuthState]);

  // Watch the user for changes (e.g. after a successful sign in)
  useEffect(() => {
    if (isNull(user)) {
      setAuthState('login');
    } else {
      // Cleanup the username variable upon login, which tracks the username between routes
      setUsername(null);
    }
  }, [setAuthState, setUsername, user]);

  switch (authState) {
    case 'confirmation':
      return <ConfirmationCode isLoading={isLoading} />;
    case 'forgotPassword':
      return <ForgotPassword />;
    case 'resetPassword':
      return <ResetPassword />;
    case 'changePassword':
      return <ChangePassword />;
    case 'login':
      return <Login isLoading={isLoading} />;
    default:
      return neverReached(authState);
  }
};

export const AuthenticationController = ({
  children,
}: AuthenticationControllerProps): JSX.Element => {
  const user = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const returnTo = searchParams.get('return_to');

  const { challenge } = useContext(AuthContext);
  const isChangePasswordPage = challenge?.challengeName === 'NEW_PASSWORD_REQUIRED' || false;

  if (user && !returnTo) {
    return <>{children}</>;
  }

  return (
    <AuthStateProvider>
      <AuthenticationPage isChangePasswordPage={isChangePasswordPage}>
        <Box bgcolor="colors.corePrimary2" p={4} maxWidth="480px" m="auto" borderRadius="8px">
          <AuthForm isLoading={false} />
        </Box>
      </AuthenticationPage>
    </AuthStateProvider>
  );
};
