import { copyText } from 'src/app/internationalization';

const {
  accounts: {
    createAccount: {
      unknown,
      individual,
      corporation,
      withholdingForeignPartnership,
      withholdingForeignPartnershipTrust,
      trust,
      governmentInternationalOrganization,
      taxExemptOrganization,
      privateFoundation,
      artistAthlete,
      estate,
      usBranchTreatedPerson,
      qualifiedIntermediary,
      privateIntermediaryWithholdingGeneral,
      privateIntermediaryWithholdingExemptOrg,
      qualifiedIntermediaryWithholdingGeneral,
      qualifiedIntermediaryWithholdingExemptOrg,
      authorizedForeignAgent,
      publicPensionFund,
      unknownRecepient,
      qualifiedSecuritiesLenderIntermediary,
      qualifiedSecuritiesLenderOther,
    },
  },
} = copyText;

export const ACCOUNT_SUMMARY_REQUEST_BATCH_SIZE = 750;

export const RIATypes = {
  RIA_MANAGED: 'RIA_MANAGED',
  CUSTODIAL_MANAGED: 'CUSTODIAL_MANAGED',
  RETIREMENT_TRADITIONAL_RIA_MANAGED: 'RETIREMENT_TRADITIONAL_RIA_MANAGED',
  RETIREMENT_ROTH_RIA_MANAGED: 'RETIREMENT_ROTH_RIA_MANAGED',
  TRUST_RIA_MANAGED: 'TRUST_RIA_MANAGED',
};

export const AcatsStatuses = {
  IN_PROGRESS: 'ACATS is under processing',
  COMPLETE: 'ACATS Complete',
};

export const OPTIONS_STATUSES = [
  { text: 'Enrolled', value: true },
  { text: 'Not Enrolled', value: false },
];

export const REASONS_FOR_STATUS_CHANGE = [
  { text: AcatsStatuses.COMPLETE, value: AcatsStatuses.COMPLETE },
  { text: AcatsStatuses.IN_PROGRESS, value: 'ACAT' },
  { text: 'Legal restriction (Fraud/AML)', value: 'LEGAL_AML' },
  { text: 'Duplicate account', value: 'DUP_ACCOUNT' },
  { text: 'The person is deceased', value: 'DECEASED' },
  { text: 'Other', value: 'OTHER' },
];

export const NEW_REASONS_FOR_STATUS_CHANGE = [
  { text: 'ACATS is processing', value: 'ACAT' },
  { text: 'Legal Restriction (Fraud/AML)', value: 'LEGAL_AML' },
  { text: 'Duplicate Account', value: 'DUPLICATE_ACCOUNT' },
  { text: 'The person is deceased', value: 'DECEASED' },
  { text: 'Account has been transferred', value: 'ACCOUNT_TRANSFERRED' },
  { text: 'Margin call occurred on the account', value: 'MARGIN_CALLS' },
  { text: 'Payment failed on the account', value: 'PAYMENT_FAILURE' },
  { text: 'FINRA restriction applied on the account', value: 'FINRA_RESTRICTIONS' },
  { text: 'PDT restriction applied on the account', value: 'PDT_RESTRICTIONS' },
  { text: 'Partner has restricted the account', value: 'PARTNER_RESTRICTED' },
  { text: 'Compliance restriction applied on the account', value: 'COMPLIANCE_RESTRICTED' },
  { text: 'Other', value: 'OTHER' },
];

export const REASONS_FOR_STATUS_CHANGE_PARTNER = [
  { text: 'Duplicate Account', value: 'DUPLICATE_ACCOUNT' },
  { text: 'Partner has restricted the account', value: 'PARTNER_RESTRICTED' },
  { text: 'Other', value: 'OTHER' },
];

export const REASONS_FOR_ACCOUNT_REOPENING = [{ text: 'Other', value: 'OTHER' }];

export const ACCOUNT_STATUSES = [
  { text: 'Pending', value: 'PENDING' },
  { text: 'Open', value: 'OPEN' },
  { text: 'Liquidating Trades Only', value: 'OPEN_NO_NEW_TRADES' },
  { text: 'Frozen', value: 'FROZEN' },
  { text: 'Closed', value: 'CLOSED' },
  { text: 'Re-Open', value: 'RE_OPEN' },
];

export const taxRecipientCodes = [
  { text: unknown, value: 'UNKNOWN' },
  { text: individual, value: 'INDIVIDUAL' },
  { text: corporation, value: 'CORPORATION' },
  { text: withholdingForeignPartnership, value: 'WITHHOLDING_FOREIGN_PARTNERSHIP' },
  { text: withholdingForeignPartnershipTrust, value: 'WITHHOLDING_FOREIGN_PARTNERSHIP_TRUST' },
  { text: trust, value: 'TRUST' },
  { text: governmentInternationalOrganization, value: 'GOVERNMENT_INTERNATIONAL_ORGANIZATION' },
  { text: taxExemptOrganization, value: 'TAX_EXEMPT_ORGNIZATION' },
  { text: privateFoundation, value: 'PRIVATE_FOUNDATION' },
  { text: artistAthlete, value: 'ARTIST_ATHLETE' },
  { text: estate, value: 'ESTATE' },
  { text: usBranchTreatedPerson, value: 'US_BRANCH_TREATED_PERSON' },
  { text: qualifiedIntermediary, value: 'QUALIFIED_INTERMEDIARY' },
  {
    text: privateIntermediaryWithholdingGeneral,
    value: 'PRIVATE_INTERMEDIARY_WITHHOLDING_GENERAL',
  },
  {
    text: privateIntermediaryWithholdingExemptOrg,
    value: 'PRIVATE_INTERMEDIARY_WITHHOLDING_GENERAL',
  },
  {
    text: qualifiedIntermediaryWithholdingGeneral,
    value: 'QUALIFIED_INTERMEDIARY_WITHHOLDING_GENERAL',
  },
  {
    text: qualifiedIntermediaryWithholdingExemptOrg,
    value: 'QUALIFIED_INTERMEDIARY_WITHHOLDING_EXEMPT_ORG',
  },
  { text: authorizedForeignAgent, value: 'AUTHORIZED_FOREIGN_AGENT' },
  { text: publicPensionFund, value: 'PUBLIC_PENSION_FUND' },
  { text: unknownRecepient, value: 'UNKNOWN_RECIPIENT' },
  {
    text: qualifiedSecuritiesLenderIntermediary,
    value: 'QUALIFIED_SECURITIES_LENDER_INTERMEDIARY',
  },
  { text: qualifiedSecuritiesLenderOther, value: 'QUALIFIED_SECURITIES_LENDER_OTHER' },
];

export const taxStatusCodes = (isForeignUser, isOmnibusAccount) => {
  return [
    isForeignUser && { text: 'W-8BEN', value: 'W-8BEN' },
    { text: 'W-8BEN-E', value: 'W-8BEN-E' },
    { text: 'W-8ECI', value: 'W-8ECI' },
    isForeignUser && { text: 'W-8EXPL', value: 'W-8EXPL' },
    isForeignUser && isOmnibusAccount && { text: 'W-8IMY', value: 'W-8IMY' },
    { text: 'W-9', value: 'W-9' },
  ];
};

export const taxStatusCodesRetail = isForeignUser => {
  return [
    isForeignUser && { text: 'W-8BEN', value: 'W-8BEN' },
    { text: 'W-8BEN-E', value: 'W-8BEN-E' },
    { text: 'W-8ECI', value: 'W-8ECI' },
    !isForeignUser && { text: 'W-9', value: 'W-9' },
  ];
};

export const taxStatusCodesPartner = (isForeignUser, isOmnibusAccount) => {
  return [
    { text: 'W-8BEN-E', value: 'W-8BEN-E' },
    isForeignUser && !isOmnibusAccount && { text: 'W-8EXPL', value: 'W-8EXPL' },
    isForeignUser && isOmnibusAccount && { text: 'W-8IMY', value: 'W-8IMY' },
    !isForeignUser && { text: 'W-9', value: 'W-9' },
  ];
};
