//
import User from 'services/modules/User';

import { registerSharedFetcher } from './sharedFetcherDatabase';

const REGEX_FULLNAME = /(\S+) ((\S+ ?)+)/;
const REGEX_USERID = /^[A-Za-z\d]{8}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{12}$/;
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_PHONE = /^\d[\d- ]{5,}\d$/;

const fieldExpressions = {
  username: null,
  firstName: null,
  lastName: null,
  phoneNumber: REGEX_PHONE,
};

const sanitizeFields = {
  phoneNumber: raw => raw.replace(/[^\d]/g, ''),
};

const fetchResults = query => {
  const promises = [];

  if (REGEX_USERID.test(query)) {
    promises.push(User.get(query));
  } else if (REGEX_EMAIL.test(query)) {
    promises.push(
      User.advancedSearch({
        email: query,
      }),
    );
    promises.push(
      User.advancedSearch({
        username: query,
      }),
    );
  } else {
    promises.push(
      ...Object.keys(fieldExpressions)
        .filter(key => !fieldExpressions[key] || fieldExpressions[key].test(query))
        .map(key =>
          User.advancedSearch({
            [key]: sanitizeFields[key] ? sanitizeFields[key](query) : query,
          }),
        ),
    );

    if (REGEX_FULLNAME.test(query)) {
      const allParts = query.split(/\s/);

      let firstName;
      let lastName;
      // search all possible combinations of first and last name
      for (let i = 1; i < allParts.length; i++) {
        firstName = allParts.slice(0, i).join(' ');
        lastName = allParts.slice(i).join(' ');

        promises.push(
          User.advancedSearch({
            firstName,
            lastName,
          }),
        );
      }
    }
  }

  return Promise.all(promises).then(responses => {
    // transform nested array into single-level array
    const results = responses.reduce(
      (acc, next) => [...acc, ...(Array.isArray(next) ? next : [next])],
      [],
    );

    // remove duplicate results by converting from array to object and back to array
    const resultsByID = results.reduce((acc, next) => {
      // Can we simplify this type? Couldn't find a way to make it simpler in Flow
      const userID =
        typeof next.id === 'string' ? next.id : typeof next.userID === 'string' ? next.userID : '';

      return Object.assign({}, acc, { [userID]: next });
    }, {});
    return Object.keys(resultsByID).map(key => resultsByID[key]);
  });
};

export default registerSharedFetcher('USER', fetchResults);
