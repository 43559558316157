import ColorLib from 'color';

// We disable this eslint rule to take advantage of hoisting. Otherwise, we would need to put this
// method inside the class, which doesn't really make sense.
// eslint-disable-next-line no-use-before-define

const mixedToColorLib = color =>
  typeof color === 'string'
    ? ColorLib(color)
    : color instanceof Color // eslint-disable-line no-use-before-define
    ? color.value
    : color;

// Our custom Color class eliminates much of the repetition of using npm's Color. For example,
// the custom toString function allows you to directly use a Color object inside of a styled-
// component rule without having to call .string().
class Color {
  constructor(color) {
    this.value = mixedToColorLib(color);
  }

  get rgb() {
    return this.value.rgb().string();
  }
  get hex() {
    return this.value.hex();
  }

  // Moves towards white or black, depending on the brightness of this value. Example: if value
  // is 80% black, adjusting with an offset of 0.5 will return 40% black.
  adjust(offset) {
    const swap = offset < 0;
    const mixWith = this.value.isLight() ? (!swap ? 'black' : 'white') : !swap ? 'white' : 'black';

    return new Color(this.value.mix(ColorLib(mixWith), Math.abs(offset)));
  }

  // These methods are proxies for npm's Color methods
  // Purposely repeating code here so that it is typed statically. Yes, we could loop through an
  // array of method names and build them dynamically, but then we lose static typechecking.
  negate() {
    return new Color(this.value.negate());
  }
  lighten(n) {
    return new Color(this.value.lighten(n));
  }
  darken(n) {
    return new Color(this.value.darken(n));
  }
  saturate(n) {
    return new Color(this.value.saturate(n));
  }
  desaturate(n) {
    return new Color(this.value.desaturate(n));
  }
  grayscale() {
    return new Color(this.value.grayscale());
  }
  whiten(n) {
    return new Color(this.value.whiten(n));
  }
  blacken(n) {
    return new Color(this.value.blacken(n));
  }
  fade(n) {
    return new Color(this.value.fade(n));
  }
  opaquer(n) {
    return new Color(this.value.opaquer(n));
  }
  rotate(n) {
    return new Color(this.value.rotate(n));
  }
  mix(color, n) {
    return new Color(this.value.mix(mixedToColorLib(color), n));
  }

  // For use in styled-component rules
  toString() {
    return this.value.rgb().string();
  }
}
export default Color;
