import { UnregisterCallback } from 'history';
import { ProviderContext, SnackbarKey, useSnackbar as useNotiSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export type UseSnackbarOptions = {
  clearOnNavigate?: boolean;
};

type EnqueueSnackbarOptions = Parameters<ProviderContext['enqueueSnackbar']>;

export const useSnackbar = ({
  clearOnNavigate = false,
}: UseSnackbarOptions = {}): ProviderContext => {
  const { closeSnackbar, enqueueSnackbar: notistackEnqueueSnackbar, ...rest } = useNotiSnackbar();

  const history = useHistory();
  const [enqueuedItems, setEnqueuedItems] = useState<Array<SnackbarKey>>([]);

  const enqueueSnackbar = useCallback<
    (message: EnqueueSnackbarOptions[0], options?: EnqueueSnackbarOptions[1]) => SnackbarKey
  >(
    (message: EnqueueSnackbarOptions[0], options?: EnqueueSnackbarOptions[1]): SnackbarKey => {
      const key = notistackEnqueueSnackbar(message, options);

      setEnqueuedItems(items => [...items, key]);

      return key;
    },
    [notistackEnqueueSnackbar],
  );

  useEffect(() => {
    let unregister: UnregisterCallback | undefined;

    const listener = () => {
      enqueuedItems.forEach(snackbarKey => closeSnackbar(snackbarKey));
    };

    if (clearOnNavigate) {
      unregister = history.listen(listener);
    }

    return () => {
      unregister?.();
    };
  }, [clearOnNavigate, closeSnackbar, history, enqueuedItems]);

  return {
    closeSnackbar,
    enqueueSnackbar,
    ...rest,
  };
};
