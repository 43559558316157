import { MenuItem } from '@drivehub/ui-components';
import { Avatar } from '@mui/material';
import { useWhoAmI } from 'src/app/hooks';
import { useSignOut } from 'src/app/hooks/useAuth';
import styled from 'styled-components';

import { copyText } from '../../internationalization';
import { TopNavBarDropdownMenu } from './TopNavBarDropdownMenu';
import { TwoLineItem } from './TwoLineItem';

const StyledAvatar = styled(Avatar)`
  margin-right: ${props => props.theme.spacing(1)};
  height: 32px;
  width: 32px;
  font-size: ${props => props.theme.typography.body2.fontSize}px;
  background-color: ${props => props.theme.dw.colors.white};
  color: ${props => props.theme.dw.colors.corePrimary1};
`;

export const User = (): JSX.Element => {
  const { data } = useWhoAmI();
  const userName = data?.employee.name ?? '';
  const roleName = data?.role.name ?? '';
  const companyName = data?.partner.companyName ?? '';
  const signOut = useSignOut();

  const {
    navbar: {
      avatar: { logout },
    },
  } = copyText;

  // Possibly naive to split this way? Unsure how to initialize non <FIRST_NAME LAST_NAME> names
  const initials = userName.split(' ').map(part => part[0]);

  const menuItems = [
    <MenuItem key="log-out" onClick={signOut}>
      {logout}
    </MenuItem>,
  ];

  const userNameWithCompany = `${userName}, ${companyName}`;

  return (
    <TopNavBarDropdownMenu menuItems={menuItems} testId="user-menu">
      <StyledAvatar>{initials}</StyledAvatar>
      <TwoLineItem top={userNameWithCompany} bottom={roleName} />
    </TopNavBarDropdownMenu>
  );
};
