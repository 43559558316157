import { Numbers } from 'common/constants/numbers';
import { isUndefined } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIdle, useInterval, useLocalStorage, useMountedState } from 'react-use';

import { useSignOut } from './useAuth';

const { SESSION_MODAL_COUNT_DOWN_SECONDS, SESSION_TIMEOUT_MINUTES } = Numbers;

const sessionTimeoutInMs = SESSION_TIMEOUT_MINUTES * 1000 * 60;

export type UseSessionReturnValue = {
  isSessionIdle: boolean;
  timeLeft?: number;
  logoutSession: () => void;
  keepSessionAlive: () => void;
};

const getIsSessionIdle = (lastActiveTimeStamp: string | undefined): boolean => {
  if (isUndefined(lastActiveTimeStamp)) {
    return false;
  }

  // Return whether we're roughly before now.
  return new Date(lastActiveTimeStamp).getTime() <= Date.now();
};

/**
 * This hook tracks the user's idle state across all windows, and logs them out if they are idle for too long.
 */
export const useSession = (): UseSessionReturnValue => {
  const isLocallyIdle = useIdle(sessionTimeoutInMs);
  const [lastActive, setLastActive, removeLastActive] = useLocalStorage<string | undefined>(
    'dh_last_active',
    new Date().toISOString(),
  );
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
  const [isGloballyIdle, setIsGloballyIdle] = useState<boolean>(false);
  const signOut = useSignOut();
  const isMounted = useMountedState();

  useInterval(() => {
    if (!isMounted()) {
      return;
    }

    // Update our last active time, if we haven't hit the local threshold
    if (!isLocallyIdle) {
      setLastActive(new Date().toISOString());
    } else {
      setIsGloballyIdle(getIsSessionIdle(lastActive));
    }
  }, 250);

  // Tick down the time left until the user is logged out.
  useInterval(
    () => {
      if (isMounted() && !isUndefined(timeLeft)) {
        setTimeLeft(t => Number(t) - 1);
      }
    },
    !isUndefined(timeLeft) ? 1000 : null,
  );

  useEffect(() => {
    if (isMounted() && isGloballyIdle && isUndefined(timeLeft)) {
      setTimeLeft(SESSION_MODAL_COUNT_DOWN_SECONDS);
    }
  }, [isGloballyIdle, isMounted, timeLeft]);

  const logoutSession = useCallback(() => {
    // Clear out the logout time on the way out in case the user logs back in
    removeLastActive();
    signOut();
  }, [removeLastActive, signOut]);

  const keepSessionAlive = useCallback(() => {
    setTimeLeft(undefined);
    setIsGloballyIdle(false);
  }, []);

  // Log the user out when timeLeft reaches 0
  useEffect(() => {
    if (isMounted() && !isUndefined(timeLeft) && timeLeft <= 0) {
      logoutSession();
    }
  }, [isMounted, logoutSession, timeLeft]);

  if (
    !isUndefined(lastActive) &&
    new Date(lastActive).getTime() + SESSION_MODAL_COUNT_DOWN_SECONDS * 1000 <= Date.now()
  ) {
    logoutSession();
  }

  return {
    logoutSession,
    keepSessionAlive,
    isSessionIdle: isGloballyIdle,
    timeLeft,
  };
};
