import { Box, Divider } from '@mui/material';
import { FC, useMemo } from 'react';
import { useFeatureToggle } from 'src/app/hooks';
import styled from 'styled-components';

import { flipBoxShadow } from '../../utils';
import { PrimaryNav } from './PrimaryNav';
import { getSettings } from './routes';

const StyledDivider = styled(Divider)`
  background-color: ${props => props.theme.dw.colors.corePrimary4};
`;

const SettingsBox = styled(Box)`
  box-shadow: ${props => flipBoxShadow(props.theme.shadows[4])};
`;

export const Settings: FC = () => {
  const { data: featureToggles } = useFeatureToggle();
  const settings = useMemo(() => getSettings(featureToggles), [featureToggles]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SettingsBox component="div" marginTop="auto" flexShrink={0}>
      <StyledDivider />
      {settings.map(config => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <PrimaryNav key={config.text} {...config} />
      ))}
    </SettingsBox>
  );
};
