//
import SearchIcon from '@mui/icons-material/Search';
import Color from 'modules/Color';
import React from 'react';
import resultTypes from 'reducers/search/resultTypes';
import styled from 'styled-components';

const TIPS_BG = new Color('#FFF');
const TIPS = [].concat(...resultTypes.map(resultType => resultType.tips).filter(a => a));

const Container = styled.div`
  padding: 5em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: TIPS_BG;
  color: ${TIPS_BG.adjust(0.4)};
  text-align: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 3em;
  color: ${TIPS_BG.adjust(0.12)};
  margin-bottom: 0.5rem;
`;

const TipIndicator = styled.span`
  background-color: ${TIPS_BG.adjust(0.1)};
  font-weight: 500;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9em;
  margin-right: 0.3em;
`;

const Tips = ({ tip }) => (
  <Container>
    <StyledSearchIcon name="search" />
    <span>
      <TipIndicator>Tip</TipIndicator> {tip}
    </span>
  </Container>
);

export default class TipsContainer extends React.Component {
  tip = TIPS[Math.round(Math.random() * (TIPS.length - 1))];

  render() {
    return <Tips tip={this.tip || ''} />;
  }
}
