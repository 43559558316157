const getCommissions = data =>
  data.reduce((acc, current) => {
    if (current.commissions) {
      acc.push(...current.commissions);
    }
    return acc;
  }, []);

const groupByIb = data =>
  data.reduce((acc, current) => {
    if (current.commissions) {
      acc.push({
        id: current.ib.id,
        commissions: [...current.commissions],
      });
    }
    return acc;
  }, []);

export const shapeCommissions = data => {
  const allCommissions = getCommissions(data);
  const byID = allCommissions.reduce((acc, current) => {
    acc[current.commissionID] = current;
    return acc;
  }, {});
  return byID;
};

export const shapelistsByID = data => {
  const grouped = groupByIb(data);
  const listsByWLP = grouped.reduce((acc, current) => {
    acc[current.id] = current.commissions.map(c => c.commissionID);
    return acc;
  }, {});
  return listsByWLP;
};
