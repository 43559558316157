import { isString } from 'lodash';

export type CognitoError = {
  code: string;
  message: string;
};

// Typeguard function should be allowed to take anything as a parameter
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isCognitoError = (error: any): error is CognitoError => {
  return isString(error?.code) && isString(error?.message);
};
