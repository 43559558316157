import { flatten } from 'lodash';
import { useEnvironment } from 'src/app/hooks';

import { Permission } from '../../../constants/permissions';
import { usePermission } from '../../../hooks/usePermission';
import { RouterConfig } from '../types';

const getPermissions = (configs: RouterConfig[]): Array<Permission | null> => {
  return flatten(
    configs.map(config => {
      // Consider only
      if (config?.subRoutes) {
        return getPermissions(config.subRoutes);
      }

      // Leaf node
      return config.permissions.length > 0 ? config.permissions : [null];
    }),
  );
};

export const useRecursivePermissions = (config: RouterConfig): boolean => {
  const allowedPermissions: Array<Permission | null> = getPermissions([config]);
  const hasPermission = usePermission(allowedPermissions);
  const { value } = useEnvironment();

  // Environment being on or off supersedes any permissioning
  if (!config.environments.includes(value)) {
    return false;
  }

  // Null is returned from getPermissions to signify an empty permission object
  if (allowedPermissions.includes(null)) {
    return true;
  }

  return hasPermission;
};
