import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { BOPartner } from 'app/types';
import { uniqBy } from 'lodash';
import { fetch } from 'src/app/services';

const endpoint = new URL('/api/partners/partnerProfiles', window.location.origin).href;

export const useBOPartners = (
  options?: UseQueryOptions<BOPartner[]>,
): UseQueryResult<BOPartner[]> => {
  return useQuery<BOPartner[]>({
    queryKey: ['bo-partners'],
    queryFn: async () => {
      const data = await fetch(endpoint);

      return data.json();
    },
    ...options,
  });
};

export const getSortedPartners = (partnerData: BOPartner[] | undefined): BOPartner[] => {
  if (!partnerData) return [];
  const sortedPartners = partnerData.sort((a, b) => a.name?.localeCompare(b.name) ?? 0);
  return uniqBy(sortedPartners, 'name');
};
