export const ErrorMessages = {
  ACCESS_DENIED: 'You do not have access to view this data',
  ACCOUNT_ERRORS_ONLY: 'No balance errors, please fix the account errors in the batch',
  AT_LEAST_ONE_ALLOCATION_ERROR_MESSAGE: 'At least one allocation must be entered',
  BALANCE_ERRORS_ONLY: 'No account errors, please fix the balance errors in the batch',
  BALANCE_NOT_AVAILABLE_ERROR: 'Error: Balance Not Available',
  BENEFICIARIES_TOTAL_ERROR_MESSAGE: 'Total must equal 100%',
  ENTER_VALID_FINTRANID: 'Please enter a valid wlpFinTranTypeID.',
  FILE_TYPE_ERROR_MESSAGE: 'Please upload a supported file type.',
  GENERIC_ERROR_MESSAGE: 'Something went wrong, please contact support.',
  IDENTIFICATION_HELP_ERROR_MESSAGE:
    'Identification data has not been updated. Please ' +
    're-enter your updates with corrected information.',
  INCORRECT_FINTRANID_FORMAT: 'The wlpFinTranTypeID format you entered is incorrect.',
  INVALID_ACCOUNT_NUMBER_ERROR: 'Error: Invalid account number.',
  INVALID_ACCOUNT_NUMBER_ERROR_MESSAGE: 'Please enter a valid Account Number.',
  INVALID_FORMAT: 'invalid format',
  INVALID_URL: 'Invalid URL',
  KYC_VERIFICATION_ERROR_MESSAGE: 'There was an error in KYC verification. Please try again later.',
  MAX_IMAGE_SIZE_ERROR_MESSAGE: 'The max upload size is 10MB, please upload a smaller file.',
  MUST_BE_LESS_THAN_10MB: 'Must be less than 10MB',
  MUST_BE_PNG_JPEG_JPG_GIF: 'Must be a .png, .jpeg, .jpg, or .gif',
  NO_ACCOUNTS_MARGIN_CALL_ERROR_MESSAGE: 'There are no accounts with margin calls.',
  NO_MARGIN_REPORT_FOUND: 'No margin call report found for this time.',
  NO_PAYMENT_WAS_FOUND: 'No payment was found',
  NO_SUCCESSFUL_RECORDS_FIX_ERRORS: 'No successful records, please fix the errors in the batch',
  PARTNER_CREATE_ERROR: 'There was an error creating a new Partner. Please try again later.',
  PARTNER_NOT_FOUND: 'Partner Not Found',
  PARTNER_UPDATE_ERROR: 'There was an error editing the selected Partner. Please try again later.',
  PRODUCTION_IBID_FORMAT_ERROR: 'Production IBID format is incorrect',
  PRODUCTION_IBID_DUPLICATE_ERROR: 'Please use a unique IBID',
  REQUIRED: 'required',
  SANDBOX_IBID_FORMAT_ERROR: 'Sandbox IBID format is incorrect',
  SANDBOX_IBID_DUPLICATE_ERROR: 'Please use a unique IBID',
  SHARE_QUANTITY_FORMAT_ERROR_MESSAGE:
    'Share Quantity must be entered as a numeric value with up to 8 decimals.',
  STATUS_CHANGING_ERROR: 'There was an error changing status. Please try again later.',
  STATUS_COMMENT_ERROR: 'Status Comment - Enter a comment to update the selected status',
  SYMBOL_ALREADY_EXISTS: 'Symbol already exists.',
  VALID_EMAIL_ERROR_MESSAGE: 'Please enter a valid email.',
  WEBSOCKET_ALERTS_ERROR_MESSAGE:
    'There was an error connecting to our Alerts service. Please try again later.',
};
