import { NEW_YORK_TIMEZONE } from 'common/constants/dates';
import {
  format,
  formatDistanceToNow,
  intlFormatDistance,
  isFuture,
  isWeekend as dateIsWeekend,
  parse,
} from 'date-fns';
import { format as formatTimeZone } from 'date-fns-tz';

/**
 * Returns true if the given date is a weekend day
 * @param date the moment js object
 * @returns true if weekend
 */
export const isWeekend = (date: Date | number): boolean => {
  return dateIsWeekend(date);
};

export const isFutureDate = (date: Date | number): boolean => isFuture(date);

export const formatDate = (date: Date | number, formatString = 'MM/dd/yyyy'): string => {
  return format(date, formatString);
};

export const formatDateYearFirst = (date: Date | number, formatString = 'yyyy/MM/dd'): string => {
  return format(date, formatString);
};

export const formatDateTime = (date: Date | number): string => {
  return format(date, 'MM/dd/yyyy HH:mm:ss');
};

export const formatDateTimeWithAnte = (date: Date | number): string => {
  return format(date, 'MM/dd/yyyy hh:mm:ss a');
};

export const formatMonth = (date: Date | number): string => {
  return format(date, 'MMM yyyy');
};

export const formatExactTime = (date: Date | number): string => {
  return format(date, 'PP hh:mm:ss.SSS a');
};

export const formatWordDateAndTime = (date: Date | number): string => {
  return format(date, 'PPp');
};

export const formatDateReadable = (date: Date | number): string => {
  return format(date, 'PP');
};

export const formatDateReadableWithSeconds = (date: Date | number): string => {
  return format(date, 'PPpp');
};

export const formatDateMonDayYear = (date: Date | number): string => {
  return format(date, 'MMM d, yyyy');
};

export const formatDateOnly = (date: Date | number): string => {
  return format(date, 'yyyy-MM-dd');
};

export const formatTimeOnly = (date: Date | number): string => {
  return format(date, 'hh:mm a');
};

export const formatDateFromNow = (date: Date | number): string => {
  return formatDistanceToNow(date);
};

export const formatDateTimeInNewYork = (date: Date | number): string => {
  return formatTimeZone(date, 'yyyy-MM-dd h:mm:ss a z', { timeZone: NEW_YORK_TIMEZONE });
};

export const formatRelative = (date: Date | number): string => {
  return intlFormatDistance(date, Date.now());
};

export const formatReport = (date: Date | number): string => {
  return format(date, 'yyyyMMdd');
};

export const getTimezone = (date: Date | number): string => {
  return format(date, 'z');
};

export const formatYYYYMMDD = (date: Date | number): string => {
  return format(date, 'yyyy-MM-dd');
};

export const formatMMYYYY = (date: number | Date): string => {
  return format(date, 'MM-yyyy');
};

export const formatYYYYMM = (date: number | Date): string => {
  return format(date, 'yyyy-MM');
};

export const formatMMDDYYYY = (date: number | Date): string => {
  return format(date, 'MM-dd-yyyy');
};

export const parseYYYYMM = (value: string): Date => {
  return parse(value, 'yyyy-MM', new Date());
};

export const parseYYYYMMDD = (value: string): Date => {
  return parse(value, 'yyyy-MM-dd', new Date());
};

export const parseDateByFormat = (value: string, dateFormat = 'yyyy-MM-dd'): Date => {
  return parse(value, dateFormat, new Date());
};

export const lastChangedDate = (timestamps: string[]): string =>
  formatWordDateAndTime(
    new Date(timestamps.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]).getTime(),
  );
