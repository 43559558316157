import { Location } from 'history';
import { useLocation } from 'react-router-dom';

import { RouterConfig } from '../types';

const getPathWithoutQueryString = (path: string) => path.split('?')[0];

type AreChildrenActiveConfig = Pick<RouterConfig, 'subRoutes' | 'path' | 'exact' | 'isActive'>;

// This isn't actually used in our implementation currently, so pass a dummy value
const dummyMatch = { params: {}, isExact: false, path: '', url: '' };

const areChildrenActive = (location: Location, config: AreChildrenActiveConfig): boolean => {
  const { subRoutes = [] } = config;
  const { pathname } = location;

  if (subRoutes.length !== 0) {
    return subRoutes.some(subRoute => areChildrenActive(location, subRoute));
  }

  return Boolean(
    (config.exact && pathname === config.path) ||
      config.isActive?.(dummyMatch, location) ||
      pathname === getPathWithoutQueryString(String(config.path)),
  );
};

type UseChildrenActiveOptions = Pick<RouterConfig, 'subRoutes'>;

export const useChildrenActive = (config: UseChildrenActiveOptions): boolean => {
  const location = useLocation();

  const { subRoutes = [] } = config;

  return subRoutes.some(subRoute => {
    return areChildrenActive(location, subRoute);
  });
};
