import { BatchPreviewViews } from 'common/constants/batch';
import { BatchTypes } from 'common/constants/batch';
import { CopyText } from 'common/constants/copyText';
import { ErrorMessages } from 'common/constants/errorMessages';
import { getSeparatedRecords } from 'common/utils/getRecordsSeperatedByErrorTypeOrSuccess';

const { ACCOUNT_ERRORS_ONLY, BALANCE_ERRORS_ONLY, NO_SUCCESSFUL_RECORDS_FIX_ERRORS } =
  ErrorMessages;

const {
  NO_BATCH_ERRORS_READY_TO_SUBMIT,
  NO_FINTRAN_BATCH_ERRORS_READY_TO_SUBMIT,
  EXPORT_ORIGINAL_FILE,
  EXPORT_SUCCESSFUL,
  EXPORT_ALL_ACCOUNT_AND_BALANCE_ERRORS,
  PLEASE_FIX_AND_RETRY,
  SUBMIT_BATCH_WITH_NEGATIVES,
  SUBMIT_BATCH_TRANSACTIONS,
} = CopyText;

export const protectAccount = account => {
  if (account && account.errorCode) {
    return {
      accountNo: account.accountNo,
      error: true,
      accountNotFound: true,
      errorMessage: 'Account not found',
    };
  }

  return account;
};

export const combinePreviousResponsesAndCurrentResponse = (
  current,
  previous = { failed: [], successes: [] },
) => {
  return {
    failed: [...previous.failed, ...current.failed.map(error => protectAccount(error))],
    successes: [
      ...previous.successes,
      ...current.successes.map(success => protectAccount(success)),
    ],
  };
};

export const getOriginalBatchLines = (previewLines, batchType) => {
  if (!previewLines) return [];

  return (
    previewLines
      .filter(item => {
        if (batchType === BatchTypes.FINTRAN_ADD) {
          return item.batchCode;
        }
        return true;
      })
      .map(item => {
        const { error, ...rest } = item;

        return rest;
      }) || []
  );
};

export const determineIfIsReadyToSubmitBatch = (hasErrors, preview) =>
  !hasErrors && !preview.accountErrors.length;

export const getBatchPreviewErrorText = (
  { balanceErrors, accountErrors, successes },
  currentView,
  batchType,
) => {
  const { ACCOUNT_ERRORS, BALANCE_ERRORS, SUCCESSFUL } = BatchPreviewViews;
  const noErrors = !balanceErrors.length && !accountErrors.length;

  if (
    [BALANCE_ERRORS, ACCOUNT_ERRORS].includes(currentView) &&
    noErrors &&
    batchType === BatchTypes.FINTRAN_ADD
  ) {
    return NO_FINTRAN_BATCH_ERRORS_READY_TO_SUBMIT;
  }

  if ([BALANCE_ERRORS, ACCOUNT_ERRORS].includes(currentView) && noErrors) {
    return NO_BATCH_ERRORS_READY_TO_SUBMIT;
  }

  if (currentView === BALANCE_ERRORS && !balanceErrors.length && accountErrors.length) {
    return ACCOUNT_ERRORS_ONLY;
  }

  if (currentView === ACCOUNT_ERRORS && !accountErrors.length && balanceErrors.length) {
    return BALANCE_ERRORS_ONLY;
  }

  if (currentView === SUCCESSFUL && !successes.length && !noErrors) {
    return NO_SUCCESSFUL_RECORDS_FIX_ERRORS;
  }

  return null;
};

export const determineIfShouldGoToAccountErrors = (batchType, records) =>
  records.accountErrors.length && batchType === BatchTypes.FINTRAN_ADD;

export const getExportLabelText = (currentView, props) =>
  currentView === BatchPreviewViews.VALIDATION_ERRORS
    ? EXPORT_ORIGINAL_FILE
    : props.isSuccess
    ? EXPORT_SUCCESSFUL
    : EXPORT_ALL_ACCOUNT_AND_BALANCE_ERRORS;

export const getSubmitBatchButtonText = (isNotReadyToSubmitBatch, preview) => {
  return isNotReadyToSubmitBatch
    ? PLEASE_FIX_AND_RETRY
    : preview.balanceErrors.length
    ? SUBMIT_BATCH_WITH_NEGATIVES
    : SUBMIT_BATCH_TRANSACTIONS;
};

export default {
  determineIfIsReadyToSubmitBatch,
  getBatchPreviewErrorText,
  determineIfShouldGoToAccountErrors,
  getSeparatedRecords,
  protectAccount,
  combinePreviousResponsesAndCurrentResponse,
  getOriginalBatchLines,
  getExportLabelText,
  getSubmitBatchButtonText,
};
