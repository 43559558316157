import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  ReportName: 'PositionRestingOrder',
  isVisible: false,
};

const handlers = {
  REPORTS_SELECT: (state, action) =>
    immer(state, draftState => {
      draftState.ReportName = action.payload.ReportName;
    }),

  REPORTS_GET_SUCCESS: (state, action) =>
    immer(state, draftState => {
      draftState.report = action.payload.report;
      draftState.isVisible = true;
    }),

  REPORTS_GET_ERROR: (state, action) =>
    immer(state, draftState => {
      draftState.report = null;
      draftState.isVisible = true;
    }),

  REPORTS_CLOSE: (state, action) =>
    immer(state, draftState => {
      draftState.isVisible = false;
    }),
};

export const reportsReducer = generateReducer(initialState, handlers);
