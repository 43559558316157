export type FeatureToggle = {
  PERMISSIONS_PAGE: boolean;
  FPSL_VIEW: boolean;
  EMPLOYEE_LAST_LOGIN: boolean;
  TAX_STATUS_AND_RECIPIENT_CODES: boolean;
  SHARE_TRANSFER_REDESIGN: boolean;
  TRUSTED_CONTACT: boolean;
  ACATS_DOC_TYPES: boolean;
  OPTIONS: boolean;
  MUTUAL_FUNDS: boolean;
  CLIENTS_CREATE_PAGE: boolean;
  FIXED_INCOME: boolean;
  CANCEL_REBILL: boolean;
  TICKET_AUDIT_CANCEL_REBILL: boolean;
  BATCHES_UPDATE: boolean;
  OPTIONS_EXPIRATION_DAY: boolean;
  ROLES_PAGE: boolean;
  PERMISSIONS_GROUPS_PAGE: boolean;
  RECENT_BATCHES_REDESIGN: boolean;
  ORDER_ENTRY_FLOW: boolean;
  BANK_ACCOUNTS_REDESIGN: boolean;
  EQUITIES_DIVIDEND_REINVESTMENT: boolean;
  MANAGE_PARTNERS_PAGE: boolean;
  WLP_REDESIGN: boolean;
};

export const defaultFeatureToggle: FeatureToggle = {
  PERMISSIONS_PAGE: false,
  FPSL_VIEW: false,
  EMPLOYEE_LAST_LOGIN: false,
  TAX_STATUS_AND_RECIPIENT_CODES: false,
  SHARE_TRANSFER_REDESIGN: false,
  TRUSTED_CONTACT: false,
  ACATS_DOC_TYPES: true,
  OPTIONS: false,
  FIXED_INCOME: false,
  MUTUAL_FUNDS: false,
  CLIENTS_CREATE_PAGE: false,
  CANCEL_REBILL: false,
  TICKET_AUDIT_CANCEL_REBILL: false,
  BATCHES_UPDATE: false,
  OPTIONS_EXPIRATION_DAY: false,
  ROLES_PAGE: false,
  PERMISSIONS_GROUPS_PAGE: false,
  RECENT_BATCHES_REDESIGN: false,
  ORDER_ENTRY_FLOW: false,
  BANK_ACCOUNTS_REDESIGN: false,
  EQUITIES_DIVIDEND_REINVESTMENT: false,
  MANAGE_PARTNERS_PAGE: false,
  WLP_REDESIGN: false,
};
