/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const executedAwayOrder = {
  displayName: 'Executed Away Order',
  permission: 'EXECUTED_AWAY_ORDER_CREATE_BATCH_VIEW',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    broker: {
      label: 'Broker',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'broker',
    },
    batch: {
      label: 'Batch to Post-trade',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'batch',
    },
    quantity: {
      label: 'Quantity',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'number',
      serverKey: 'quantity',
    },
    price: {
      label: 'Price',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'number',
      serverKey: 'price',
    },
    commission: {
      label: 'Commission',
      textAlign: 'right',
      canSort: false,
      required: false,
      dataType: 'money',
      serverKey: 'commission',
    },
    clientnotes: {
      label: 'Order Notes',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'clientNotes',
    },
    donotreconcile: {
      label: 'Do Not Reconcile',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'doNotReconcile',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountNo: 'DWER000010',
      symbol: 'AAPL',
      quantity: 9.10192,
      price: 150.54,
      broker: 'DW',
      batch: 'true',
      commission: 0,
      clientNotes: 'Buy 9.10192 shares of AAPL at 150.54',
      doNotReconcile: 'false',
    },
  ],
};

export { executedAwayOrder };
