import { Box, SvgIcon } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

import DriveHubLogo from '../../assets/DriveHub_logo_negative@2x.png';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const AnimationContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const StyledLogoBox = styled(Box)<{ $isOpen: boolean }>`
  position: relative;
  width: ${props => (props.$isOpen ? 127 : 0)}px;
  transition: ${props =>
    props.$isOpen
      ? props.theme.transitions.create(['width', 'margin'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen,
        })
      : props.theme.transitions.create(['width', 'margin'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen,
        })};
  margin-left: ${props => (props.$isOpen ? props.theme.spacing(1.5) : 0)};
`;

const LogoIcon = styled(SvgIcon)`
  margin-top: auto;
  margin-bottom: auto;
` as typeof SvgIcon;

const DriveHubLogoIcon = styled('img')`
  width: 127px;
`;

const Version = styled.div`
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.1px;
`;

export interface HeaderProps {
  onClick: () => void;
  isOpen: boolean;
}

// TODO: Image would be better as an imageset, but need Webpack changes.
// Went with a 2x for now to give Retina users a sharper image
export const Header: FC<HeaderProps> = props => {
  const { isOpen, onClick } = props;

  const version = `V${__APP_VERSION__}`;

  return (
    <Box component="div" display="flex" flexDirection="row" height="29px">
      <LogoIcon
        component={Logo}
        onClick={onClick}
        title="DriveHub"
        role="button"
        data-testid="DWLogoIcon"
      />
      <AnimationContainer>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <StyledLogoBox component="div" display="flex" flexDirection="column" $isOpen={isOpen}>
          <DriveHubLogoIcon src={DriveHubLogo} alt="DriveHub Logo" />
          <Version>{version}</Version>
        </StyledLogoBox>
      </AnimationContainer>
    </Box>
  );
};
