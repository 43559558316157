import createStore from './legacy/store/createStore';

// The tests are trying to pull the store from index.js when request.js
// is needed, and this causes the full app to attempt to be instantiated
// Let scope for tests only to allow setting
// eslint-disable-next-line import/no-mutable-exports
export let store = createStore();

export const setStore = (newStore: typeof store): void => {
  store = newStore;
};
