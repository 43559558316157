//
import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  addInstrumentPopup: false,
  deleteInstrumentModal: false,
  editingDetails: false,

  deleteSymbol: '',

  cleaned: [],
  errors: [],

  searchQuery: '',
};

const handlers = {
  TOGGLE_ADD: (state, action) =>
    immer(state, draftState => {
      draftState.addInstrumentPopup = !state.addInstrumentPopup;
    }),
  TOGGLE_DELETE: (state, action) =>
    immer(state, draftState => {
      draftState.deleteSymbol = action.payload.deleteSymbol ? action.payload.deleteSymbol : '';
      draftState.deleteInstrumentModal = !state.deleteInstrumentModal;
    }),
  TOGGLE_EDIT: (state, action) =>
    immer(state, draftState => {
      draftState.editingDetails = !state.editingDetails;
    }),

  INSTRUMENT_GROUP_PARSE_START: null,
  INSTRUMENT_GROUP_PARSE_ERROR: null,
  INSTRUMENT_GROUP_PARSE_SUCCESS: (state, action) =>
    immer(state, draft => {
      draft.cleaned = action.payload.results.cleaned;
      draft.errors = action.payload.results.errors;
    }),

  CANCEL_UPLOAD: (state, action) =>
    immer(state, draft => {
      draft.cleaned = [];
      draft.errors = [];
    }),
  FILTER_INSTRUMENT_GROUPS: (state, action) =>
    immer(state, draftState => {
      draftState.searchQuery = action.payload.searchQuery.toLowerCase();
    }),
};

export const instrumentGroupPageReducer = generateReducer(initialState, handlers);
