import debounce from 'debounce';
import { loadingEnd, loadingStart } from 'reducers/loading';
import { clearStatusMessages, createErrorMessage } from 'reducers/statusMessage';

import resultTypes from './resultTypes';
import sharedFetcherDatabase from './resultTypes/sharedFetchers/sharedFetcherDatabase';

// Populated later once we have a reference to dispatch
const DEBOUNCE_DELAY = 250;
let searchDebounce;

const setPreliminaryQuery = query => ({
  type: 'SEARCH_SET_QUERY_PRELIMINARY',
  payload: { query },
});

const beginSearch = query => ({
  type: 'SEARCH_FETCH_START',
  payload: { query },
  ...loadingStart('SEARCH_FETCH'),
});

const completeSearch = (query, results) => ({
  type: 'SEARCH_FETCH_SUCCESS',
  payload: { query, results },
  ...loadingEnd('SEARCH_FETCH'),
});

export const resetSearch = () => ({
  type: 'SEARCH_RESET',
});

export const doSearch = query => (dispatch, getState) => {
  if (!query) return;
  const settings = getState().settings;
  dispatch(beginSearch(query));
  const sharedInstances = {};
  const promises = resultTypes
    .filter(resultType => !resultType.applicableQueries || resultType.applicableQueries.test(query))
    .map(resultType => {
      if (typeof resultType.fetchResults !== 'function') {
        if (!sharedInstances[resultType.fetchResults.id]) {
          sharedInstances[resultType.fetchResults.id] = sharedFetcherDatabase[
            resultType.fetchResults.id
          ](query, settings);
        }
        return {
          resultType,
          promise: sharedInstances[resultType.fetchResults.id],
        };
      }

      return {
        resultType,
        promise: resultType.fetchResults(query, settings),
      };
    });

  const consequencedPromises = promises.map(({ promise, resultType }) =>
    (promise instanceof Promise ? promise : Promise.resolve(promise)).then(results => {
      const processedResults = resultType.transformFetchedResults
        ? resultType.transformFetchedResults(results, settings)
        : results;

      return { [resultType.id]: processedResults };
    }),
  );
  return Promise.all(consequencedPromises)
    .then(categorizedResults => {
      dispatch(completeSearch(query, Object.assign({}, ...categorizedResults)));
    })
    .catch(e => {
      dispatch(completeSearch(query, ''));
    });
};

export const beginDebouncedSearch = query => (dispatch, getState) => {
  dispatch(setPreliminaryQuery(query));

  if (!searchDebounce) {
    searchDebounce = debounce(query => dispatch(doSearch(query.trim())), DEBOUNCE_DELAY);
  }
  if (query && query.length >= 3) {
    searchDebounce(query);
  }
};
