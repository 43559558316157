import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { generalSecLendingGraphQLClient } from 'src/app/services/graphqlClient';

export type UpdateUserRolePrivilegesVariables = {
  authToken: string;
  dataSrc: string;
};

export const useUpdateUserRolePrivileges = (): UseMutationResult<
  string,
  Error,
  UpdateUserRolePrivilegesVariables
> => {
  return useMutation<string, Error, UpdateUserRolePrivilegesVariables>({
    mutationFn: async variables => {
      const { updateUserRolePrivileges } = await generalSecLendingGraphQLClient.request<{
        updateUserRolePrivileges: string;
      }>(
        gql`
          mutation ($req: EmployeeInput) {
            updateUserRolePrivileges(employeeInput: $req)
          }
        `,
        { req: variables },
      );

      return updateUserRolePrivileges;
    },
  });
};
