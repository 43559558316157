//
import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  editMode: false,
};

const handlers = {
  TOGGLE_MODE: (state, action) =>
    immer(state, draft => {
      draft.editMode = !draft.editMode;
    }),
};

export default generateReducer(initialState, handlers);
