import { LoadingButton } from '@drivehub/ui-components';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAddAcknowledgment, useGetDisclosures, useSnackbar, useWhoAmI } from 'app/hooks';
import { format } from 'date-fns';
import { isUndefined } from 'lodash';
import { lazy, Suspense, useCallback } from 'react';
import { copyText } from 'src/app/internationalization';

import { getActiveDisclosures } from './utils';

const PDFDocument = lazy(() => import('./PDFDocument'));

const {
  navbar: {
    help: {
      apiTermsOfUse,
      apiTermsOfUseModal: {
        lastUpdated,
        agreeAndContinue,
        termsSuccessfullyAccepted,
        errorAcceptingTerms,
      },
    },
  },
} = copyText;

export type APITermsOfUseModalProps = {
  open: boolean;
};

export const APITermsOfUseModal = ({ open }: APITermsOfUseModalProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { data: latestTermsAndConditions } = useGetDisclosures({
    select: getActiveDisclosures,
  });

  const { data: whoAmIData } = useWhoAmI();
  const userID = whoAmIData?.employee.id;

  const termsUpdatedDate = latestTermsAndConditions?.updated || Date.now();

  const { mutate, isLoading: isAddAcknowledgmentLoading } = useAddAcknowledgment();

  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });

  const disclosureID = latestTermsAndConditions?.disclosureID;
  const version = latestTermsAndConditions?.version;

  const handleConfirm = useCallback(() => {
    if (isUndefined(disclosureID) || isUndefined(version) || isUndefined(userID)) {
      return;
    }

    mutate(
      { userID, disclosureID, version },
      {
        onSuccess: () => {
          enqueueSnackbar(termsSuccessfullyAccepted, {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(errorAcceptingTerms, {
            variant: 'error',
          });
        },
      },
    );
  }, [userID, disclosureID, version, mutate, enqueueSnackbar]);

  return (
    <Dialog fullWidth fullScreen={fullScreen} open={open}>
      <DialogTitle>{apiTermsOfUse}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="subtitle2" mb={1}>{`${lastUpdated} ${format(
          new Date(termsUpdatedDate),
          'MMMM yyyy',
        )}`}</Typography>
        {/* <Box flexShrink={1} minHeight={0} minWidth={0} display="flex" flexDirection="column"> */}
        <Suspense fallback={<CircularProgress color="primary" />}>
          <PDFDocument url={latestTermsAndConditions?.url} />
        </Suspense>
        {/* </Box> */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton
          onClick={handleConfirm}
          loading={isAddAcknowledgmentLoading}
          disabled={isAddAcknowledgmentLoading}
          color="primary"
        >
          {agreeAndContinue}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
