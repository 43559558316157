import { Box, Button } from '@drivehub/ui-components';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSession } from 'src/app/hooks';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';

const {
  sessionModal: { youWillBeLoggedOut, continueSessionPrompt, logOut, continueSession },
} = copyText;

export const SessionKeepAliveModal = (): JSX.Element => {
  const { logoutSession, keepSessionAlive, isSessionIdle, timeLeft } = useSession();

  const headerString = timeLeft ? replaceFieldInCopyText(youWillBeLoggedOut, String(timeLeft)) : '';

  return (
    <Dialog open={isSessionIdle}>
      <DialogTitle>{headerString}</DialogTitle>
      <DialogContent>{continueSessionPrompt}</DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button color="secondary" variant="contained" onClick={logoutSession}>
            {logOut}
          </Button>
          <Box ml={1}>
            <Button color="primary" variant="contained" onClick={keepSessionAlive}>
              {continueSession}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
