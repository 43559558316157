import { ACCOUNT_EXCLUSION_REASON } from 'src/legacy/common/constants/batch';
/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const accountExclusion = {
    displayName: 'Account Exclusion (Settlements)',
    permission: 'RECON_ACCOUNT_EXCLUSION_CREATE_BATCH',
    csvInputs: {
        accountno: {
            label: 'Account No',
            textAlign: 'left',
            canSort: false,
            required: true,
            dataType: 'string',
            serverKey: 'accountNo',
        },
        reason: {
            label: 'Reason',
            textAlign: 'left',
            canSort: false,
            required: true,
            dataType: 'string',
            serverKey: 'reason',
        },
        comment: {
            label: 'Comment',
            textAlign: 'left',
            canSort: false,
            required: false,
            dataType: 'string',
            serverKey: 'comment',
        },        
    },
    checks: {
        columns: {
          reason: {
            includes: ACCOUNT_EXCLUSION_REASON,
          },
        },
      },
    groupPreviewBy: '',
    doAccumulatingBalance: false,
    exampleData: [
        {
            accountNo: 'DMZR000123',
            reason : 'ESCHEATMENT',
            comment : 'Test',
        },
    ],
};

export { accountExclusion };
