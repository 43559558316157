import { reduceInstrumentsByID, reduceInstrumentsBySymbol } from 'modules/instruments';

const initialState = {
  list: [],
  instrumentsBySymbol: {},
  filteredList: [],
  searchQuery: '',
  completedSearchQuery: '',
  instrument: {},
  quotesBySymbol: {},
  instrumentsByID: {},
  isin: null,
  loadingIsin: true,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'INSTRUMENTS_GET_SUCCESS':
      const updatedInstrumentList = payload.updatedInstrument
        ? payload.list
            .filter(instrument => {
              return instrument.id !== payload.updatedInstrument.id;
            })
            .concat([payload.updatedInstrument])
        : payload.list;
      return {
        ...state,
        list: updatedInstrumentList,
        instrumentsBySymbol: reduceInstrumentsBySymbol(updatedInstrumentList),
        instrumentsByID: reduceInstrumentsByID(updatedInstrumentList),
      };

    case 'COMPLETE_INSTRUMENT_SEARCH':
      return {
        ...state,
        filteredList: payload.list,
        completedSearchQuery: payload.query,
        searchQuery: payload.query,
      };

    case 'INSTRUMENT_GET_SUCCESS':
      return { ...state, instrument: payload.instrument };

    case 'INSTRUMENT_QUOTE_GET_SUCCESS':
      const bySymbol = payload.quotes.reduce((acc, curr) => {
        acc[curr.symbol] = curr;
        return acc;
      }, {});
      return { ...state, quotesBySymbol: { ...state.quotesBySymbol, ...bySymbol } };

    // -------------- FOR ISIN INFO REQUEST -------------- //
    case 'ISIN_CLEAR':
      return { ...state, isin: '' };

    case 'ISIN_GET_START':
      return { ...state, loadingIsin: true };

    case 'ISIN_GET_SUCCESS':
      return { ...state, isin: payload.isin, loadingIsin: false };

    case 'ISIN_GET_ERROR':
      return { ...state, isin: null, loadingIsin: false };
    // --------------------------------------------------- //

    default:
      return state;
  }
}
