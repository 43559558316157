import { Box, LinkButton, LoadingButton, TextField } from '@drivehub/ui-components';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useUnmountPromise } from 'react-use';
import { useSnackbar } from 'src/app/hooks';
import { useSignIn } from 'src/app/hooks/useAuth';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';
import * as yup from 'yup';

import { AuthStateContext } from './AuthContext';
import { isCognitoError } from './types';
import { transformLoginFormVariables } from './utils/login';

const {
  authorization: {
    username,
    password,
    incorrectUsernameOrPassword,
    signInToYourAccount,
    forgotYourPassword,
    login,
    signIn: signInCopy,
    resetPassword,
    required,
  },
  common: { anUnknownErrorOccurred },
  formValidation: { isARequiredField },
} = copyText;

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = yup.object({
  username: yup.string().trim().required(replaceFieldInCopyText(isARequiredField, username)),
  password: yup.string().trim().required(replaceFieldInCopyText(isARequiredField, password)),
});

export const Login = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  const signIn = useSignIn();
  const { setAuthState, setUsername } = useContext(AuthStateContext);
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const mounted = useUnmountPromise();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const transformedValues = transformLoginFormVariables(values);

      try {
        await mounted(signIn(transformedValues));
        setUsername(transformedValues.username);
      } catch (e) {
        if (isCognitoError(e)) {
          if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
            formik.setFieldError('username', incorrectUsernameOrPassword);
            formik.setFieldError('password', incorrectUsernameOrPassword);
          } else {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(anUnknownErrorOccurred, { variant: 'error' });
        }
      }
    },
  });

  const forgotPassword = () => {
    setAuthState('forgotPassword');
  };

  return (
    <>
      <Typography variant="h5">{signInToYourAccount}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            name="username"
            type="text"
            label={username}
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="username"
            error={formik.touched.username && !!formik.errors.username}
            helperText={formik.touched.username && formik.errors.username}
            autoFocus
          />
          <TextField
            name="password"
            type="password"
            label={password}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="current-password"
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Typography variant="body2" sx={{ display: 'inline' }}>
            {forgotYourPassword}
            &nbsp;
            <LinkButton
              onClick={forgotPassword}
              color="colors.white"
              sx={{ display: 'inline' }}
              type="button"
            >
              {resetPassword}
            </LinkButton>
          </Typography>
          <Typography variant="body2">{required}</Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <LoadingButton
              disabled={!formik.isValid}
              type="submit"
              variant="contained"
              color="primary"
              loading={formik.isSubmitting || isLoading}
            >
              {signInCopy}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <Helmet>
        <title>{login}</title>
      </Helmet>
    </>
  );
};
