const shapeMap = array => {
  const map = array.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});
  return map;
};

const shapeIdsArray = array => {
  const ids = array.map(p => p.id);
  return ids;
};

const shapeOrdersMap = orders => {
  const map = orders.reduce((acc, current) => {
    acc[current.orderID] = current;
    return acc;
  }, {});
  return map;
};

const shapeOrdersIdsArray = orders => {
  const ids = orders.map(p => p.orderID);
  return ids;
};

const shapeListByUserid = (data, userID) => {
  const listObj = data.reduce((acc, current) => {
    const id = current.userID || userID;
    const itemID = current.id || current.orderID;
    if (!acc[id]) {
      acc[id] = [itemID];
    } else {
      acc[id].push(itemID);
    }
    return acc;
  }, {});
  return listObj;
};

export { shapeIdsArray, shapeListByUserid, shapeMap, shapeOrdersIdsArray, shapeOrdersMap };
