export const loadingStart = key => ({
  loading: key,
});

export const loadingEnd = key => ({
  loaded: key,
});

const loadingReducer = (loading = {}, action) => {
  if (action.loading) {
    return {
      ...loading,
      [action.loading]: true,
    };
  }

  if (action.loaded) {
    return {
      ...loading,
      [action.loaded]: false,
    };
  }

  return loading;
};

export default loadingReducer;
