import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import type { Permission } from '../types/permission';

const endpoint = new URL('/api/manage-permissions', window.location.origin).href;

export const useAddPermission = (): UseMutationResult<
  Permission,
  { errorCode: number; message: string },
  Permission
> => {
  const queryClient = useQueryClient();
  const mutationFn = async (variables: Permission) => {
    const permissionCreateVariables: Permission = {
      active: variables.active,
      documentation: variables.documentation,
      internalOnlyRecommended: variables.internalOnlyRecommended,
      permissionID: variables.permissionID,
    };

    const data = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(permissionCreateVariables),
      headers: {
        'Content-type': 'application/json',
      },
    });

    return (await data.json()).data as Permission;
  };

  return useMutation<Permission, { errorCode: number; message: string }, Permission>({
    mutationFn,
    onMutate: async newPermission => {
      await queryClient.cancelQueries({ queryKey: ['manage-permissions'] });

      queryClient.setQueryData(['manage-permissions'], originalPermissions => {
        if (!originalPermissions) return;
        const filteredRoles = (originalPermissions as Permission[]).filter(
          permission => permission.permissionID !== newPermission.permissionID,
        );
        return [...filteredRoles, newPermission];
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['manage-permissions'] });
    },
  });
};
