import { FC } from 'react';

import { isEnvironmentKey } from '../../hooks';
import { ExternalNavItem } from './ExternalNavItem';
import { NavDropdown } from './NavDropdown';
import { NavItem } from './NavItem';
import { SecondaryNav } from './SecondaryNav';
import { NavProps } from './types';

export const PrimaryNav: FC<NavProps> = props => {
  const { subRoutes = [], path, isExternal, isHighlighted } = props;

  if (subRoutes.length > 0) {
    return (
      <NavDropdown
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        component={SecondaryNav}
        // eslint-disable-next-line react/destructuring-assignment
        variant={isHighlighted ? 'secondary' : 'primary'}
      />
    );
  }

  if (isExternal && isEnvironmentKey(path)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ExternalNavItem {...props} path={path} variant="primary" />;
  }

  // Standalone
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NavItem {...props} path={path as string} variant="primary" />;
};
