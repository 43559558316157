export var templates = {
  'exampleBatchFileTemplate': {},
  'invalidFileNameTemplate': {},
  'missingColumnsTemplate': {},
  'timeCheckTemplate': {},
  'transactionLimitTemplate': {},
  'uploadFileTemplate': {},
};

templates['exampleBatchFileTemplate'] =   function(data) {
var __t, __p = '';
__p += 'Example Batch ' +
((__t = ( data.displayName )) == null ? '' : __t) +
' File';
return __p
};

templates['invalidFileNameTemplate'] =   function(data) {
var __t, __p = '';
__p += 'Please upload a batch file with a name containing ' +
((__t = ( data.fileNameLengthMax )) == null ? '' : __t) +
' characters or less for this batch type. Please use a file name that does not include the &, #, or % characters.';
return __p
};

templates['missingColumnsTemplate'] =   function(data) {
var __t, __p = '';
__p += 'You selected ' +
((__t = ( data.displayName )) == null ? '' : __t) +
', but your file is missing required columns. See Example file below for which columns are required or select a different Batch Type';
return __p
};

templates['timeCheckTemplate'] =   function(data) {
var __t, __p = '';
__p += 'Batches can only be submitted from ' +
((__t = ( data.startTime )) == null ? '' : __t) +
' - ' +
((__t = ( data.endTime )) == null ? '' : __t) +
' ' +
((__t = ( data.timeZone )) == null ? '' : __t);
return __p
};

templates['transactionLimitTemplate'] =   function(data) {
var __t, __p = '';
__p += 'The upper limit for a transaction should be ' +
((__t = ( data.limit )) == null ? '' : __t) +
' or lesser';
return __p
};

templates['uploadFileTemplate'] =   function(data) {
var __t, __p = '';
__p += 'Upload File: ' +
((__t = ( data.fileName )) == null ? '' : __t);
return __p
};
