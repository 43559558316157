import { PAGE_LIMIT } from 'common/constants/moneyMovement';

const updateInitialValues = {
  // default to empty object to have an initial one line of inputs
  fees: [{}],
};

const initialState = {
  byID: {},
  currentStatus: '',
  currentType: '',
  directions: {},
  statusCounts: {},
  query: '',
  selected: [],
  renderEditListItemsModal: false,
  editResults: {
    success: {},
    failed: {},
  },
  // initial values for update money movement
  initialValues: updateInitialValues,
  downloadCSV: {
    active: false,
  },
  partners: [],
  rawListData: {},
};

export function moneyMovementReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'MM_RESET':
      return { ...state, statusCounts: {}, query: '' };

    case 'MM_ERROR':
      return { ...state, submittingEdit: false };

    case 'MM_SEARCH':
      return { ...state, query: payload.query };

    // get payment details actions
    // MM_GET_DETAILS_START:
    // MM_DETAIL_ERROR:

    case 'MM_GET_DETAILS_SUCCESS':
      const id = payload.data.id;
      const statusHistory = payload.data.statusHistory;
      return {
        ...state,
        byID: {
          ...state.byID,
          [id]: { ...state.byID[id], statusHistory },
        },
      };

    case 'MM_GET_LIST_SUCCESS':
      const directionalPageLimit =
        action.payload.direction === 'virtual' ? PAGE_LIMIT - 1 : PAGE_LIMIT;

      const paymentsArr = Array.isArray(action.payload.list.data)
        ? action.payload.list.data.slice(0, directionalPageLimit)
        : action.payload.list.data.data.slice(0, directionalPageLimit);
      const paymentsByID = paymentsArr.reduce((acc, currPayment) => {
        acc[currPayment.id] = currPayment;
        return acc;
      }, {});
      const prevPageTargetID =
        action.payload.list.data.length > 0 && action.payload.list.data[0].subPartnerID
          ? `${action.payload.list.data[0].subPartnerID.id}#${action.payload.list.data[0].timestamp}`
          : null;

      return {
        ...state,
        rawListData: payload.list,
        byID: { ...state.byID, ...paymentsByID },
        directions: {
          ...state.directions,
          [action.payload.status]: {
            ids: Object.keys(paymentsByID),
            count: Object.keys(paymentsByID).length,
            hasNext:
              action.payload.paginationDirection === 'prev' ||
              action.payload.list.data.length > directionalPageLimit,
            pageNumber: action.payload.pageNumber,
            prevPageTargetID,
          },
        },
        currentStatus: payload.status,
        currentType: payload.type,
        selected: [],
      };

    case 'MM_UPDATE_STATUS_COUNT':
      return {
        ...state,
        statusCounts: {
          ...state.statusCounts,
          [payload.status]: {
            count: payload.count,
          },
        },
      };

    // get payments count actions
    // START_GET_MM_COUNT:
    // FINISH_GET_MM_COUNT:

    // select actions
    case 'MM_SELECT':
      const selectedId = payload.id;

      return {
        ...state,
        selected: state.selected.includes(selectedId)
          ? state.selected.filter(x => x !== selectedId)
          : [...state.selected, selectedId],
      };

    case 'MM_SELECT_MANY':
      return { ...state, selected: payload.ids };

    case 'MM_DESELECT_ALL':
      return { ...state, selected: [] };

    // edit payment actions
    // MM_EDIT_END:
    // MM_EDIT_START:
    // PAYMENT_EDIT_ERROR:
    // MM_EDIT_LIST_SUCCESS:

    case 'EDIT_PAYMENTS_END':
      const oldStatusIDs = [...state.directions[payload.status].ids];

      // if the status changed, remove the id from the current status direction
      const newStatusIDs = oldStatusIDs.filter(paymentID => {
        if (payload.success[paymentID] && payload.success[paymentID].payload.status) {
          return false;
        }
        return true;
      });

      let newState = { ...state };

      Object.keys(action.payload.success).forEach(successID => {
        const successPayload = payload.success[successID].payload;
        if (successPayload.fees) {
          newState = {
            ...newState,
            byID: { ...state.byID, [successID.fees]: successPayload.fees },
          };
        }

        if (successPayload.wlpFinTranTypeID) {
          newState = {
            ...newState,
            byID: { ...state.byID, [successID.wlpFinTranTypeID]: successPayload.wlpFinTranTypeID },
          };
        }
      });

      Object.keys(action.payload.failed).forEach(failedID => {
        newState.byID[failedID].error = payload.failed[failedID];
        newState = {
          ...newState,
          byID: { ...state.byID, [failedID.error]: payload.failed[failedID] },
        };
      });

      return {
        // ...state,
        ...newState,
        editResults: { ...state.editResults, success: payload.success, failed: payload.failed },
        directions: {
          ...state.directions,
          [payload.status]: { ...state.directions[payload.status], ids: newStatusIDs },
        },
        selected: [],
      };

    // modal actions
    case 'MM_SHOW_EDIT_LIST_ITEM_MODAL':
      return { ...state, renderEditListItemsModal: true };

    case 'MM_HIDE_EDIT_LIST_ITEM_MODAL':
      return { ...state, renderEditListItemsModal: false };

    case 'MM_SET_UPDATE_MODAL_INITIAL_VALUES':
      if (payload.selectedPaymentID) {
        const initialValues = {};
        const selectedPaymentDetails = state.byID[payload.selectedPaymentID];

        let fees = [];
        if (selectedPaymentDetails.fees && selectedPaymentDetails.fees.length) {
          fees = selectedPaymentDetails.fees.map(fee => ({
            amount: fee.amount ? Math.abs(fee.amount) : 0,
            wlpFinTranTypeID: fee.description,
          }));
        }
        initialValues.fees = fees.length ? fees : [{}];

        if (selectedPaymentDetails.wlpFinTranTypeID) {
          initialValues.wlpFinTranTypeID = selectedPaymentDetails.wlpFinTranTypeID;
        }
        return { ...state, initialValues };
      } else {
        return { ...state, initialValues: updateInitialValues };
      }
    // download csv actions
    case 'MM_DOWNLOAD_CSV_END':
      return { ...state, downloadCSV: { ...state.downloadCSV, active: false } };

    case 'MM_DOWNLOAD_CSV_START':
      return { ...state, downloadCSV: { ...state.downloadCSV, active: true } };

    case 'GET_PARTNERS_SUCCESS':
      return { ...state, partners: payload };

    default:
      return state;
  }
}
