import { ListItemIcon } from '@mui/material';
import styled from 'styled-components';

import { Variant } from './types';

export const NavbarListItemIcon = styled(ListItemIcon)<{ variant: Variant }>`
  min-width: ${props => props.theme.spacing(5)};

  svg {
    height: 24px;
    width: 24px;
  }
`;
