// error reducer...
export const createError = (msg = 'Error message is undefined.', code = '-1', name) => ({
  error: { msg, code, name },
});

export const clearErrors = type => {
  return {
    clearErrors: type || true,
  };
};

const errorReducer = (errors = {}, action) => {
  if (action.clearErrors) {
    // const copy = { ...errors };
    delete errors[action.clearErrors === true ? action.type : action.clearErrors];
    return errors;
  }

  if (action.error) {
    const { msg, code, name } = action.error;
    const id = name || action.type;

    if (!errors[id]) errors[id] = [];

    return {
      ...errors,
      [id]: [].concat(errors[id]).concat({ msg, code }),
    };
  }

  return errors;
};

export default errorReducer;
