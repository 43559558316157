import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  subscriptionsByUserID: {},
};

const handlers = {
  GET_USERS_SUBSCRIPTIONS_START: null,
  GET_USERS_SUBSCRIPTIONS_ERROR: null,
  GET_USERS_SUBSCRIPTIONS_SUCCESS: (state, action) =>
    immer(state, draftState => {
      const userSubscriptions = action.payload.subscriptions.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      draftState.subscriptionsByUserID[action.payload.userID] = userSubscriptions;
    }),
  DELETE_SUBSCRIPTION_START: null,
  DELETE_SUBSCRIPTION_ERROR: null,
  DELETE_SUBSCRIPTION_SUCCESS: null,
};

export default generateReducer(initialState, handlers);
