import type { PropsWithChildren } from 'react';
import { useWhoAmI } from 'src/app/hooks';
import { useUser } from 'src/app/hooks/useAuth';

export const AuthorizationLoader = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  // These two pieces constitute the core of our auth, Cognito and WhoAmI
  const user = useUser();
  const { data: whoAmIData } = useWhoAmI();
  const isAuthLoaded = user && whoAmIData;

  return <>{isAuthLoaded && <>{children}</>}</>;
};
