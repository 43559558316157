import { isUndefined } from 'lodash';
import { formatReport, isRequiredField, neverReached } from 'src/app/utils';

import { copyText } from '../../internationalization';
import { WhoAmI } from '../../types';
import { DailyReportParameters, ReportParameters } from '../../types/reports';
import { Report, ReportParameter, TPlusOneReportCode } from '../../types/tPlusOne';

const {
  common: { month },
  partner: { partner: partnerLabel },
  tPlusOne: {
    reportTypes: { daily },
  },
} = copyText;

export const getReportHeaderText = (reportType: TPlusOneReportCode): string => {
  switch (reportType) {
    case 'tplusone_daily':
      return daily;
    default:
      return neverReached(reportType);
  }
};

export const monthRequired = isRequiredField(month);
export const partnerRequired = isRequiredField(partnerLabel);

const getMonthlyReport = (reportCode: 'tplusone_daily', options: DailyReportParameters) => {
  const { date, partnerCode } = options;

  if (isUndefined(partnerCode)) {
    throw new Error('Partner cannot be undefined');
  }

  if (!date || !partnerCode) {
    throw new Error('Date or partner code null');
  }

  const reportParameters: ReportParameter[] = [
    { parameterName: 'partnerCode', parameterValue: [partnerCode] },
  ];

  if (date) {
    reportParameters.push({
      parameterName: 'reportDate',
      parameterValue: formatReport(date),
    });
  }

  return {
    reportCode,
    reportParameters,
  };
};

export const getReportParameters = (
  reportCode: TPlusOneReportCode,
  options: ReportParameters,
): Report => {
  switch (reportCode) {
    case 'tplusone_daily': {
      return getMonthlyReport(reportCode, options as DailyReportParameters);
    }

    default:
      return neverReached(reportCode);
  }
};

export const downloadDocument = (documentText: string, fileName: string): void => {
  if (!documentText || !fileName) return;
  const url = URL.createObjectURL(new Blob([documentText]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  URL.revokeObjectURL(url);
};

export const getDefaultPartner = (environment: string, whoAmI: WhoAmI | undefined): string => {
  if (isUndefined(whoAmI)) {
    return '';
  }

  return whoAmI.partner.envs[environment].partnerID;
};
