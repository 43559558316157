import { Box, IconButton, LoadingButton, PhoneInput, TextField } from '@drivehub/ui-components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useUnmountPromise } from 'react-use';
import { useSnackbar } from 'src/app/hooks';
import { AuthContext, useCompleteNewPassword } from 'src/app/hooks/useAuth';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import { AuthStateContext } from './AuthContext';
import { isCognitoError } from './types';

YupPassword(yup);

const {
  common: { anUnknownErrorOccurred, confirm },
  authorization: {
    phoneNumber,
    password,
    confirmPassword,
    passwordRequirements,
    changePassword,
    enterYourPassword,
    confirmYourPassword,
    addYourMobileNumber,
  },
  formValidation: {
    isARequiredField,
    minLowercaseOne,
    minUppercaseOne,
    minNumberOne,
    min16Characters,
    passwordsDontMatch,
  },
} = copyText;

const initialValues = {
  phoneNumber: '',
  password: '',
  confirm: '',
};

const validationSchema = yup.object({
  phoneNumber: yup.string().required(replaceFieldInCopyText(isARequiredField, phoneNumber)),
  password: yup
    .string()
    .password()
    .required(replaceFieldInCopyText(isARequiredField, password))
    .minLowercase(1, replaceFieldInCopyText(minLowercaseOne, password))
    .minUppercase(1, replaceFieldInCopyText(minUppercaseOne, password))
    .minNumbers(1, replaceFieldInCopyText(minNumberOne, password))
    .minSymbols(0)
    .min(16, replaceFieldInCopyText(min16Characters, password)),
  confirm: yup
    .string()
    .password()
    .required(replaceFieldInCopyText(isARequiredField, confirmPassword))
    .minLowercase(1, replaceFieldInCopyText(minLowercaseOne, confirmPassword))
    .minUppercase(1, replaceFieldInCopyText(minUppercaseOne, confirmPassword))
    .minNumbers(1, replaceFieldInCopyText(minNumberOne, confirmPassword))
    .minSymbols(0)
    .min(16, replaceFieldInCopyText(min16Characters, confirmPassword))
    .oneOf([yup.ref('password'), null], passwordsDontMatch),
});

export const ChangePassword = (): JSX.Element => {
  const completeNewPassword = useCompleteNewPassword();
  const { setAuthState } = useContext(AuthStateContext);
  const { challenge } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const mounted = useUnmountPromise();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await mounted(completeNewPassword({ user: challenge, ...values }));
        setAuthState('confirmation');
      } catch (e) {
        if (isCognitoError(e)) {
          // Unknown what can go wrong here, so we'll show a toast
          enqueueSnackbar(e.message, { variant: 'error' });
        } else {
          enqueueSnackbar(anUnknownErrorOccurred, { variant: 'error' });
        }
      }
    },
  });

  const passwordHelperText = formik.touched.password
    ? formik.errors.password
    : passwordRequirements;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" color="white">
            {enterYourPassword}
          </Typography>
          <TextField
            name="password"
            type={showPassword ? 'text' : 'password'}
            label={password}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="new-password"
            error={formik.touched.password && !!formik.errors.password}
            helperText={passwordHelperText}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="h6" color="white">
            {confirmYourPassword}
          </Typography>
          <TextField
            name="confirm"
            type={showConfirmPassword ? 'text' : 'password'}
            label={confirmPassword}
            value={formik.values.confirm}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="new-password"
            error={formik.touched.confirm && !!formik.errors.confirm}
            helperText={formik.touched.confirm && formik.errors.confirm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box my={2} sx={{ height: '1px', backgroundColor: 'colors.corePrimary5' }} />
          <Typography variant="body2">{addYourMobileNumber}</Typography>
          <PhoneInput
            defaultCountry="us"
            onChange={(val: string) => formik.setFieldValue('phoneNumber', val)}
            onBlur={formik.handleBlur}
            name="phoneNumber"
            variant="outlined"
            label={phoneNumber}
            disableAreaCodes
            margin="normal"
            autoComplete="tel"
            required
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={formik.isSubmitting}
              disabled={!formik.dirty || !formik.isValid}
            >
              {confirm}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <Helmet>
        <title>{changePassword}</title>
      </Helmet>
    </>
  );
};
