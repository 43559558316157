import { Theme } from '@drivehub/ui-components';

import { neverReached } from '../../utils';
import { Variant } from './types';

type GetListItemColorProps = {
  theme: Theme;
  isActive?: boolean;
  variant: Variant;
};

export const getListItemColor = (props: GetListItemColorProps): string => {
  const {
    theme: {
      dw: { colors },
    },
    isActive = false,
    variant,
  } = props;

  if (isActive) {
    switch (variant) {
      case 'primary':
        return colors.corePrimary3;
      case 'secondary':
        return colors.white;
      case 'tertiary':
        return colors.white;
      default:
        neverReached(variant);
    }
  }

  switch (variant) {
    case 'primary':
      return colors.corePrimary5;
    case 'secondary':
      return colors.white;
    case 'tertiary':
      return colors.corePrimary5;
    default:
      neverReached(variant);
  }

  return 'inherit';
};

type GetListItemSizeProps = {
  theme: Theme;
  variant: Variant;
};

export const getListItemSize = (props: GetListItemSizeProps): string | number | undefined => {
  const {
    theme: { typography },
    variant,
  } = props;

  switch (variant) {
    case 'primary':
      return typography.body2.fontSize;
    case 'secondary':
      return typography.body2.fontSize;
    case 'tertiary':
      return typography.subtitle1.fontSize;
    default:
      neverReached(variant);
  }

  return typography.body2.fontSize;
};

export const getLeftPadding = (variant: Variant, spacing: (num: number) => number): number => {
  switch (variant) {
    case 'primary':
      return spacing(2);
    case 'secondary':
      return spacing(2);
    case 'tertiary':
      return spacing(4);
    default:
      neverReached(variant);
  }

  return 0;
};

export const getLeftMargin = (variant: Variant): string => {
  switch (variant) {
    case 'primary':
      return '0';
    case 'secondary':
      return '40px';
    case 'tertiary':
      return '40px';
    default:
      neverReached(variant);
  }

  return '0';
};

export const getBackgroundColor = (isActive: boolean, variant: Variant, theme: Theme): string => {
  if (isActive) {
    switch (variant) {
      case 'primary':
        return theme.dw.palette.secondary.main;
      case 'secondary':
        // 30% opacity
        return `${theme.dw.colors.corePrimary4}4D`;
      case 'tertiary':
        return 'transparent';
      default:
        neverReached(variant);
    }
  }

  return 'inherit';
};
