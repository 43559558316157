import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { copyText } from 'app/internationalization/copyText';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

const {
  navbar: {
    routes: { dashboard },
  },
} = copyText;

const Container = styled.div`
  flex: 1;
  background-color: ${props => props.theme.dw.colors.corePrimaryBase};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  flex: 1;
  color: ${props => props.theme.dw.colors.white};
  font-size: 13px;
`;

const Header = styled.div`
  font-size: 16px;
  margin-bottom: 0.5em;
  color: ${props => props.theme.dw.colors.white};
  font-weight: regular;
`;

const OriginalLandingPage = () => (
  <Container>
    <Helmet>
      <title>{dashboard}</title>
    </Helmet>
    <Content data-test="dashboard-welcome-message">
      <SearchOutlinedIcon sx={{ height: 48, width: 48, mr: 2 }} />
      <Text>
        <Header>Welcome to DriveHub</Header>
        <p>To get started, use the search box above to find clients, orders, accounts, and more.</p>
      </Text>
    </Content>
  </Container>
);

export default OriginalLandingPage;
