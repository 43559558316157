import { ChangeEvent, KeyboardEvent, useCallback, useRef, useState } from 'react';
import useMousetrap from 'react-hook-mousetrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { beginDebouncedSearch } from 'reducers/search/actions';

import { getPathToNavigateTo } from './utils';

const getSearchQuery = (state: { search: { preliminaryQuery: string } }) =>
  state.search.preliminaryQuery;
const getSearchResults = (state: {
  search: {
    results: {
      ACCOUNT?: Array<{
        path: string;
      }>;
    };
  };
}) => state.search.results;

export const useNavSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const query = useSelector(getSearchQuery);
  const searchResults = useSelector(getSearchResults);
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const handleOpen = useCallback(() => {
    inputRef.current?.focus();
    setIsOpen(true);
  }, []);

  // This method closes the suggestion box and leaves the search bar open and unfocused
  const handleClose = useCallback(() => {
    inputRef.current?.blur();
    setIsOpen(false);
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsOpen(true);
      dispatch(beginDebouncedSearch(event.currentTarget.value));
    },
    [dispatch],
  );

  const unfocusSeachBar = useCallback(() => {
    inputRef.current?.blur();
    setIsOpen(false);
  }, []);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        unfocusSeachBar();
        inputRef.current?.blur();
      }

      if (event.key === 'Enter') {
        const accountUndefined =
          searchResults?.ACCOUNT && searchResults.ACCOUNT[0].path.includes('undefined');
        const userUndefined = Object.keys(searchResults)?.length === 1 && accountUndefined;

        if (Object.keys(searchResults).length > 1 && accountUndefined) {
          delete searchResults.ACCOUNT;
        }
        // Get the result
        const newPath = getPathToNavigateTo(searchResults);

        if (newPath && !userUndefined) {
          history.push(newPath);
          handleClose();
        }
      }
    },
    [unfocusSeachBar, searchResults, history, handleClose],
  );

  useMousetrap('/', handleOpen, 'keyup');
  useMousetrap('esc', unfocusSeachBar);

  return {
    handleKeyUp,
    handleChange,
    handleClose,
    handleOpen,
    query,
    inputRef,
    isOpen,
    setIsOpen,
  };
};
