import MoneyMovement from 'services/modules/MoneyMovement';

const REGEX_PAYMENT = /^[[A-Za-z\d]{10}-[A-Za-z\d]{13}-[A-Za-z\d]{5}$/;

const REGEX_CHECK = new RegExp(REGEX_PAYMENT);

export default {
  id: 'PAYMENT',
  label: 'Payments',
  applicableQueries: REGEX_CHECK,
  maxSuggestions: 1,
  fetchResults: (query, _) => {
    return Promise.all([
      MoneyMovement.getPaymentHistoryDeposit(query).catch(() => 'error'),
      MoneyMovement.getPaymentHistoryWithdrawal(query).catch(() => 'error'),
    ]).then(values => {
      const [depositResults, withdrawalResults] = values;
      if (depositResults === withdrawalResults) {
        // if both error out, return an empty array for search to know there were no results
        return [];
      } else {
        // otherwise, get the result that is not the error and return it.
        const data = depositResults === 'error' ? withdrawalResults : depositResults;
        return [
          {
            path: `/reporting/payment-audit/${query}`,
            primary: data.id,
            secondary: 'payment audit',
          },
        ];
      }
    });
  },
};
