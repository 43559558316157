//

import userFetcher from './sharedFetchers/user';

export default {
  id: 'ADVISOR',
  label: 'Advisors',
  maxSuggestions: 3,
  fetchResults: userFetcher,
  transformFetchedResults: results =>
    results
      .filter(user => user.userType.name === 'TRADING_ADVISOR')
      .map(user => ({
        path: `/users/${user.id || user.userID}`,
        primary: user.company || `${user.firstName} ${user.lastName}`,
      })),
};
