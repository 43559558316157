const REGEX_ACCOUNT_NUMBER = /^([A-Za-z\d]{4})(\d{1,6})$/;

/**
 * Takes in a partial account number like KRDA1 and
 * fills in the missing zeros to produce: KRDA000001
 * @param {string} accountNo
 */
export function getCompleteAccountNo(accountNo) {
  const matches = accountNo.match(REGEX_ACCOUNT_NUMBER);
  return matches && accountNo.slice(-1) !== '0'
    ? `${matches[1]}${matches[2].padStart(6, '0')}`
    : accountNo;
}
