import { AllViewBatchPermissions } from 'src/app/constants';

/*
  displayName - batch name to be used for labels etc.
  permission - permissions needed to create this batch type
  csvInputs - has all the columns used for the Preview and the Details
  csvInputs.COLUMNNAME - the column name MUST be all lowercase
  csvInputs.COLUMNNAME.label - label for the column
  csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
  csvInputs.COLUMNNAME.canSort - should this column be sortable
  csvInputs.COLUMNNAME.required - block upload if this column is missing
  csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
  csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
  groupPreviewBy - either a empty string or the name of the column by which to group all the rows
  exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const promotionShareTransfer = {
  displayName: 'Promotion Share Transfer',
  permission: AllViewBatchPermissions.PROMOTION_SHARE_TRANSFER_CREATE_BATCH_VIEW,
  overrides: {
    dnb: false,
  },
  checks: {
    timeCheck: {
      permissionBypass: AllViewBatchPermissions.PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_VIEW,
      tz: 'America/New_York',
      start: {
        hours: 8,
        minutes: 0,
      },
      end: {
        hours: 17,
        minutes: 0,
      },
    },
    columns: {
      accountFrom: {
        accountType: {
          value: 'SHARE_PROMOTION_ACCOUNT',
          permissionBypass:
            AllViewBatchPermissions.PROMOTION_SHARE_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW,
        },
      },
      accountTo: {
        accountStatus: {
          value: 'OPEN',
        },
      },
      quantity: {
        positiveOnly: true,
        hasTotalShares: true,
      },
    },
  },
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountfrom: {
      label: 'Account From',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountFrom',
    },
    accountto: {
      label: 'Account To',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountTo',
    },
    symbol: {
      label: 'Symbol',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'symbol',
    },
    averageprice: {
      label: 'Average Price',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'price',
      serverKey: 'averagePrice',
    },
    quantity: {
      label: 'Quantity',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'number',
      serverKey: 'quantity',
    },
    transferprice: {
      label: 'Transfer Price',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'price',
      serverKey: 'transferPrice',
    },
    executionstrategy: {
      label: 'Accounting Method',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'executionStrategy',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'comment',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountFrom: 'QWERTYY12345',
      accountTo: 'BIRDY12345',
      symbol: 'AAPL',
      averagePrice: 100,
      quantity: 10,
      transferPrice: 50,
      executionStrategy: 'LIFO',
      comment: 'Some comment',
    },
  ],
};

export { promotionShareTransfer };
