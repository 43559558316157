import generateReducer from 'modules/generateReducer';

export const initialState = {
  isOpen: true,
  orders: null,
};

const handlers = {
  CV_GET_BUY_ORDER_START: (state, action) => initialState,

  CV_GET_BUY_ORDER_SUCCESS: (state, action) => ({
    ...state,
    orders: action.payload.orders,
  }),

  CV_GET_SELL_ORDER_SUCCESS: (state, action) => ({
    ...state,
    orders: [...(state.orders || []), ...action.payload.orders],
  }),
  CV_GET_SELL_ORDER_START: null,
  CV_GET_SELL_ORDER_ERROR: null,

  CV_OPEN: (state, action) => ({
    ...state,
    orders: null,
    isOpen: true,
  }),

  CV_CREATE_GFV_SUCCESS: null,
  CV_CREATE_GFV_START: null,
  CV_CREATE_GFV_ERROR: null,
  CV_ERROR: null,
  CV_GET_BUY_ORDER_ERROR: null,
};

export const createViolationReducer = generateReducer(initialState, handlers);
