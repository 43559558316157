import generateReducer from 'modules/generateReducer';

export const initialState = {
  preliminaryQuery: '',
  pendingQuery: null,
  searchedQuery: null,
  results: {},
  resultsCount: 0,
};

const handlers = {
  SEARCH_SET_QUERY_PRELIMINARY: (state, action) => ({
    ...state,
    preliminaryQuery: action.payload.query,
  }),
  SEARCH_FETCH_START: (state, action) => ({
    ...state,
    pendingQuery: action.payload.query,
  }),
  SEARCH_FETCH_SUCCESS: (state, action) =>
    action.payload.query !== state.pendingQuery
      ? state
      : {
          ...state,
          searchedQuery: action.payload.query,
          results: Object.assign(
            {},
            ...Object.keys(action.payload.results)
              .filter(key => action.payload.results[key].length)
              .map(key => ({ [key]: action.payload.results[key] })),
          ),
          resultsCount: Object.values(action.payload.results)
            // $FlowFixMe flow does not know about Object.values properly yet
            .map(arr => arr.length)
            .reduce((acc, n) => acc + n, 0),
        },
  SEARCH_RESET: (state, action) => initialState,
};

export default generateReducer(initialState, handlers);
