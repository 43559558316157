import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Holiday } from 'app/types';
import { fetch } from 'src/app/services';

const endpoint = new URL('/api/calendars', window.location.origin).href;

export const useCalendar = (): UseQueryResult<Holiday[]> => {
  return useQuery<Holiday[]>({
    queryKey: ['calendar'],
    queryFn: async () => {
      const data = await fetch(endpoint);

      return data.json() as Promise<Holiday[]>;
    },
  });
};
