import { endOfDay, startOfDay } from 'date-fns';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  accountID: '',
  customRange: false,
  startDate: startOfDay(new Date()),
  endDate: endOfDay(Date.now()),
  results: { transactions: [] },
  typeFilter: [],
  symbolFilter: '',
  amountFilter: 0,
  amountFilterString: '',
  amountFilterType: 'equalTo',
  rangeStart: 0,
  rangeStartString: '',
  nextID: '',
  prevID: '',
  hasNext: true,
  hasPrev: false,
  pageNumber: 1,
  pendingTransactions: [],
  isDownloading: false,
};

const handlers = {
  TRANSACTIONS_TOGGLE_CUSTOM_RANGE: (state, action) => ({
    ...state,
    customRange: !state.customRange,
  }),

  TRANSACTIONS_SET_DATE: (state, action) => ({
    ...state,
    startDate: action.payload.startDate || state.startDate,
    endDate: action.payload.endDate || state.endDate,
  }),

  TRANSACTIONS_GET_START: (state, action) => ({ ...state, accountID: action.payload.accountID }),

  TRANSACTIONS_GET_SUCCESS: (state, action) => ({
    ...state,
    results: action.payload.results,
    prevID: action.payload.prevID,
    nextID: action.payload.nextID,
    hasNext: action.payload.hasNext,
    hasPrev: action.payload.pageNumber > 1,
    pageNumber: action.payload.pageNumber,
  }),

  TRANSACTIONS_GET_ERROR: (state, action) => ({ ...state, results: { transactions: [] } }),

  TRANSACTIONS_UPDATE_FILTER: (state, action) => ({
    ...state,
    typeFilter: action.payload.value,
  }),

  TRANSACTIONS_UPDATE_FILTER_SYMBOL: (state, action) => ({
    ...state,
    symbolFilter: action.payload.symbol,
  }),

  TRANSACTIONS_UPDATE_FILTER_AMOUNT: (state, action) => ({
    ...state,
    amountFilter: action.payload.amount,
    amountFilterString: action.payload.string,
  }),

  TRANSACTIONS_UPDATE_FILTER_AMOUNT_TYPE: (state, action) => ({
    ...state,
    amountFilterType: action.payload.amountType,
  }),

  TRANSACTIONS_UPDATE_FILTER_RANGE: (state, action) => ({
    ...state,
    rangeStart: action.payload.rangeStart,
  }),

  TRANSACTIONS_DOWNLOADING: (state, action) => ({
    ...state,
    isDownloading: action.payload.isDownloading,
  }),
};

export const userTransactionsReducer = generateReducer(initialState, handlers);
