import { isUndefined } from 'lodash';

type SearchResult = { path: string };
type SearchResults = Record<string, SearchResult[]>;

/**
 * Gets the first category with a defined path or undefined if no results.
 * @param searchResults A Record<string, SearchResult[]> holding the search results
 * @returns the first defined path
 */
export const getPathToNavigateTo = (searchResults: SearchResults): string | undefined => {
  const paths = Object.values(searchResults)
    .map(results => results?.[0].path)
    .filter(path => !isUndefined(path));

  return paths[0];
};
