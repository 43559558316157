import { useState } from 'react';
import { useTimeoutFn } from 'react-use';

import { useUser } from './useAuth';
import { useGetAcknowledgments } from './useGetAcknowledgments';
import { useGetDisclosures } from './useGetDisclosures';
import { useWhoAmI } from './useWhoAmI';

// This hook tracks initial loading state of the app
export const useStartUpLoader = () => {
  const { data: whoAmIData } = useWhoAmI();
  const userID = whoAmIData?.employee.id;
  const { isLoading: isAcknowledgmentsLoading } = useGetAcknowledgments(userID);

  const { isLoading: isDisclosureLoading } = useGetDisclosures();

  const areAcknowledgmentsLoading = isAcknowledgmentsLoading || isDisclosureLoading;

  const [isGreaterThanOneSecond, setIsGreaterThanOneSecond] = useState(false);

  // These two pieces constitute the core of our auth, Cognito and WhoAmI
  const user = useUser();
  const isAuthLoaded = user && whoAmIData;

  useTimeoutFn(() => {
    setIsGreaterThanOneSecond(true);
  }, 1000);

  return (areAcknowledgmentsLoading || !isAuthLoaded) && isGreaterThanOneSecond;
};
