//
import generateReducer from 'modules/generateReducer';

export const initialState = {
  callList: [],
  summaries: {},
  modalOpen: false,
};

const handlers = {
  REPORTING_MARGIN_CALL_GET_SUCCESS: (state, action) => ({
    ...state,
    callList: action.payload.list,
    summaries: action.payload.summaries,
  }),
  REPORTING_MARGIN_CALL_GET_START: null,
  REPORTING_MARGIN_CALL_GET_ERROR: null,
  REPORTING_MARGIN_CALL_UPDATE_SUMMARY_SUCCESS: (state, action) => ({
    ...state,
    summaries: {
      ...state.summaries,
      [action.payload.accountID]: action.payload.summary,
    },
  }),
  REPORTING_MARGIN_CALL_UPDATE_SUMMARY_ERROR: null,
  REPORTING_MARGIN_CALL_SHOW_MODAL: (state, action) => ({
    ...state,
    modalOpen: true,
  }),
  REPORTING_MARGIN_CALL_HIDE_MODAL: (state, action) => ({
    ...state,
    modalOpen: false,
  }),
};

export const marginCallReducer = generateReducer(initialState, handlers);
