const generateReducer =
  (initialState, handlers, persistAfterDatasrcChange = false) =>
  (state = initialState, action) => {
    const envUpdated =
      action.type === 'SETTING_UPDATED' &&
      action.payload &&
      action.payload.key === 'ENV' &&
      // Check if we are going from an existing env, otherwise this is start up and other requests may come in ahead of this
      state.settings?.ENV;

    // if the env is updated, and the state contains uniquePartnersByID reset the uniquePartnersByID regardless of persistAfterDatasrcChange value
    if (envUpdated && state.uniquePartnersByID) {
      return { ...initialState, uniquePartnersByID: {} };
    }
    // if the env is updated, and persistAfterDatasrcChange is false, return the initialState
    if (envUpdated && !persistAfterDatasrcChange) {
      return initialState;
    }
    // if this is not a env update and the other conditions are not met, use our standard handler
    return Object.prototype.hasOwnProperty.call(handlers, action.type) &&
      typeof handlers[action.type] === 'function'
      ? handlers[action.type](state, action)
      : state;
  };

export default generateReducer;
