import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useSnackbar } from 'src/app/hooks';
import { fetch } from 'src/app/services';

import { copyText } from '../internationalization';

const {
  transactions: {
    fintrans: {
      doNotReconcile: { successMessage, errorMessage },
    },
  },
} = copyText;

export type UseFintranDNROptions = {
  accountID: string;
  finTranIDs: [string];
};

const endpoint = new URL('/api/accounts', window.location.origin).href;

export const useFintranDNR = (
  closeModal: () => void,
): UseMutationResult<unknown, Error, UseFintranDNROptions> => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<unknown, Error, UseFintranDNROptions>({
    mutationFn: async ({ accountID, finTranIDs }) => {
      const data = await fetch(`${endpoint}/${accountID}/transactions`, {
        method: 'PATCH',
        body: JSON.stringify({
          finTranIDs,
          doNotReconcile: true,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });
      const result = await data.json();
      return result;
    },

    onError: error => {
      const errorText = error.message && error.message.length > 0 ? error.message : errorMessage;
      enqueueSnackbar(errorText, { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar(successMessage, { variant: 'success' });
    },
    onSettled: () => {
      closeModal();
    },
  });
};
