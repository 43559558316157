import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { pure } from 'recompose';

const CONTEXT_SETTINGS = [
  ['amountDisplayCurrency', PropTypes.string],
  ['amountHideCurrencySymbol', PropTypes.bool],
];

class AmountProvider extends React.Component {
  static childContextTypes = CONTEXT_SETTINGS.reduce(
    (acc, next) => ({
      ...acc,
      [next[0]]: next[1],
    }),
    {
      currencyExchangeRates: PropTypes.object,
    },
  );

  getChildContext = () =>
    CONTEXT_SETTINGS.reduce(
      (acc, next) => ({
        ...acc,
        [next[0]]: this.props[next[0]],
      }),
      {
        currencyExchangeRates: {},
      },
    );

  render() {
    return this.props.children;
  }
}

export default connect(state =>
  CONTEXT_SETTINGS.reduce(
    (acc, next) => ({
      ...acc,
      [next[0]]: state.settings[next[0]],
    }),
    {},
  ),
)(pure(AmountProvider));
