import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { IconButton } from '@mui/material';
import { createElement, FC } from 'react';
import styled from 'styled-components';

import { Environment, useEnvironment, usePermission } from '../../hooks';
import { NavListItem } from './NavListItem';
import { NavbarListItemIcon } from './NavListItemIcon';
import { NavListItemText } from './NavListItemText';
import { NavProps, Variant } from './types';
import { getListItemColor } from './utils';

const NavbarExternalIcon = styled(ExitToAppOutlinedIcon)<{ variant: Variant }>`
  color: ${getListItemColor};
  height: 16px;
  width: 16px;
`;

export const ExternalNavItem: FC<
  NavProps & { variant: Variant; path: keyof Environment }
> = props => {
  const {
    environments,
    icon,
    path, // Path is a key from envs for external links to control on a per environment basis
    permissions = [],
    text,
    variant,
  } = props;

  const hasPermission = usePermission(permissions);
  const env = useEnvironment();
  const link = env?.[path] as string;

  const isEnvironmentAllowed = environments.includes(env.value);

  if (!hasPermission || !isEnvironmentAllowed) {
    return null;
  }

  const IconComponent = icon ? createElement(icon) : null;

  return (
    <NavListItem component="a" href={link} target="_blank" variant={variant}>
      <NavbarListItemIcon variant={variant} title={text}>
        {IconComponent}
      </NavbarListItemIcon>
      <NavListItemText primary={text} variant={variant} />
      <IconButton edge="end" aria-label="external" size="large">
        <NavbarExternalIcon variant={variant} />
      </IconButton>
    </NavListItem>
  );
};
