export const reduceInstrumentsBySymbol = instruments => {
  const instrumentsBySymbol = {};
  for (const instrument of instruments) {
    instrumentsBySymbol[instrument.symbol] = instrument;
  }
  return instrumentsBySymbol;
};

export const reduceInstrumentsByID = instruments => {
  const instrumentsByID = {};
  for (const instrument of instruments) {
    instrumentsByID[instrument.id] = instrument;
  }
  return instrumentsByID;
};
