import { RefObject, useRef } from 'react';

import { useToggle } from './useToggle';

type UseMenuReturnType<T> = {
  anchorEl: RefObject<T>;
  isMenuOpen: boolean;
  toggleMenuOpen: () => void;
};

export function useMenu<T>(): UseMenuReturnType<T> {
  const [isMenuOpen, toggleMenuOpen] = useToggle();
  const anchorEl = useRef<T>(null);

  return {
    anchorEl,
    isMenuOpen,
    toggleMenuOpen,
  };
}
