/*
	displayName - batch name to be used for labels etc.
	permission - permissions needed to create this batch type
	csvInputs - has all the columns used for the Preview and the Details
	csvInputs.COLUMNNAME - the column name MUST be all lowercase
	csvInputs.COLUMNNAME.label - label for the column
	csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
	csvInputs.COLUMNNAME.canSort - should this column be sortable
	csvInputs.COLUMNNAME.required - block upload if this column is missing
	csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
	csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
	groupPreviewBy - either a empty string or the name of the column by which to group all the rows
	exampleData - array of objects to populate the example data table (Create Batch screen)
*/
const deposit = {
  displayName: 'Deposit',
  permission: 'DEPOSITS_CREATE_BATCH_VIEW',
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    paymentstatus: {
      label: 'Payment Status',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'paymentStatus',
    },
    accountno: {
      label: 'Account No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountNo',
    },
    accountid: {
      label: 'Account ID',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'accountID',
    },
    amount: {
      label: 'Amount',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'money',
      serverKey: 'amount',
    },
    currency: {
      label: 'Currency',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'currency',
    },
    type: {
      label: 'Transaction Type',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'type',
    },
    bankaccountid: {
      label: 'Bank Account ID',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'bankAccountID',
    },
    transactioncode: {
      label: 'Transaction Code',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'transactionCode',
    },
    note: {
      label: 'Note',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'note',
    },
    details: {
      label: 'Details',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'details',
    },
  },
  groupPreviewBy: '',
  doAccumalatingBalance: false,
  exampleData: [
    {
      accountNo: 'DWFD000000',
      accountID: 'QWERT12345',
      amount: 20.11,
      currency: 'USD',
      type: 'ACH',
      bankAccountID: '6276848a-0d04-404c-aabe-ac1b866e8827',
      transactionCode: 'RECEIPT_CHECK',
      note: 'A note on a user',
      details: 'Details about the deposit',
    },
  ],
};

export { deposit };
