import { Backdrop, Box, InputBase } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

import SearchSuggestions from '../../../legacy/routes/Interior/NavSearch/SearchSuggestions';
import { copyText } from '../../internationalization';
import { useNavSearch } from './hooks/useNavSearch';

const SearchBarContainer = styled('div')<{
  query: string;
}>`
  flex-grow: 1;
  max-width: ${props => props.theme.spacing(46)};
  z-index: 2;
  height: 32px;
  position: relative;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border-bottom-left-radius: ${props =>
    !props.query.length
      ? props.theme.shape.borderRadius
      : '0'}px; // Make this flush with the suggestions menu
  border-bottom-right-radius: ${props =>
    !props.query.length
      ? props.theme.shape.borderRadius
      : '0'}px; // Make this flush with the suggestions menu
  background-color: ${props => props.theme.dw.colors.corePrimary2};
  transition: ${props =>
    props.theme.transitions.create(['all'], {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })};

  input {
    height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${props => props.theme.spacing(1)};
    padding-right: ${props => props.theme.spacing(2)};
    opacity: 1;
    transition: ${props =>
      props.theme.transitions.create(['opacity', 'width', 'padding'], {
        easing: props.theme.transitions.easing.sharp,
        duration: props.theme.transitions.duration.enteringScreen,
      })};
  }

  .MuiInputBase-root {
    height: 32px;
    color: inherit;
    width: 100%;
    cursor: 'inherit';
  }
`;

const SearchBarBackdrop = styled(Backdrop)`
  &&& {
    z-index: 1;
    margin-right: 0; // Inheriting the navbar item spacing by default
  }
`;

export const NavSearch: FC = () => {
  const { query, inputRef, handleChange, handleKeyUp, handleClose, isOpen, setIsOpen } =
    useNavSearch();

  const {
    common: { search: searchPlaceholder },
  } = copyText;

  const onSearchBarClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SearchBarContainer query={query}>
        <InputBase
          value={query}
          placeholder={searchPlaceholder}
          inputProps={{ 'aria-label': 'search', maxLength: '80' }}
          inputRef={inputRef}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onClick={onSearchBarClick}
          autoFocus
          data-testid="nav-searchbar"
        />
        {isOpen && (
          <Box onClick={handleClose}>
            <SearchSuggestions isActive />
          </Box>
        )}
      </SearchBarContainer>
      <SearchBarBackdrop open={isOpen} onClick={handleClose} />
    </>
  );
};
