import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';
import { CancelRebillReturnValue } from 'src/app/types';

type UseCancelRebillOrderVariables = {
  orderNo?: string;
  type: string;
  reason: string;
  approvedBy?: string;
  comment?: string;
  adjustedPrice?: number;
  profitAndLoss?: number;
  adjustedCommission?: number;
  errorAccountNo?: string;
};

const endpoint = new URL('/api/asset-transfers/cancel-rebills', window.location.origin).href;

export const useCancelRebillRequest = (): UseMutationResult<
  CancelRebillReturnValue,
  { errorCode: number; message: string },
  UseCancelRebillOrderVariables
> => {
  return useMutation<
    CancelRebillReturnValue,
    { errorCode: number; message: string },
    UseCancelRebillOrderVariables
  >({
    mutationFn: async variables => {
      const data = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(variables),
        headers: {
          'Content-type': 'application/json',
        },
      });

      return data.json() as Promise<CancelRebillReturnValue>;
    },
  });
};
