import generateReducer from 'modules/generateReducer';

export const initialState = {
  docWorkflowEnabledLanguages: [],
  principalWorkflowEnabledLanguages: [],
  usersNavUser: '/',
  usersNavAccount: '/',
  usersNavUserImmediate: false,
  usersNavAccountImmediate: false,
  amountDisplayCurrency: 'USD',
  amountHideCurrencySymbol: false,
  amountDecimalSeperator: '.',
  amountThousandSeperator: ',',
  hidePositionSetWarning: false,
  showPositionsData: {
    COST_BASIS: true,
    QUANTITY: true,
    PRICE: true,
    VALUE: true,
    PL: true,
    MARGIN: true,
  },
  ENV: null,
};

const handlers = {
  SETTING_UPDATED: (state, action) => ({ ...state, [action.payload.key]: action.payload.value }),
  SETTINGS_UPDATED: (state, action) => ({ ...state, ...action.payload.settings }),
};

export default generateReducer(initialState, handlers, true);
