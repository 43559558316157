import { isUndefined } from 'lodash';
import ReactGA, { EventArgs } from 'react-ga';

/** *** Internal *** */
const editOutPII = (path: string) => {
  const segments = path.split('/');

  const edittedPII = segments
    .map(segment => {
      if (segment.length && segment.length < 16) {
        return segment;
      }

      return undefined;
    })
    .filter(segment => !isUndefined(segment))
    .join('/');

  return edittedPII.length === 0 ? '/' : edittedPII;
};

const parsePageTitle = () => {
  const pageTitle = document.title || 'DriveHub by DW';
  return pageTitle.split('|')[0].trim();
};

/** *** External  ***  */
export const init = (trackingId: string): void => {
  ReactGA.initialize(trackingId, {
    titleCase: false,
  });
};

export const sendEvent = (payload: EventArgs): void => {
  ReactGA.event(payload);
};

type SendPageViewOptions = {
  path: string;
  partnerName: string;
  roleID: string;
};

export const sendPageView = ({ path, partnerName, roleID }: SendPageViewOptions): void => {
  const location = `${window.location.protocol}//${window.location.host}`;
  const title = parsePageTitle();

  ReactGA.set({ location, dimension1: partnerName, dimension2: roleID, title });
  ReactGA.pageview(editOutPII(path));
};

export const updateUserId = (userId: string): void => {
  ReactGA.set({ userId });
};
