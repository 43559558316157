import { findEnvVar } from 'app/utils';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';

import { useGetEnvironmentVariables } from './useGetEnvironmentVariables';

export type Environments = 'TEST' | 'LIVE';

export interface Environment {
  DEFAULT: boolean;
  DESCRIPTION: string;
  LABEL: string;
  GA?: string;
  value: Environments;
}

export const isEnvironmentKey = (arg: unknown): arg is keyof Environment => {
  if (isString(arg)) {
    return ['DEFAULT', 'DESCRIPTION', 'LABEL', 'value'].includes(arg);
  }

  return false;
};

const environments: Record<Environments, Environment> = {
  LIVE: {},
  TEST: {},
} as Record<Environments, Environment>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentEnv = (state: any): Environments => state.settings.ENV;

// TODO: This can be enhanced and put into a context to replace the Redux implementation
export const useEnvironment = (): Environment => {
  const env = useSelector(getCurrentEnv) ?? 'LIVE';

  return environments[env];
};

export const useEnvironments = (): Record<Environments, Environment> => {
  const { data } = useGetEnvironmentVariables();
  environments.LIVE.DEFAULT = findEnvVar(data, 'REACT_APP_DATASRC_LIVE_DEFAULT')?.value as boolean;
  environments.LIVE.LABEL = findEnvVar(data, 'REACT_APP_DATASRC_LIVE_LABEL')?.value as string;
  environments.LIVE.DESCRIPTION = findEnvVar(data, 'REACT_APP_DATASRC_LIVE_DESCRIPTION')
    ?.value as string;
  environments.LIVE.GA = findEnvVar(data, 'REACT_APP_DATASRC_LIVE_GA')?.value as string;
  environments.LIVE.value = 'LIVE';

  environments.TEST.DEFAULT = findEnvVar(data, 'REACT_APP_DATASRC_TEST_DEFAULT')?.value as boolean;
  environments.TEST.LABEL = findEnvVar(data, 'REACT_APP_DATASRC_TEST_LABEL')?.value as string;
  environments.TEST.DESCRIPTION = findEnvVar(data, 'REACT_APP_DATASRC_TEST_DESCRIPTION')
    ?.value as string;
  environments.TEST.GA = findEnvVar(data, 'REACT_APP_DATASRC_TEST_GA')?.value as string;
  environments.TEST.value = 'TEST';
  return environments;
};
