import { usePermission } from 'src/app/hooks';

import { Permission } from '../../constants/permissions';

export type IfPermissionedProps = {
  permissions: Permission | Permission[];
  children: React.ReactNode;
};

export const IfPermissioned = ({
  permissions,
  children,
}: IfPermissionedProps): JSX.Element | null => {
  const isPermissioned = usePermission(permissions);

  if (!isPermissioned) {
    return null;
  }

  return <>{children}</>;
};
