//
import loading from 'reducers/loading';
import { combineReducers } from 'redux';

import errors from './error';
import messages from './statusMessage';

export default combineReducers({
  errors,
  loading,
  messages,
});
