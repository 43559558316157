import { ListItemText } from '@mui/material';
import styled from 'styled-components';

import { Variant } from './types';
import { getListItemSize } from './utils';

export interface NavItemListItemTextProps {
  variant: Variant;
}

export const NavListItemText = styled(ListItemText)<NavItemListItemTextProps>`
  & .MuiListItemText-primary {
    font-size: ${getListItemSize}px;
    text-overflow: clip;
    overflow-x: hidden;
    white-space: nowrap;
  }
`;
