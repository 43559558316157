import { Box, Button, LoadingButton, TextField } from '@drivehub/ui-components';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useUnmountPromise } from 'react-use';
import { useSnackbar } from 'src/app/hooks';
import { useForgotPassword } from 'src/app/hooks/useAuth';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';
import * as yup from 'yup';

import { AuthStateContext } from './AuthContext';
import { isCognitoError } from './types';

const {
  authorization: { username, userNotFound, resetYourPassword, backToSignIn, sendCode },
  common: { anUnknownErrorOccurred },
  formValidation: { isARequiredField },
} = copyText;

const initialValues = {
  username: '',
};

const validationSchema = yup.object({
  username: yup.string().required(replaceFieldInCopyText(isARequiredField, username)),
});

export const ForgotPassword = (): JSX.Element => {
  const forgotPassword = useForgotPassword();
  const { setAuthState, setUsername } = useContext(AuthStateContext);
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const mounted = useUnmountPromise();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await mounted(forgotPassword(values));
        setAuthState('resetPassword');
        setUsername(values.username);
      } catch (e) {
        if (isCognitoError(e)) {
          if (e.code === 'UserNotFoundException') {
            formik.setFieldError('username', userNotFound);
          } else {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(anUnknownErrorOccurred, { variant: 'error' });
        }
      }
    },
  });

  const goToLogin = () => {
    setAuthState('login');
  };

  return (
    <>
      <Typography variant="h5">{resetYourPassword}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="username"
            error={formik.touched.username && !!formik.errors.username}
            helperText={formik.touched.username && formik.errors.username}
            autoFocus
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="tertiary" onClick={goToLogin}>
              {backToSignIn}
            </Button>
            <LoadingButton
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
              color="primary"
              loading={formik.isSubmitting}
              disabled={!formik.dirty || !formik.isValid}
            >
              {sendCode}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <Helmet>
        <title>{resetYourPassword}</title>
      </Helmet>
    </>
  );
};
