import { isAfter } from 'date-fns';

export const shapeActions = data => {
  const ids = data.map(action => action.eventID);
  const byID = data.reduce((acc, current) => {
    acc[current.eventID] = current;
    return acc;
  }, {});
  return {
    ids,
    byID,
  };
};

export const upcomingSymbols = data => {
  const today = new Date();
  const upcomingArray = data.filter(event => isAfter(new Date(event.date), startOfDay(new Date())));
  const upcoming = upcomingArray.reduce((acc, current) => {
    const currentSymbol = acc[current.symbol];
    if (currentSymbol) {
      acc[current.symbol].push(current.eventID);
    } else {
      acc[current.symbol] = [current.eventID];
    }
    return acc;
  }, {});
  return upcoming;
};
