import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EnvironmentVariable } from 'app/types';

export const useGetEnvironmentVariables = <T = EnvironmentVariable[]>(
  options?: UseQueryOptions<T>,
): UseQueryResult<T> => {
  return useQuery<T>({
    queryKey: ['environments'],
    queryFn: async () => {
      const endpoint = new URL(`/api/environments`, window.location.origin).href;

      const res = await fetch(endpoint);

      const environments = await res.json();
      return environments;
    },
    ...options,
  });
};
