const initialState = {
  wlpFinTransTypes: {},
  isFrom: true,
  showCreateBeneficiaryModal: false,
  showDepositCreateModal: false,
  showCreateContingentModal: false,
  accountMgmtType: {},
};

export function accountReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'TRANSFER_TOGGLE':
      return { ...state, isFrom: payload.isFrom };

    case 'TOGGLE_CREATE_BENEFICIARIES_MODAL':
      return {
        ...state,
        showCreateBeneficiaryModal: !state.showCreateBeneficiaryModal,
        editingBeneficiaries: payload.editingBeneficiaries || false,
        existingBeneficiaries: payload.existingBeneficiaries || [],
      };

    case 'TOGGLE_DEPOSIT_CREATE_MODAL':
      return {
        ...state,
        showDepositCreateModal: !state.showDepositCreateModal,
        accountMgmtType: payload,
      };

    case 'TOGGLE_CREATE_CONTINGENT_MODAL':
      return {
        ...state,
        showCreateContingentModal: !state.showCreateContingentModal,
        editingContingentBeneficiaries: payload.editingContingentBeneficiaries || false,
        existingContingentBeneficiaries: payload.existingContingentBeneficiaries || [],
      };

    default:
      return state;
  }
}
