import { EnvironmentVariable } from 'app/types';

import { findItemInArray } from './arrays';

export const findEnvVar = (
  envVarData: Array<EnvironmentVariable> | undefined,
  envVarTarget: EnvironmentVariable['environmentID'],
) => {
  return envVarData && findItemInArray(envVarData, 'environmentID', envVarTarget)
    ? findItemInArray(envVarData, 'environmentID', envVarTarget)
    : undefined;
};

export const findDeployedEnvironment = (url: string) => {
  if (url === 'dev1-dashboard.drivewealth.tech') return 'Master';
  if (url === 'beta-dashboard.drivewealth.com') return 'UAT';
  if (url === 'dashboard.drivewealth.com') return 'Production';
  if (url === 'qa-master-dashboard.drivewealth.tech') return 'QA';
  return 'local';
};
