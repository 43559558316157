import generateReducer from 'modules/generateReducer';

export const initialState = {
  violations: [],
  isDescending: true,
  sortingBy: 'accountNo',
};

const handlers = {
  ACCOUNT_VIOLATIONS_GET_SUCCESS: (state, action) => ({
    ...state,
    violations: action.payload.report,
  }),
  ACCOUNT_VIOLATIONS_GET_ERROR: null,
  ACCOUNT_VIOLATIONS_GET_START: null,

  ACCOUNT_VIOLATIONS_CHANGE_SORT_BY: (state, action) => ({
    ...state,
    isDescending: state.sortingBy === action.payload.key ? !state.isDescending : state.isDescending,
    sortingBy: action.payload.key,
  }),
};

export const accountViolationsReducer = generateReducer(initialState, handlers);
