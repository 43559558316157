import { useEnvironment, useGetAcknowledgments, useGetDisclosures, useWhoAmI } from 'app/hooks';
import { Acknowledgment } from 'app/types';
import { APITermsOfUseModal } from './APITermsOfUseModal';
import { getActiveDisclosures } from './utils';

export const ApiTermsOfUse = (): JSX.Element => {
  const environment = useEnvironment();

  const { data: whoAmIData } = useWhoAmI();
  const userID = whoAmIData?.employee.id;
  const { data: acknowledgmentsData, isSuccess: isAckDataSuccess } = useGetAcknowledgments(userID);

  // Filter down the disclosures to get the proper DH type for this view
  const { data: activeDisclosure, isSuccess: isDisclosuresSuccess } = useGetDisclosures({
    select: getActiveDisclosures,
  });

  const hasUnsignedAcknowledgment = acknowledgmentsData?.data.some(
    (el: Acknowledgment) =>
      el.version === activeDisclosure?.version &&
      el.disclosureID === activeDisclosure?.disclosureID &&
      el.accepted === true &&
      el.environment === environment.value,
  );

  return (
    <APITermsOfUseModal
      open={
        isAckDataSuccess && isDisclosuresSuccess && !!activeDisclosure && !hasUnsignedAcknowledgment
      }
    />
  );
};
