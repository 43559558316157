import type { DisclosureAPIResponse } from 'app/types';

export const getActiveDisclosures = (
  data: DisclosureAPIResponse[],
): DisclosureAPIResponse | undefined => {
  if (data === undefined) return;
  return data.find(
    el => el.product === 'DRIVEHUB' && el.subProduct === 'ALL' && el.status === 'ACTIVE',
  );
};
