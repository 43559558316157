import { useWhoAmI } from 'src/app/hooks';

import { Environments, useEnvironments } from '../../../hooks';
import { copyText } from '../../../internationalization';

const getDisabledReasonForDatasrc = (
  environment: Environments,
  permissionGroups: Record<Environments, unknown>,
  companyEnvSettings: Record<Environments, unknown>,
): string | undefined => {
  const {
    navbar: {
      useEnvironmentDisabled: { noAccessRights, companyNotSetup },
    },
  } = copyText;

  if (!permissionGroups?.[environment]) {
    return noAccessRights;
  }
  if (!companyEnvSettings[environment]) {
    return companyNotSetup;
  }

  return undefined;
};

type EnvironmentDisabledReturnValue = Map<Environments, string>;

export const useIsEnvironmentDisabled = (): EnvironmentDisabledReturnValue => {
  const environments = useEnvironments();
  const { data } = useWhoAmI();
  const permissionGroups = data?.role?.datasrcPermissionGroups ?? {};
  const companyEnvSettings = data?.partner?.envs ?? {};

  return Object.values(environments).reduce((acc, { value }) => {
    const disabledMessage = getDisabledReasonForDatasrc(
      value,
      permissionGroups,
      companyEnvSettings,
    );

    if (disabledMessage) {
      acc.set(value, disabledMessage);
    }

    return acc;
  }, new Map());
};
