import { Tooltip } from '@mui/material';
import { setSetting } from 'modules/settings';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useEnvironment, useEnvironments } from '../../hooks';
import { EnvironmentMenuItem } from './EnvironmentMenuItem';
import { useIsEnvironmentDisabled } from './hooks/useEnvironmentDisabled';
import { TopNavBarDropdownMenu } from './TopNavBarDropdownMenu';
import { TwoLineItem } from './TwoLineItem';

export const EnvironmentSelector: FC = () => {
  const environments = useEnvironments();
  const env = useEnvironment();
  const currentEnvironmentLabel = env?.LABEL;
  const isEnvironmentDisabled = useIsEnvironmentDisabled();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleMenuItemClick = (id: string) => {
    history.push('/');
    dispatch(setSetting('ENV', id));
  };

  const menuItems = Object.values(environments).map(({ LABEL, DESCRIPTION, value }) => {
    const isDisabledMessage = isEnvironmentDisabled.get(value);

    const menuItem = (
      <EnvironmentMenuItem
        key={value}
        disabled={Boolean(isDisabledMessage)}
        id={value}
        description={DESCRIPTION}
        label={LABEL}
        onClick={handleMenuItemClick}
      />
    );

    if (isDisabledMessage) {
      return (
        <Tooltip key={value} title={isDisabledMessage} arrow>
          <span>{menuItem}</span>
        </Tooltip>
      );
    }

    return menuItem;
  });

  return (
    <TopNavBarDropdownMenu menuItems={menuItems}>
      <TwoLineItem top={currentEnvironmentLabel} bottom="Environment" />
    </TopNavBarDropdownMenu>
  );
};
