import immer from 'immer';
import generateReducer from 'modules/generateReducer';

import { shapeIdToSymbol, shapeSymbolToId } from './manipulations';

export const initialState = {
  symbolToId: {},
  idToSymbol: {},
};

const handlers = {
  GET_ALL_INSTRUMENTS_SUCCESS: (state, action) =>
    immer(state, draft => {
      draft.symbolToId = shapeSymbolToId(action.payload.instruments);
      draft.idToSymbol = shapeIdToSymbol(action.payload.instruments);
    }),
  GET_ALL_INSTRUMENTS_START: null,
};

export default generateReducer(initialState, handlers);
