//

import userFetcher from './sharedFetchers/user';

export default {
  id: 'CLIENT',
  label: 'Clients',
  maxSuggestions: 5,
  fetchResults: userFetcher,
  transformFetchedResults: (results, settings) =>
    results
      .filter(
        user =>
          user.userType.name === 'INDIVIDUAL_TRADER' ||
          user.userType.name === 'CUSTODIAL' ||
          user.userType.name === 'BENEFICIARY' ||
          user.userType.name === 'INSTITUTIONAL',
      )
      .map(user => ({
        path: `/users/${user.id || user.userID}${settings.usersNavUser}`,
        primary: `${user.firstName} ${user.lastName}`,
        secondary: user.username,
        tertiary: user.parentIB.name,
      })),
  tips: [
    "You can type a person's name, email, and more.",
    'You can enter a raw user ID to jump straight to their user page.',
  ],
};
