import { Amplify, Auth } from 'aws-amplify';
import { PropsWithChildren } from 'react';

import { useGetEnvironmentVariables } from './hooks';
import { findEnvVar } from './utils';

export const Bootstrap = ({ children }: PropsWithChildren<Record<string, never>>): JSX.Element => {
  useGetEnvironmentVariables({
    onSuccess: data => {
      const authConfig = {
        region: findEnvVar(data, 'REACT_APP_AWS_REGION')?.value,
        userPoolId: findEnvVar(data, 'REACT_APP_COGNITO_USERPOOL_ID')?.value,
        userPoolWebClientId: findEnvVar(data, 'REACT_APP_COGNITO_USERPOOL_CLIENT')?.value,
      };
      Amplify.configure({
        Auth: authConfig,
      });
      Auth.configure(authConfig);
    },
  });

  return <>{children}</>;
};
