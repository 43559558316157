import { useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage, usePrevious } from 'react-use';

import { getCurrentEnv } from './useEnvironment';

export const useEnvironmentChange = () => {
  const currentEnv = useSelector(getCurrentEnv);

  const prevEnv = usePrevious(currentEnv);
  const [, , clearStoredPreviousResults] = useLocalStorage('storedPreviousResults');

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isUndefined(prevEnv)) {
      return;
    }

    if (currentEnv !== prevEnv) {
      clearStoredPreviousResults();
      queryClient.clear();
    }
  }, [currentEnv, prevEnv, clearStoredPreviousResults, queryClient]);
};
