import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  teamMembers: {},
  roles: {},
};

const handlers = {
  START_GET_TEAM: null,
  GET_TEAM_ERROR: null,
  GET_TEAM_SUCCESS: (state, action) =>
    immer(state, draft => {
      const indexByID = action.payload.team.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      draft.teamMembers = indexByID;
    }),
  START_GET_ROLES: null,
  GET_ROLES_ERROR: null,
  GET_ROLES_SUCCESS: (state, action) =>
    immer(state, draft => {
      const indexByID = action.payload.roles.reduce((acc, curr) => {
        acc[curr.roleID] = curr;
        return acc;
      }, {});
      draft.roles = indexByID;
    }),
  // Delete employee actions
  START_DELETE_EMPLOYEE: null,
  DELETE_EMPLOYEE_ERROR: null,
  DELETE_EMPLOYEE_SUCCESS: null, // delete manually or fetch team again...
  // Resend invite actions
  START_RESEND_INVITE: null,
  RESEND_INVITE_ERROR: null,
  RESEND_INVITE_SUCCESS: null,
  // Change role actions
  START_CHANGE_EMPLOYEE_ROLE: null,
  CHANGE_EMPLOYEE_ROLE_ERROR: null,
  CHANGE_EMPLOYEE_ROLE_SUCCESS: null,
};

export default generateReducer(initialState, handlers, true);
