import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'accountOrders',
  initialState: {
    sort: ['createdWhen', 1],
  },
  reducers: {
    setSorting: (state, action) => {
      state.sort = action.payload;
    },
  },
});

export const { setSorting } = slice.actions;

export default slice.reducer;

export const selectOrdersSort = state => state.accountOrders.sort;
