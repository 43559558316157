import { getPending, getPendingCounts } from '../internal/documents';
import request from './request';

/**
 * @module
 * In order to prove the identity of a client, they must provide documentary proof
 * of their identity.
 */
export default class Document {
  /**
   * @constant
   * @property {string} CASH
   * @property {string} ORDERS
   * @property {string} TRANSACTIONS
   * @property {string} EQUITY
   * @property {string} VIOLATIONS
   * @property {string} ALL
   */
  static DOCUMENT_TYPES = {
    PHOTO_ID: 'PICTURE_ID',
    ADDRESS: 'PROOF_OF_ADDRESS',
    PHOTO_ID_AND_ADDRESS: 'PICTURE_ID_PROOF_OF_ADDRESS',
    CORPORATE: 'CORPORATE',
    TRUST: 'TRUST_DOCUMENTS',
    RISK: 'RISK_ASSESSMENT',
    TAX: 'TAX',
    OTHER: 'OTHER',
  };

  /**
   * @constant
   * @property {object} PICTURE
   * @property {string} PICTURE.INVALID
   * @property {string} PICTURE.MISMATCH_NAME
   * @property {string} PICTURE.ILLEGIBLE
   * @property {string} PICTURE.EXPIRED
   * @property {string} PICTURE.OTHER
   * @property {object} ADDRESS
   * @property {string} ADDRESS.INVALID
   * @property {string} ADDRESS.MISMATCH_NAME
   * @property {string} ADDRESS.ILLEGIBLE
   * @property {string} ADDRESS.EXPIRED
   * @property {string} ADDRESS.OTHER
   */
  static REJECTION_REASONS = {
    PICTURE: {
      INVALID: 'PICTURE_INVALID_DOCUMENT_TYPE',
      MISMATCH_NAME: 'PICTURE_MISMATCH_NAME',
      ILLEGIBLE: 'PICTURE_ILLEGIBLE',
      EXPIRED: 'PICTURE_EXPIRED',
      OTHER: 'PICTURE_OTHER',
    },
    ADDRESS: {
      INVALID: 'ADDRESS_INVALID_DOCUMENT_TYPE',
      MISMATCH_ADDRESS: 'ADDRESS_MISMATCH_ADDRESS',
      MISMATCH_NAME: 'ADDRESS_MISMATCH_NAME',
      ILLEGIBLE: 'ADDRESS_ILLEGIBLE',
      EXPIRED: 'ADDRESS_EXPIRED',
      OTHER: 'ADDRESS_OTHER',
    },
  };

  /**
   * @constant
   * @property {string} ENGLISH
   * @property {string} CHINESE
   * @property {string} SPANISH
   * @property {string} PORTUGUESE
   */
  static LANGUAGE_IDS = {
    ENGLISH: 'en_US',
    CHINESE: 'zh_CN',
    SPANISH: 'es_ES',
    PORTUGUESE: 'pt_BR',
  };

  /**
   * Upload documentary proof of identity for a {@link User}.
   * @param image A base64 image string or file
   */
  static create(userID, type, image, side) {
    if (typeof image === 'string') {
      return request({
        method: 'POST',
        endpoint: '/documents',
        body: {
          userID,
          type,
          side: side.toUpperCase(),
          document: image,
        },
        addlHeaders: {
          Accept: 'application/json',
        },
      })
        .then(res => res.data)
        .catch(err => {
          throw new Error(err);
        });
    }

    const body = new FormData();

    body.append('userID', userID);
    body.append('documentType', type);
    body.append('documentImage', image);
    body.append('documentImageType', image.type);
    body.append('side', side.toUpperCase());
    body.append(
      'documentImageExt',
      image.name.substring(image.name.lastIndexOf('.') + 1, image.name.length),
    );

    return request({
      method: 'POST',
      endpoint: '/documents',
      addlHeaders: {
        'Content-type': 'form-data',
      },
      body,
    })
      .then(data => data.body)
      .catch(error => {
        throw new Error(error.message ? `${error.errorCode}: ${error.message}` : error);
      });
  }

  /**
   * Get details about a document.
   */
  static get(documentID) {
    return request({
      endpoint: '/documents/' + documentID,
    }).then(data => data.body);
  }

  /**
   * Get back a temporary URL to access the file. Access will timeout after 5 minutes.
   */
  static getURL(documentID) {
    return request({
      endpoint: `/documents/${documentID}/url`,
    }).then(data => data.body.url);
  }

  /**
   * Get details about all documents in the queue.
   */
  static getPendingCounts() {
    return getPendingCounts('documents');
  }

  /**
   * Get pending documents for a {@link User} in the queue.
   * @param query either a userID string or an array of language IDs
   */
  static getPending(query) {
    return getPending('nextPending', query);
  }

  /**
   * Update a {@link User}'s document language.
   */
  static updateLanguage(userID, languageID) {
    return request({
      method: 'PATCH',
      endpoint: '/documents/updateLanguage',
      body: {
        userID,
        languageID,
      },
    }).then(() => undefined);
  }

  /**
   * Approve or reject a {@link User}'s documents.
   */
  static review(userID, reviewerUserID, documents) {
    return request({
      method: 'POST',
      endpoint: '/documents/review',
      body: {
        userID,
        approvedByUserID: reviewerUserID,
        documents,
      },
    }).then(() => undefined);
  }
}
