//
import memoize from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { useLocalStorage } from 'react-use';
import styled from 'styled-components';
import { CopyText } from 'common/constants/copyText';
import KeyboardAwareResults from './KeyboardAwareResults';
import Tips from './Tips';
import Color from 'modules/Color';

const { ENTER_CHAR_RESTRICT } = CopyText;
const TIPS_BG = new Color('#FFF');
const Container = styled.div`
  background-color: ${props => props.theme.dw.colors.corePrimary3};
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate3d(0, -10%, 0);
  z-index: 450;
  transition: ${props =>
    props.isActive
      ? props.theme.transitions.create(['opacity', 'transform'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.enteringScreen,
        })
      : props.theme.transitions.create(['opacity', 'transform'], {
          easing: props.theme.transitions.easing.sharp,
          duration: props.theme.transitions.duration.leavingScreen,
        })};
  user-select: text;
  font-weight: normal;
  overflow: auto;
  max-height: 80vh;

  ${props =>
    props.isActive &&
    `
		pointer-events: all;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		z-index: 600;
	`}
`;

const StyledPara = styled.p`
color: ${TIPS_BG.adjust(0.4)};
padding: 10px;
&::before {
  content: '';
  display: inline-block;
}
`;

const getKeyForResultSet = memoize(results => String(Date.now()));
const SearchSuggestions = props => {
  const [storedItems] = useLocalStorage(
    'storedPreviousResults',
    localStorage.getItem('storedPreviousResults'),
  );

  const allSearchResults = storedItems ? { ...storedItems, ...props.results } : props.results;

  const allSearchResultsCount = storedItems
    ? storedItems.PREVIOUS.length + props.resultsCount
    : props.resultsCount;
  return (
    <Container isActive={props.isActive}>
      {(!props.searchQuery || (props.searchQuery && props.searchQuery.length < 3)) ? (
     <>
      <StyledPara>{ENTER_CHAR_RESTRICT}</StyledPara>
    <Tips />
    </>
     ) : (
        <>
         <KeyboardAwareResults
            key={getKeyForResultSet(allSearchResults)}
            isActive={props.isActive}
            results={allSearchResults}
            resultsCount={allSearchResultsCount}
          />
          </>
      )}
    </Container>
  );
};

export default connect(state => ({
  searchQuery: state.search.searchedQuery,
  results: state.search.results,
  resultsCount: state.search.resultsCount,
}))(SearchSuggestions);
