import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/app/services';
import { AddExchangeOrderReturnValue, CreateExchangeOrder } from 'src/app/types';

import { useWhoAmI } from './useWhoAmI';

const endpoint = new URL('/api/exchanges', window.location.origin).href;

export const useAddNewExchangeOrder = (): UseMutationResult<
  AddExchangeOrderReturnValue,
  { errorCode: number; message: string },
  CreateExchangeOrder
> => {
  const queryClient = useQueryClient();
  const { data: whoAmIData } = useWhoAmI();

  return useMutation<
    AddExchangeOrderReturnValue,
    { errorCode: number; message: string },
    CreateExchangeOrder
  >({
    mutationFn: async variables => {
      const data = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(variables),
        headers: {
          'Content-type': 'application/json',
        },
      });

      return data.json() as Promise<AddExchangeOrderReturnValue>;
    },

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['accounts', variables.accountID, 'summary', 'orders'],
      });
      queryClient.invalidateQueries({ queryKey: ['accounts', variables.accountID, 'positions'] });
      queryClient.invalidateQueries({
        queryKey: [
          'reports',
          'positionDetails',
          { wlpId: whoAmIData?.employee.partnerID as string, accountNumber: variables.accountNo },
        ],
      });
    },
  });
};
