import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { createContext } from 'react';

import { SignInInput } from './types';

interface AuthState {
  user: CognitoUser | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  challenge: any | null; // TODO: Enhance Challenge typing
  confirmSignIn(code: string): ReturnType<(typeof Auth)['confirmSignIn']>;
  signIn(input: SignInInput): ReturnType<(typeof Auth)['signIn']>;
  signOut(): ReturnType<(typeof Auth)['signOut']>;
  deleteUser(): ReturnType<(typeof Auth)['deleteUser']>;
}

export const AuthContext = createContext<AuthState>({
  user: null,
  challenge: null,
  signIn: async () => {},
  signOut: async () => {},
  deleteUser: async () => {},
  confirmSignIn: async () => {},
});
