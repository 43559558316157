/**
 * Mirrors a single box-shadow entry
 * @param boxShadow A box shadow string of type "0px 2px 4px -1px rgba(0,0,0,0.2)"
 * @returns a flipped box shadow string like "0px -2px 4px -1px rgba(0,0,0,0.2)"
 */
const flipIndividualBoxShadow = (boxShadow: string): string => {
  const parts = boxShadow.split(' ');

  const flipped = Number.parseFloat(parts[1].replace('px', '')) * -1;

  parts[1] = `${flipped}px`;

  return parts.join(' ');
};

/**
 * Mirrors a box shadow along the x-axis so it is going upward
 * @param boxShadow a box shadow string like "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
 * @returns a string like "0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)"
 */
export const flipBoxShadow = (boxShadow: string): string => {
  const individualParts = boxShadow.split('),');
  return individualParts
    .map((part, idx) => (idx !== individualParts.length - 1 ? `${part})` : part))
    .map(flipIndividualBoxShadow)
    .join(',');
};
