import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { isUndefined } from 'lodash';
import { fetch } from 'src/app/services';
import { Acknowledgment } from 'src/app/types';

const endpoint = new URL('/api/acknowledgments', window.location.origin).href;

export type AddAcknowledgmentVariables = Pick<
  Acknowledgment,
  'disclosureID' | 'version' | 'userID'
>;

export const useAddAcknowledgment = (): UseMutationResult<
  Acknowledgment,
  { errorCode: number; message: string },
  AddAcknowledgmentVariables
> => {
  const queryClient = useQueryClient();

  return useMutation<
    Acknowledgment,
    { errorCode: number; message: string },
    AddAcknowledgmentVariables
  >({
    mutationFn: async variables => {
      const { userID, ...rest } = variables;

      const data = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(rest),
        headers: {
          'Content-type': 'application/json',
        },
      });

      return data.json() as Promise<Acknowledgment>;
    },

    onSuccess: (data, { userID }) => {
      const queryData = queryClient.getQueryData<{ data: Acknowledgment[] }>([
        'acknowledgments',
        userID,
      ]);

      if (isUndefined(queryData)) {
        return;
      }

      const updatedAcknowledgments = produce(queryData, draft => {
        draft.data.push(data);
      });

      queryClient.setQueryData(['acknowledgments', userID], updatedAcknowledgments);
    },
  });
};
