const initialState = {
  editingIndexes: [],
  orderNumber: '',
};

export function createAllocationsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'EDIT_THIS_ROW':
      return { ...state, editingIndexes: [...state.editingIndexes, payload.index] };

    case 'CHANGE_ORDER_NUMBER':
      return { ...state, orderNumber: payload.value };

    case 'CLEAR_FORM':
      return initialState;

    default:
      return state;
  }
}
