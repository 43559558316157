import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { initialFetch } from '../services';
import { WhoAmI } from '../types';

// What else is on this? Is this a standard form?
export type WhoAmIError = {
  message: string;
};

const endpoint = new URL('/api/employees/me', window.location.origin).href;

export const useWhoAmI = (
  options?: UseQueryOptions<WhoAmI, WhoAmIError>,
): UseQueryResult<WhoAmI, WhoAmIError> => {
  return useQuery<WhoAmI, WhoAmIError>({
    queryKey: ['whoAmI'],
    queryFn: async () => {
      const data = await initialFetch(endpoint);

      return data.json() as Promise<WhoAmI>;
    },
    ...options,
  });
};
