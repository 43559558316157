export enum AllBatchPermissions {
  CASH_TRANSFER_CREATE_BATCH_CREATE = 'CASH_TRANSFER_CREATE_BATCH_CREATE',
  FINTRANS_CREATE_BATCH_CREATE = 'FINTRANS_CREATE_BATCH_CREATE',
  ORDER_ADD_CREATE_BATCH_CREATE = 'ORDER_ADD_CREATE_BATCH_CREATE',
  ORDER_CANCEL_CREATE_BATCH_CREATE = 'ORDER_CANCEL_CREATE_BATCH_CREATE',
  POSITION_ADJUST_CREATE_BATCH_CREATE = 'POSITION_ADJUST_CREATE_BATCH_CREATE',
  SHARE_TRANSFER_CREATE_BATCH_CREATE = 'SHARE_TRANSFER_CREATE_BATCH_CREATE',
  PROMOTION_CASH_TRANSFER_CREATE_BATCH_CREATE = 'PROMOTION_CASH_TRANSFER_CREATE_BATCH_CREATE',
  PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_CREATE = 'PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_CREATE',
  PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_CREATE = 'PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_CREATE',
  PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_CREATE = 'PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_CREATE',
  PROMOTION_SHARE_TRANSFER_CREATE_BATCH_CREATE = 'PROMOTION_SHARE_TRANSFER_CREATE_BATCH_CREATE',
  PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_CREATE = 'PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_CREATE',
  LOCK_UNLOCK_CREATE = 'LOCK_UNLOCK_CREATE',
  PRESIGNED_S3_LINK_GET = 'PRESIGNED_S3_LINK_GET',
  CANCEL_REBILL_CREATE_BATCH = 'CANCEL_REBILL_CREATE_BATCH',
  CANCEL_REBILL_CREATE_BATCH_VIEW = 'CANCEL_REBILL_CREATE_BATCH_VIEW',
  RECON_ACCOUNT_EXCLUSION_CREATE_BATCH = 'RECON_ACCOUNT_EXCLUSION_CREATE_BATCH',
  RECON_ACCOUNT_EXCLUSION_CREATE_BATCH_VIEW = 'RECON_ACCOUNT_EXCLUSION_CREATE_BATCH_VIEW',
}

export enum AllViewBatchPermissions {
  FINTRANS_CREATE_VIEW = 'FINTRANS_CREATE_VIEW',
  ORDER_ADD_CREATE_BATCH_VIEW = 'ORDER_ADD_CREATE_BATCH_VIEW',
  ORDER_CANCEL_CREATE_BATCH_VIEW = 'ORDER_CANCEL_CREATE_BATCH_VIEW',
  SHARE_TRANSFER_CREATE_BATCH_VIEW = 'SHARE_TRANSFER_CREATE_BATCH_VIEW',
  CASH_TRANSFER_CREATE_BATCH_VIEW = 'CASH_TRANSFER_CREATE_BATCH_VIEW',
  POSITION_ADJUST_CREATE_BATCH_VIEW = 'POSITION_ADJUST_CREATE_BATCH_VIEW',
  EXECUTED_AWAY_ORDER_CREATE_BATCH_VIEW = 'EXECUTED_AWAY_ORDER_CREATE_BATCH_VIEW',
  ACCOUNTS_EDIT_CREATE_BATCH_VIEW = 'ACCOUNTS_EDIT_CREATE_BATCH_VIEW',
  ACCOUNTS_CLOSE_CREATE_BATCH_VIEW = 'ACCOUNTS_CLOSE_CREATE_BATCH_VIEW',
  INSTRUMENTS_EDIT_CREATE_BATCH_VIEW = 'INSTRUMENTS_EDIT_CREATE_BATCH_VIEW',
  RECON_ACCOUNT_EXCLUSION_CREATE_BATCH = 'RECON_ACCOUNT_EXCLUSION_CREATE_BATCH',
  INSTRUMENTS_ADD_CREATE_BATCH_VIEW = 'INSTRUMENTS_ADD_CREATE_BATCH_VIEW',
  DEPOSITS_CREATE_BATCH_VIEW = 'DEPOSITS_CREATE_BATCH_VIEW',
  ORDER_STATUS_CREATE_BATCH_VIEW = 'ORDER_STATUS_CREATE_BATCH_VIEW',
  PROMOTION_CASH_TRANSFER_CREATE_BATCH_VIEW = 'PROMOTION_CASH_TRANSFER_CREATE_BATCH_VIEW',
  PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_VIEW = 'PROMOTION_CASH_TRANSFER_BYPASS_TIME_CHECK_VIEW',
  PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW = 'PROMOTION_CASH_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW',
  PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_VIEW = 'PROMOTION_CASH_TRANSFER_BYPASS_TRANSACTION_LIMIT_CHECK_VIEW',
  PROMOTION_SHARE_TRANSFER_CREATE_BATCH_VIEW = 'PROMOTION_SHARE_TRANSFER_CREATE_BATCH_VIEW',
  PROMOTION_SHARE_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW = 'PROMOTION_SHARE_TRANSFER_BYPASS_PROMOTION_ACCOUNT_PURPOSE_VIEW',
  PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_VIEW = 'PROMOTION_SHARE_TRANSFER_BYPASS_TIME_CHECK_VIEW',
  BATCH_USER_DROPDOWN_VIEW = 'BATCH_USER_DROPDOWN_VIEW',
  LOCK_UNLOCK_VIEW = 'LOCK_UNLOCK_VIEW',
}

enum RestPermissions {
  ACCOUNTS_CREATE_LIVE = 'ACCOUNTS_CREATE_LIVE',
  ACCOUNTS_CREATE_MANAGED = 'ACCOUNTS_CREATE_MANAGED',
  ACCOUNTS_CREATE_MARGIN = 'ACCOUNTS_CREATE_MARGIN',
  ACCOUNTS_CREATE_PRACTICE = 'ACCOUNTS_CREATE_PRACTICE',
  ACCOUNTS_CREATE_RIA_MANAGED = 'ACCOUNTS_CREATE_RIA_MANAGED',
  ACCOUNTS_CREATE_HSA_SELF = 'ACCOUNTS_CREATE_HSA_SELF',
  ACCOUNTS_CREATE_HSA_ADVISORY = 'ACCOUNTS_CREATE_HSA_ADVISORY',
  ACCOUNTS_CREATE_HSA_RIA_MANAGED = 'ACCOUNTS_CREATE_HSA_RIA_MANAGED',
  ACCOUNTS_GET = 'ACCOUNTS_GET',
  ACCOUNTS_EDIT = 'ACCOUNTS_EDIT',
  ACCOUNTS_EDIT_BOD = 'ACCOUNTS_EDIT_BOD',
  ACCOUNTS_EDIT_LEVERAGE = 'ACCOUNTS_EDIT_LEVERAGE',
  BANK_ACCOUNTS_GET = 'BANK_ACCOUNTS_GET',
  BANK_ACCOUNTS_DELETE = 'BANK_ACCOUNTS_DELETE',
  BO_PASSWORD_UPDATE = 'BO_PASSWORD_UPDATE',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  DASHBOARD_PARTNER_DROPDOWN_VIEW = 'DASHBOARD_PARTNER_DROPDOWN_VIEW',
  DEPOSITS_GET_ALL = 'DEPOSITS_GET_ALL',
  DOCUMENTS_STATUS_EDIT = 'DOCUMENTS_STATUS_EDIT',
  DOCUMENTS_GET = 'DOCUMENTS_GET',
  DOCUMENTS_DELETE = 'DOCUMENTS_DELETE',
  EMPLOYEES_CREATE = 'EMPLOYEES_CREATE',
  EMPLOYEES_GET = 'EMPLOYEES_GET',
  EMPLOYEES_EDIT = 'EMPLOYEES_EDIT',
  EMPLOYEES_DELETE = 'EMPLOYEES_DELETE',
  INSTRUMENTS_CREATE = 'INSTRUMENTS_CREATE',
  INSTRUMENTS_EDIT = 'INSTRUMENTS_EDIT',
  INSTRUMENTS_EDIT_OPTIONS = 'INSTRUMENTS_EDIT_OPTIONS',
  INSTRUMENTS_EDIT_LEVERAGE = 'INSTRUMENTS_EDIT_LEVERAGE',
  INSTRUMENT_LISTING_VIEW = 'INSTRUMENT_LISTING_VIEW',
  INSTRUMENT_GROUP_VIEW = 'INSTRUMENT_GROUP_VIEW',
  INSTRUMENT_GROUP_EDIT = 'INSTRUMENT_GROUP_EDIT',
  INSTRUMENTS_GET = 'INSTRUMENTS_GET',
  INSTRUMENTS_ISIN_VIEW = 'INSTRUMENTS_ISIN_VIEW',
  INSTRUMENTS_ISIN_EDIT = 'INSTRUMENTS_ISIN_EDIT',
  INSTRUMENTS_CUSIP_VIEW = 'INSTRUMENTS_CUSIP_VIEW',
  INSTRUMENTS_CUSIP_EDIT = 'INSTRUMENTS_CUSIP_EDIT',
  INSTRUMENTS_SEDOL_VIEW = 'INSTRUMENTS_SEDOL_VIEW',
  INSTRUMENTS_SEDOL_EDIT = 'INSTRUMENTS_SEDOL_EDIT',
  INSTRUMENTS_RIC_VIEW = 'INSTRUMENTS_RIC_VIEW',
  INSTRUMENTS_RIC_EDIT = 'INSTRUMENTS_RIC_EDIT',
  INVESTING_PROFILE_INFO_HIDE = 'INVESTING_PROFILE_INFO_HIDE',
  KYC_STATUS_UPDATE = 'KYC_STATUS_UPDATE',
  MANAGED_CREATE_ORDER_OVERRIDE = 'MANAGED_CREATE_ORDER_OVERRIDE',
  MANAGED_GET_ALLOCATIONS_INTERNAL = 'MANAGED_GET_ALLOCATIONS_INTERNAL',
  MARGINCALL_ALERT_GET = 'MARGINCALL_ALERT_GET',
  // Any user that wants to use any of the two following permissions
  // must also have REDEMPTIONS_UPDATE and REDEMPTIONS_UPDATE_STATUS
  // in order to update a redemption.
  // To update deposits they must only have the DEPOSITS_UPDATE_STATUS permission.
  MONEY_MOVEMENT_MOVE_TO_APPROVED = 'MONEY_MOVEMENT_MOVE_TO_APPROVED',
  MONEY_MOVEMENT_MOVE_TO_REJECTED = 'MONEY_MOVEMENT_MOVE_TO_REJECTED',
  NET_SETTLE_VIEW = 'NET_SETTLE_VIEW',
  NET_SETTLE_EDIT = 'NET_SETTLE_EDIT',
  SINGLE_SETTLEMENT_VIEW = 'SINGLE_SETTLEMENT_VIEW',
  SINGLE_SETTLEMENT_EDIT = 'SINGLE_SETTLEMENT_EDIT',
  NOTES_CREATE = 'NOTES_CREATE',
  NOTES_GET = 'NOTES_GET',
  ONBOARD_PARTNER = 'ONBOARD_PARTNER',
  ORDERS_CREATE = 'ORDERS_CREATE',
  ORDERS_CREATE_CUSTOM_COMMISSION = 'ORDERS_CREATE_CUSTOM_COMMISSION',
  ORDERS_CANCEL = 'ORDERS_CANCEL',
  ORDERS_PHONE_CREATE = 'ORDERS_PHONE_CREATE',
  PARTNER_CREATE = 'PARTNER_CREATE',
  PARTNER_SETTING_VIEW = 'PARTNER_SETTING_VIEW',
  PARTNER_SETTING_EDIT = 'PARTNER_SETTING_EDIT',
  PARTNER_EDIT = 'PARTNER_EDIT',
  PARTNER_GET = 'PARTNER_GET',
  PARTNER_SETTINGS_GET = 'PARTNER_SETTINGS_GET',
  PERMISSIONS_UPDATE = 'PERMISSIONS_UPDATE',
  PERMISSION_GROUP_GET = 'PERMISSION_GROUP_GET',
  PERMISSION_GROUP_EDIT = 'PERMISSION_GROUP_EDIT',
  PERMISSION_GROUP_CREATE = 'PERMISSION_GROUP_CREATE',
  PURPOSE_ACCOUNT_CREATE = 'PURPOSE_ACCOUNT_CREATE',
  RECONCILIATIONS_GET_ALL = 'RECONCILIATIONS_GET_ALL',
  RECONCILIATIONS_EDIT = 'RECONCILIATIONS_EDIT',
  RECONCILIATIONS_VIEW = 'RECONCILIATIONS_VIEW',
  REDEMPTIONS_GET_ALL = 'REDEMPTIONS_GET_ALL',
  REPORTS_GET = 'REPORTS_GET',
  REPORTS_GET_BESTEX = 'REPORTS_GET_BESTEX',
  REPORTS_GET_TPLUSONE = 'REPORTS_GET_TPLUSONE',
  REPORTS_GET_INTELLICLEAR = 'REPORTS_GET_INTELLICLEAR',
  REPORTS_GET_INTELLICLEAR_DOWNLOAD = 'REPORTS_GET_INTELLICLEAR_DOWNLOAD',
  REPORTS_GET_RESTRICTED_ACCOUNTS = 'REPORTS_GET_RESTRICTED_ACCOUNTS',
  REPORTS_GET_PDT = 'REPORTS_GET_PDT',
  REPORTS_GET_871M = 'REPORTS_GET_871M',
  END_OF_DAY_TRADING_REPORT_GET = 'END_OF_DAY_TRADING_REPORT_GET',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_EDIT = 'ROLE_EDIT',
  SUBSCRIPTIONS_GET = 'SUBSCRIPTIONS_GET',
  USERS_CREATE_ADVISOR = 'USERS_CREATE_ADVISOR',
  USERS_EDIT = 'USERS_EDIT',
  USERS_EDIT_KYC_STATUS = 'USERS_EDIT_KYC_STATUS',
  USERS_VIEW_KYC_STATUS = 'USERS_VIEW_KYC_STATUS',
  USERS_EDIT_PII = 'USERS_EDIT_PII',
  USERS_EDIT_PRINCIPAL_STATUS = 'USERS_EDIT_PRINCIPAL_STATUS',
  VIEW_PARTNER_DROPDOWN = 'VIEW_PARTNER_DROPDOWN',
  MANAGE_TEAM_VIEW = 'MANAGE_TEAM_VIEW',
  MANAGE_TEAM_EDIT = 'MANAGE_TEAM_EDIT',
  MANAGE_TEAM_GET = 'MANAGE_TEAM_GET',
  TAX_CENTER_UTIL_LINK_VIEW = 'TAX_CENTER_UTIL_LINK_VIEW',
  VIEW_RECENT_BATCHES = 'VIEW_RECENT_BATCHES',
  VIEW_RIA_ONLY = 'VIEW_RIA_ONLY',
  VIEW_VERSION = 'VIEW_VERSION',
  VIRTUAL_DEPOSITS_GET_ALL = 'VIRTUAL_DEPOSITS_GET_ALL',
  VIRTUAL_ACCOUNTS_VIEW = 'VIRTUAL_ACCOUNTS_VIEW',
  VIRTUAL_ACCOUNT_AMOUNT_INPUT_VIEW = 'VIRTUAL_ACCOUNT_AMOUNT_INPUT_VIEW',
  WLP_GET = 'WLP_GET',
  WLP_CREATE = 'WLP_CREATE',
  WLP_EDIT = 'WLP_EDIT',
  SEARCH_BAR_VIEW = 'SEARCH_BAR_VIEW',
  DEPOSITS_GET_BY_ID = 'DEPOSITS_GET_BY_ID',
  REDEMPTIONS_GET_BY_ID = 'REDEMPTIONS_GET_BY_ID',
  DIGITAL_WRITE = 'DIGITAL_WRITE',
  EQUITIES_VIEW = 'EQUITIES_VIEW',
  MUTUAL_FUND_VIEW = 'MUTUAL_FUND_VIEW',
  FIXED_INCOME_VIEW = 'FIXED_INCOME_VIEW',
  EQUITIES_WRITE = 'EQUITIES_WRITE',
  ACCOUNTS_EDIT_TRADING_FUNC = 'ACCOUNTS_EDIT_TRADING_FUNC',
  ACCOUNTS_LOCK_UNLOCK_POSITIONS = 'ACCOUNTS_LOCK_UNLOCK_POSITIONS',
  MANAGED_GET_PORTFOLIO_STATUS = 'MANAGED_GET_PORTFOLIO_STATUS',
  FINTRANS_GET = 'FINTRANS_GET',
  ACCOUNTS_BENEFICIARIES_GET = 'ACCOUNTS_BENEFICIARIES_GET',
  FPSL_VIEW = 'FPSL_VIEW',
  RULE_14B_EDIT = 'RULE_14B_EDIT',
  DOCUMENTS_CREATE = 'DOCUMENTS_CREATE',
  MANAGE_PERMISSIONS_VIEW = 'MANAGE_PERMISSIONS_VIEW',
  MANAGE_PERMISSIONS_EDIT = 'MANAGE_PERMISSIONS_EDIT',
  MANAGE_PERMISSION_GROUPS_VIEW = 'MANAGE_PERMISSION_GROUPS_VIEW',
  MANAGE_PERMISSION_GROUPS_EDIT = 'MANAGE_PERMISSION_GROUPS_EDIT',
  MANAGE_ROLES_VIEW = 'MANAGE_ROLES_VIEW',
  MANAGE_ROLES_EDIT = 'MANAGE_ROLES_EDIT',
  MANAGE_EXCLUSIONS_READ = 'MANAGE_EXCLUSIONS_READ',
  TAX_PROFILE_WRITE = 'TAX_PROFILE_WRITE',
  DEVELOPER_TOOLS_VIEW = 'DEVELOPER_TOOLS_VIEW',
  OVERRIDE_PDT_RESTRICTIONS = 'OVERRIDE_PDT_RESTICTIONS',
  CANCEL_REBILL_READ = 'CANCEL_REBILL_READ',
  CANCEL_REBILL_CREATE = 'CANCEL_REBILL_CREATE',
  CANCEL_REBILL_EDIT = 'CANCEL_REBILL_EDIT',
  VIEW_OPTIONS_SUITABILITY = 'VIEW_OPTIONS_SUITABILITY',
  EDIT_OPTIONS_SUITABILITY = 'EDIT_OPTIONS_SUITABILITY',
  VIEW_ACCOUNT_OPTIONS_ENROLLED = 'VIEW_ACCOUNT_OPTIONS_ENROLLED',
  EDIT_ACCOUNT_OPTIONS_ENROLLED = 'EDIT_ACCOUNT_OPTIONS_ENROLLED',
  VIEW_INSTRUMENT_OPTIONS_ENABLED = 'VIEW_INSTRUMENT_OPTIONS_ENABLED',
  EDIT_INSTRUMENT_OPTIONS_ENABLED = 'EDIT_INSTRUMENT_OPTIONS_ENABLED',
  VIEW_OPTION_CHAIN = 'VIEW_OPTION_CHAIN',
  VIEW_EXPIRATION_DAY_REPORT = 'VIEW_EXPIRATION_DAY_REPORT',
  ACAT_VIEW = 'ACAT_VIEW',
  ACAT_CREATE = 'ACAT_CREATE',
  ACAT_UPDATE = 'ACAT_UPDATE',
  MINOR_GRADUATION_VIEW = 'MINOR_GRADUATION_VIEW',
  MINOR_GRADUATION_CREATE = 'MINOR_GRADUATION_CREATE',
  MINOR_GRADUATION_UPDATE = 'MINOR_GRADUATION_UPDATE',
  ACCOUNT_STATUS_EDIT = 'ACCOUNT_STATUS_EDIT',
  ACCOUNT_VIOLATION_EDIT = 'ACCOUNT_VIOLATION_EDIT',
  ACCOUNT_FREE_TRADE_BALANCE_EDIT = 'ACCOUNT_FREE_TRADE_BALANCE_EDIT',
  ACCOUNT_MONEY_MARKET_SWEEP_EDIT = 'ACCOUNT_MONEY_MARKET_SWEEP_EDIT',
  ACCOUNT_INTEREST_FREE_EDIT = 'ACCOUNT_INTEREST_FREE_EDIT',
  ACCOUNT_DIVIDEND_REINVEST_EDIT = 'ACCOUNT_DIVIDEND_REINVEST_EDIT',
  ACCOUNT_CAPITAL_GAIN_REINVEST_EDIT = 'ACCOUNT_CAPITAL_GAIN_REINVEST_EDIT',
  POSITIONS_EDIT = 'POSITIONS_EDIT',
  VIOLATION_READ = 'VIOLATION_READ',
  VIOLATION_DELETE = 'VIOLATION_DELETE',
  MUTUAL_FUND_EDIT = 'MUTUAL_FUND_EDIT',
  AUTO_LIQUIDATION_EDIT = 'AUTO_LIQUIDATION_EDIT',
  AUTO_LIQUIDATION_GET = 'AUTO_LIQUIDATION_GET',
  LIQUIDATIONS_CREATE = 'LIQUIDATIONS_CREATE',
  FIXED_INCOME_EDIT = 'FIXED_INCOME_EDIT',
}

enum SecLendingPermissions {
  BROKER_DAILY_READ = 'BROKER_DAILY_READ',
  BROKER_DAILY_WRITE = 'BROKER_DAILY_WRITE',
  POSITION_READ = 'POSITION_READ',
  POSITION_WRITE = 'POSITION_WRITE',
  DAILY_CALC_READ = 'DAILY_CALC_READ',
  DAILY_CALC_WRITE = 'DAILY_CALC_WRITE',
  NOTES_READ = 'NOTES_READ',
  NOTES_WRITE = 'NOTES_WRITE',
  NOTES_DELETE = 'NOTES_DELETE',
  RECON_READ = 'RECON_READ',
  RECON_WRITE = 'RECON_WRITE',
  RECON_HISTORY_READ = 'RECON_HISTORY_READ',
  RECON_HISTORY_WRITE = 'RECON_HISTORY_WRITE',
  OMNI_VIEW = 'OMNI_VIEW',
  UPLOAD_ERRORS = 'UPLOAD_ERRORS',
  DAILY_LENDABLE = 'DAILY_LENDABLE',
}

enum SecLendingReportsPermissions {
  SEC_LENDING_CLIENT_DAILY_READ = 'SEC_LENDING_CLIENT_DAILY_READ',
  SEC_LENDING_CLIENT_MTD_READ = 'SEC_LENDING_CLIENT_MTD_READ',
  SEC_LENDING_CLIENT_EOM_READ = 'SEC_LENDING_CLIENT_EOM_READ',
  SEC_LENDING_PARTNER_DAILY_READ = 'SEC_LENDING_PARTNER_DAILY_READ',
  SEC_LENDING_PARTNER_MTD_READ = 'SEC_LENDING_PARTNER_MTD_READ',
  SEC_LENDING_SUPPLEMENTAL_DAILY_READ = 'SEC_LENDING_SUPPLEMENTAL_DAILY_READ',
  SEC_LENDING_DW_MTD_READ = 'SEC_LENDING_DW_MTD_READ',
  SEC_LENDING_COLLATERAL_READ = 'SEC_LENDING_COLLATERAL_READ',
}

enum InstrumentRequestPermissions {
  INSTRUMENT_REQUEST_CREATE = 'INSTRUMENT_REQUEST_CREATE',
  INSTRUMENT_REQUEST_APPROVE = 'INSTRUMENT_REQUEST_APPROVE',
}

enum ManagedOmnibusAccountPermissions {
  MANAGED_OMNIBUS_ALLOCATIONS_CREATE = 'MANAGED_OMNIBUS_ALLOCATIONS_CREATE',
}

export const Permissions = {
  ...RestPermissions,
  ...AllBatchPermissions,
  ...AllViewBatchPermissions,
  ...SecLendingPermissions,
  ...SecLendingReportsPermissions,
  ...InstrumentRequestPermissions,
  ...ManagedOmnibusAccountPermissions,
};

export type Permission = keyof typeof Permissions;
