import request from './request';

/**
 * @class MoneyMovement
 * @description allow clients to deposit/withdraw money from their DriveWealth account.
 */
export default class MoneyMovement {
  static DIRECTIONS = {
    DEPOSITS: 'deposits',
    WITHDRAWALS: 'redemptions',
    SUBSCRIPTION_TRANS: 'subscriptions',
    VIRTUAL: 'virtual',
  };

  /**
   * Get money movement list based on status
   */
  static async getList(
    direction,
    status,
    type,
    range,
    options,
    parentIbId,
    accountNo,
    minAmount,
    maxAmount,
  ) {
    let endpoint;
    switch (direction) {
      case MoneyMovement.DIRECTIONS.VIRTUAL:
        endpoint =
          status === 'All'
            ? `/funding/deposits/suspense?`
            : `/funding/deposits/suspense?status=${status}`;
        break;
      case MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS:
        endpoint = `/subscriptions/transactions?status=${status.toUpperCase()}`;
        break;
      default:
        endpoint = `/funding/${direction.toLowerCase()}?status=${status.toUpperCase()}`;
        break;
    }

    if (range) {
      const dateRange =
        direction === MoneyMovement.DIRECTIONS.VIRTUAL
          ? `&startDate=${range.startDate}&endDate=${range.endDate}`
          : `&from=${range.startDate}&to=${range.endDate}`;
      endpoint += dateRange;
    }

    if (minAmount && parentIbId) {
      endpoint += `&minAmount=${minAmount.length ? minAmount : 0}`;
    }

    if (maxAmount && parentIbId) {
      endpoint += `&maxAmount=${maxAmount || 10000000}`;
    }

    if (type && type.length) {
      endpoint += `&type=${(typeof type === 'string' ? type : type.join(',')).toUpperCase()}`;
    }

    if (parentIbId && parentIbId.length) {
      endpoint +=
        direction === MoneyMovement.DIRECTIONS.VIRTUAL
          ? `&subPartnerID=${parentIbId}`
          : `&parentIBID=${parentIbId}`;
    }

    if (accountNo) {
      endpoint += `&accountNo=${accountNo}`;
    }

    if (options) {
      for (const option in options) {
        endpoint += `&${option}=${options[option]}`;
      }
    }

    try {
      const data = await request({ endpoint });
      return {
        data: data.body.data || data.body,
        _metadata: data.body._metadata, // only exists for virtual accounts at the moment
      };
    } catch (err) {
      return { data: [], _metadata: {} };
    }
  }

  /**
   * Get details about a deposit/redemption by its ID.
   */
  static get(id, direction) {
    /*
     * types: "deposits" | "redemptions"
     */
    return request({
      endpoint:
        direction !== MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS
          ? `/funding/${direction.toLowerCase()}/${id}`
          : `/subscriptions/transactions/${id}`,
    }).then(data => data.body);
  }

  /**
   * Edit details about a deposit/redemption by its ID.
   */
  static edit(id, details, direction) {
    return request({
      method: 'PATCH',
      endpoint:
        direction !== MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS
          ? `/funding/${direction.toLowerCase()}/${id}`
          : `/subscriptions/transactions/${id}`,
      body: details,
    }).then(res => {
      return res;
    });
  }

  /**
   * Get data about deposits/redemptions
   */
  static downloadCSV(direction, status, type) {
    const endpoint =
      direction !== MoneyMovement.DIRECTIONS.SUBSCRIPTION_TRANS
        ? `/funding/${direction.toLowerCase()}/${type}/download?status=${status}`
        : `/subscriptions/transactions?status=${status}`;

    return request({
      endpoint,
      addlHeaders: {
        Accept: 'text/csv',
      },
    }).then(data => data.body);
  }

  static getRecurringDepositsForUser(userID) {
    const endpoint = `/users/${userID}/recurring-deposits`;
    return request({ endpoint }).then(data => data.body);
  }

  static getDepositHistory(depositID) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({ endpoint }).then(data => data.body);
  }

  static getPaymentHistoryDeposit(paymentID) {
    const endpoint = `/funding/deposits/${paymentID}`;
    return request({ endpoint }).then(data => data.body);
  }

  static getPaymentHistoryWithdrawal(paymentID) {
    const endpoint = `/funding/redemptions/${paymentID}`;
    return request({ endpoint }).then(data => data.body);
  }

  static updateRecurringDeposit(depositID, body) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({
      method: 'PATCH',
      endpoint,
      body,
    }).then(data => data.body);
  }

  static deleteRecurringDeposit(depositID) {
    const endpoint = `/funding/recurring-deposits/${depositID}`;
    return request({
      method: 'DELETE',
      endpoint,
    }).then(data => data);
  }
}
