/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const orderStatus = {
  displayName: 'Order Status',
  permission: 'ORDER_STATUS_CREATE_BATCH_VIEW',
  csvInputs: {
    orderid: {
      label: 'Order ID',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'orderID',
    },
    orderno: {
      label: 'Order Number',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'orderNo',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      orderID: '123456789',
      orderNo: '123456789',
    },
  ],
};

export { orderStatus };
