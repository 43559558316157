import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const NavbarIconButton = styled(IconButton)`
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;

  svg {
    color: ${props => props.theme.dw.colors.white};
    height: 20px;
    width: 20px;
  }
`;
