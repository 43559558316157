import { isUndefined } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEnvironment } from 'src/app/hooks';
import { init, sendPageView, updateUserId } from 'src/app/services/analytics';
import { EmployeeAPI } from 'src/app/types';

import { useWhoAmI } from './useWhoAmI';

const getUserId = (employee?: EmployeeAPI): string | null => {
  if (isUndefined(employee)) {
    return null;
  }

  return employee.id;
};

export const useGoogleAnalytics = (): void => {
  const location = useLocation();
  const env = useEnvironment();
  const { data: whoAmIData } = useWhoAmI();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const userId = getUserId(whoAmIData?.employee);
  const partnerName = whoAmIData?.partner.companyName;
  const roleID = whoAmIData?.role.roleID;

  const trackingId = env?.GA;

  useEffect(() => {
    if (trackingId && userId) {
      init(trackingId);
      setIsInitialized(true);
      updateUserId(userId);
    }
  }, [trackingId, userId]);

  useEffect(() => {
    if (!partnerName || !roleID || !isInitialized || !userId) {
      return;
    }

    const currentPath = location.pathname + location.search;

    sendPageView({
      path: currentPath,
      partnerName,
      roleID,
    });
  }, [isInitialized, location.pathname, location.search, partnerName, roleID, userId]);
};
