import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

const SecondaryText = styled(Typography)`
  color: ${props => props.theme.dw.colors.corePrimary5};
`;

interface TwoLineItemProps {
  top: string;
  bottom: string;
}

export const TwoLineItem: FC<TwoLineItemProps> = ({ top, bottom }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="subtitle1">{top}</Typography>
    <SecondaryText variant="subtitle1">{bottom}</SecondaryText>
  </Box>
);
