import { Box, Button, LoadingButton, TextField } from '@drivehub/ui-components';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useUnmountPromise } from 'react-use';
import { useSnackbar } from 'src/app/hooks';
import { useConfirmSignIn } from 'src/app/hooks/useAuth';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';
import * as yup from 'yup';

import { AuthStateContext } from './AuthContext';
import { isCognitoError } from './types';

const {
  authorization: { code, invalidCode, codeExpired, confirmationCode, backToSignIn },
  common: { anUnknownErrorOccurred, confirm },
  formValidation: { isARequiredField },
} = copyText;

const initialValues = {
  code: '',
};

const validationSchema = yup.object({
  code: yup.string().required(replaceFieldInCopyText(isARequiredField, code)),
});

export const ConfirmationCode = ({ isLoading }: { isLoading: boolean }): JSX.Element => {
  const confirmSignIn = useConfirmSignIn();
  const { setAuthState } = useContext(AuthStateContext);
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const mounted = useUnmountPromise();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        await mounted(confirmSignIn(values.code));
      } catch (e) {
        if (isCognitoError(e)) {
          if (e.code === 'CodeMismatchException') {
            formik.setFieldError('code', invalidCode);
          } else if (e.code === 'NotAuthorizedException') {
            formik.setFieldError('code', codeExpired);
          } else {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(anUnknownErrorOccurred, { variant: 'error' });
        }
      }
    },
  });

  const goToLogin = () => {
    setAuthState('login');
  };

  return (
    <>
      <Typography variant="h5">{confirmationCode}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <TextField
            name="code"
            type="text"
            label={code}
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="one-time-code"
            error={formik.touched.code && !!formik.errors.code}
            helperText={formik.touched.code && formik.errors.code}
            autoFocus
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={goToLogin}>
              {backToSignIn}
            </Button>
            <LoadingButton
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
              color="primary"
              loading={formik.isSubmitting || isLoading}
              disabled={!formik.dirty || !formik.isValid}
            >
              {confirm}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <Helmet>
        <title>{confirmationCode}</title>
      </Helmet>
    </>
  );
};
