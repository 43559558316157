//
import request from './request';

/**
 * @module
 * @example import { Violation } from "services";
 */
export default class Violation {
  /**
   * Create a new violation tied to an account.
   */
  static create(accountID, details) {
    return request({
      method: 'POST',
      endpoint: `/accounts/${accountID}/violations`,
      body: details,
    }).then(() => undefined);
  }

  /**
   * Get violations of an account.
   */
  static get(accountID) {
    return request({ endpoint: `/accounts/${accountID}/violations` }).then(data => data.body);
  }

  /**
   * Delete a violation from an account.
   */
  static delete(accountID, violationID) {
    return request({
      method: 'DELETE',
      endpoint: `/accounts/${accountID}/violations/${violationID}`,
    }).then(() => undefined);
  }
}
