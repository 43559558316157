import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { fetch } from 'src/app/services';
import { z } from 'zod';

export const TaxLotSchema = z.object({
  created: z.coerce.date(),
  initialQuantity: z.number(),
  openQuantity: z.number(),
  effectiveCostPerShare: z.number(),
  side: z.enum(['S', 'B']),
  comment: z.string(),
  costBasis: z.number(),
});

export type TaxLot = z.infer<typeof TaxLotSchema>;

export const DetailPositionSchema = z.object({
  instrumentID: z.string(),
  symbol: z.string(),
  currency: z.string(),
  costBasis: z.number(),
  quantity: z.number(),
  currentMarketPrice: z.number(),
  currentMarketValue: z.number(),
  unrealizedPnL: z.number(),
  effectiveCostPerShare: z.number(),
  taxLots: TaxLotSchema.array(),
});

export type DetailPosition = z.infer<typeof DetailPositionSchema>;

export const PositionDetailsReturnValueSchema = z.object({
  accountID: z.string(),
  accountNo: z.string(),
  positions: DetailPositionSchema.array(),
});

export type PositionDetailsReturnValue = z.infer<typeof PositionDetailsReturnValueSchema>;

export const usePositionDetails = <T = PositionDetailsReturnValue>(
  { accountId }: { accountId: string },
  options?: UseQueryOptions<PositionDetailsReturnValue, Error, T>,
): UseQueryResult<T, Error> => {
  return useQuery<PositionDetailsReturnValue, Error, T>({
    queryKey: ['accounts', accountId, 'position-details'],
    queryFn: async ({ signal }) => {
      const endpoint = new URL(
        `/api/accounts/${accountId}/position-details`,
        window.location.origin,
      ).href;

      const positions = await fetch(endpoint, { signal });

      const positionsJson = await positions.json();

      return PositionDetailsReturnValueSchema.parse(positionsJson);
    },

    ...options,
  });
};
