import { useCallback, useState } from 'react';

export const useToggle = (initialState = false): [boolean, () => void, (bool: boolean) => void] => {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);
  // Define and memoize toggler function in case we pass down the component,
  // This function changes the boolean value to its opposite value
  const toggle = useCallback((): void => setState(sta => !sta), []);
  return [state, toggle, setState];
};
