import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { fetch } from 'src/app/services';

import { Partner } from '../types/partner';
import { useEnvironment } from './useEnvironment';

const endpoint = new URL('/api/dw-partners', window.location.origin).href;

export const usePartners = (options?: UseQueryOptions<Partner[]>): UseQueryResult<Partner[]> => {
  const { value: environment } = useEnvironment();
  const queryResult = useQuery<Partner[]>({
    queryKey: ['partners'],
    queryFn: async () => {
      const res = await fetch(endpoint, {
        headers: {
          accept: 'application/json',
        },
      });

      /**
       * @todo
       * @name Ryan Watkins-Hughes
       * @current FEC-2407
       * @description When this api call is rewritten in the java layer, we will need to update the following to
       * `return (await res.json()).data as Partner[];` as the returned value will be wrapped in a data object like
       * the other, newer java-based endpoints
       */
      return (await res.json()) as Partner[];
    },
    ...options,
  });

  // Filtering the partners to only match ones that exist in the current environment
  const filteredData = useMemo(
    () => queryResult.data?.filter(partner => partner.envs[environment]) ?? [],
    [environment, queryResult.data],
  );

  return {
    ...queryResult,
    data: filteredData,
  } as UseQueryResult<Partner[]>;
};
