import generateReducer from 'modules/generateReducer';

export const initialState = {
  byDescription: {},
  idToDescription: {},
  listsByCategory: {},
};

const handlers = {
  FINISH_GET_FIN_TRAN_TYPES: (state, action) => {
    const finTransTypesArray = action.payload.finTranTypesArray;
    const finTranTypes = {
      byDescription: {},
      idToDescription: {},
      listsByCategory: {},
    };

    for (const finTransElem of finTransTypesArray) {
      const wlpID = finTransElem.wlpID;
      const types = finTransElem.types;

      for (const type of types) {
        const description = type.description;
        const finTranTypeID = type.finTranTypeID;
        const batchCode = type.wlpFinTranTypeID;

        if (Object.keys(type).length > 0) {
          if (typeof finTranTypes.byDescription[description] === 'undefined') {
            finTranTypes.byDescription[description] = { ...type };
            finTranTypes.byDescription[description].wlpFinTranTypeID = {
              [wlpID]: batchCode,
            };
          } else {
            finTranTypes.byDescription[description].wlpFinTranTypeID[wlpID] = batchCode;
          }
        }

        if (description.length > 0) {
          finTranTypes.idToDescription[batchCode] = description;

          if (!finTranTypes.listsByCategory[finTranTypeID]) {
            finTranTypes.listsByCategory[finTranTypeID] = [];
          }
          if (!finTranTypes.listsByCategory[finTranTypeID].includes(type.description)) {
            finTranTypes.listsByCategory[finTranTypeID].push(type.description);
          }
        }
      }
    }

    return finTranTypes;
  },
  START_GET_FIN_TRAN_TYPES: null,
  GET_FIN_TRAN_TYPES_ERROR: null,
};

export default generateReducer(initialState, handlers);
