class Sessions {
  key;

  constructor() {
    this.key = null;
  }

  get() {
    return this.key;
  }

  save(key) {
    this.key = key;
  }

  remove() {
    this.key = null;
  }
}

export const sessions = new Sessions();
export default sessions;
