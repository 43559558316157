import { getPending, getPendingCounts } from '../internal/documents';
import Account from './Account';
import Document from './Document';
import request from './request';

/**
 * @module
 * @description The Users API allows you to create a multitude of users.
 * @example import { User } from "services";
 */
export default class User {
  /**
   * Create a new user.
   */
  static create(details) {
    return request({
      method: 'POST',
      endpoint: '/users',
      body: details,
    }).then(data => data.body);
  }

  /**
   * Get specific details about a particular user.
   */
  static get(userID) {
    return request({ endpoint: '/users/' + userID }).then(data => data.body);
  }

  /**
   * Edit information on a particular user.
   */
  static edit(userID, details) {
    return request({
      method: 'PATCH',
      endpoint: '/users/' + userID,
      body: details,
    }).then(data => data.body);
  }

  static resetPasswordCode(username) {
    return request({
      method: 'POST',
      endpoint: '/users/password-reset',
      body: { username },
    }).then(data => data.body);
  }

  static resetBackOfficePassword(code, password, generatedPasswordResetID) {
    return request({
      method: 'PUT',
      endpoint: `/users/password-reset/${generatedPasswordResetID}`,
      body: { code, password },
    }).then(data => data.body);
  }

  /**
   * @static
   */
  static getNotes(userID, offset, category, direction) {
    let endpoint = `/users/${encodeURIComponent(userID)}/notes?limit=21`;

    if (direction) {
      endpoint += `&direction=${encodeURIComponent(direction)}`;
    }
    if (offset) {
      endpoint += `&offset=${encodeURIComponent(offset)}`;
    }
    if (category) {
      endpoint += `&category=${encodeURIComponent(category)}`;
    }
    return request({
      endpoint,
    }).then(data => {
      // This is not being sorted on the back-end intuitively with previous, so fix it here for now
      if (direction !== 'prev') {
        return data.body;
      }

      return data.body.sort((a, b) => new Date(b.createdWhen) - new Date(a.createdWhen));
    });
  }

  /**
   * @static
   */
  static createNote(userID, note, subject, visibility, category) {
    return request({
      method: 'POST',
      endpoint: `/users/${userID}/notes`,
      body: {
        userID,
        subject,
        note,
        visibility,
        category,
      },
    }).then(data => data.body);
  }

  /**
   * Get accounts that belong to a user.
   */
  static getAccounts(userID) {
    return request({ endpoint: `/users/${userID}/accounts` }).then(data => data.body);
  }

  /**
   * Search for users based on one or more fields contained in a query object.
   */
  static advancedSearch(searchQuery) {
    return request({
      endpoint: `/users/search?${Object.keys(searchQuery)
        .filter(key => searchQuery[key])
        .map(key => `${key}=${encodeURIComponent(String(searchQuery[key]))}`)
        .join('&')}`,
    }).then(data => data.body.resultSet);
  }

  /**
   * @constant
   * @property {RegExp} accountNo
   * @property {RegExp} userID
   * @property {RegExp} accountID
   * @property {RegExp} phoneNumber
   * @property {RegExp} fullName
   */
  static REGEXPS = {
    accountNo: /^[A-Za-z]{4}\d{6}$/,
    userID: /^[A-Za-z\d]{8}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{12}$/,
    // eslint-disable-next-line max-len
    accountID: /^[A-Za-z\d]{8}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{4}-[A-Za-z\d]{12}\.\d{13}$/,
    phoneNumber: /^\d{4}$/,
    fullName: /(\S+) ((\S+ ?)+)/,
  };

  static getFieldsToSearch(rawQuery) {
    const fields = ['accountNo', 'userID', 'accountID', 'phoneNumber', 'fullName'];

    for (const field of fields) {
      if (User.REGEXPS[field].test(rawQuery)) {
        return [field];
      }
    }

    return ['username', 'firstName', 'lastName', 'email'];
  }

  static getSearchQuery(rawQuery) {
    return User.getFieldsToSearch(rawQuery).reduce(
      (searchQuery, field) => ({
        ...searchQuery,
        [field]: rawQuery,
      }),
      {},
    );
  }

  /**
   * Search for users.
   *
   * @param {string} rawQuery can be a userID, accountNo, accountID,
   * phoneNumber, fullName, username, firstName, lastName, or email.
   */
  static search(rawQuery) {
    const searchQuery = User.getSearchQuery(rawQuery);

    // each type of query may use a different request method
    const requests = Object.keys(searchQuery).map(field => {
      const value = searchQuery[field].trim();
      switch (field) {
        case 'userID':
          return User.get(value);

        case 'accountNo':
          return Account.getByAccountNo(value).then(account => User.get(account.userID));

        case 'accountID':
          return Account.get(value).then(account => User.get(account.userID));

        case 'fullName': {
          const [first, last] = value.split(/\s/);

          return request({
            endpoint: `/users/search?firstName=${first}&lastName=${last}`,
          }).then(data => data.body.resultSet);
        }

        case 'username':
        case 'firstName':
        case 'lastName':
        case 'phoneNumber':
        case 'email':
          return request({
            endpoint: `/users/search?${field}=${value}`,
          }).then(data => data.body.resultSet);

        default: {
          throw new Error(`Attempted to search for an unknown query type (${field})`);
        }
      }
    });

    return Promise.all(requests).then(responses => {
      // transform nested array into single-level array
      const results = responses.reduce(
        (acc, next) => [...acc, ...(Array.isArray(next) ? next : [next])],
        [],
      );

      // remove duplicate results by converting from array to object and back to array
      const resultsByID = results.reduce((acc, next) => {
        // Can we simplify this type Couldn't find a way to make it simpler in Flow
        const userID =
          typeof next.id === 'string'
            ? next.id
            : typeof next.userID === 'string'
            ? next.userID
            : '';

        return Object.assign({}, acc, { [userID]: next });
      }, {});
      return Object.keys(resultsByID).map(key => resultsByID[key]);
    });
  }

  /**
   * Get documents that belong to a user.
   */
  static getDocuments(userID) {
    return request({
      endpoint: `/users/${userID}/documents`,
    }).then(data => data.body);
  }

  // --------------------------------------- KYC REQUEST --------------------------------------- //

  /**
   * Get kyc info of a user.
   */
  static getKycInfo(userID) {
    return request({
      endpoint: `/users/${userID}/kyc-status`,
    }).then(data => data.body);
  }

  /* KYC VERIFICATION: */

  static verifyKyc(userID, documents, docKYC, isWatchlist) {
    const body = {
      userID,
      watchlist: isWatchlist,
    };

    if (docKYC) {
      body.documentIDs = documents;
      body.kycDoc = true;
      body.kycNonDoc = false;
    } else {
      body.kycDoc = false;
      body.kycNonDoc = true;
    }

    return request({
      method: 'POST',
      endpoint: '/kyc/verification',
      body,
    }).then(data => data.body);
  }

  // ------------------------------------------------------------------------------------------- //

  /**
   * Creates a new document from a base64 image string or a JavaScript Image file.
   */
  static createDocument(userID, type, image) {
    return Document.create(userID, type, image);
  }

  /**
   * @static
   */
  static openDocument(documentID) {
    return Document.getURL(documentID);
  }

  /**
   * static
   */
  static getKYCUsers() {
    return request({
      endpoint: '/users/kyc-exception',
    }).then(data => data.body);
  }

  /**
   * @static
   */
  static patchKYCUsers(method, ...userIDs) {
    return (
      Promise.all(
        userIDs.map(userID =>
          request({
            endpoint: `/users/kyc-exception/${userID}`,
            method: 'PATCH',
            body: {
              method: method.toUpperCase(),
            },
          }),
        ),
      )
        // if the method declaration says it returns void, it should
        .then(() => {})
    );
  }

  /**
   * @static
   */
  static getPendingCounts() {
    return getPendingCounts('users');
  }

  /**
   * @static
   * @param query
   */
  static getPending(query) {
    return getPending('nextPendingUserApproval', query);
  }

  /**
   * @static
   */
  static review(userID) {
    return request({
      method: 'POST',
      endpoint: '/users/review',
      body: { userID },
    }).then(() => undefined);
  }

  /**
   * @static
   */
  static updateUserRole(permissionGroupID, userID) {
    const type = 'PERMISSIONS_INFO';
    const body = {
      documents: [
        {
          type,
          data: {
            id: permissionGroupID,
          },
        },
      ],
    };

    return request({
      method: 'PATCH',
      endpoint: `/users/${userID}`,
      body,
    }).then(data => data.body);
  }
}
