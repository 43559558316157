import { Box, MenuItem } from '@drivehub/ui-components';
import CheckIcon from '@mui/icons-material/Check';
import { isUndefined } from 'lodash';
import { useEnvironment, useWhoAmI } from 'src/app/hooks';
import { WhoAmI } from 'src/app/types';
import styled from 'styled-components';

import { copyText } from '../../internationalization';
import { getColor } from '../../utils';

const StyledEnvironmentMenuItem = styled(MenuItem)`
  height: unset;
  line-height: 16px;

  && {
    width: 100%;
  }
`;

const SubText = styled.div`
  color: ${getColor('corePrimary5')};
`;

const Role = styled.span`
  font-weight: 500;
`;

const ActiveCheckMarkContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: ${props => props.theme.spacing(1)};

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`;

const getRoleForEnvironment = (envId: string, whoAmI: WhoAmI | undefined) => {
  if (isUndefined(whoAmI)) {
    return '';
  }

  return whoAmI.role.datasrcPermissionGroups[envId]?.role;
};

interface EnvironmentMenuItemProps {
  id: string;
  label: string;
  description: string;
  onClick: (id: string) => void;
  disabled?: boolean;
}

export const EnvironmentMenuItem = ({
  description,
  disabled,
  id,
  label,
  onClick,
}: EnvironmentMenuItemProps): JSX.Element => {
  const { data } = useWhoAmI();
  const role = getRoleForEnvironment(id, data);
  const activeEnv = useEnvironment();

  const handleClick = () => {
    onClick(id);
  };

  const isActive = activeEnv.value === id;

  const {
    navbar: {
      environmentSelector: { yourRole },
    },
  } = copyText;

  const roleLabel = `${yourRole}: `;

  return (
    <StyledEnvironmentMenuItem onClick={handleClick} selected={isActive} disabled={disabled}>
      <Box display="flex" flexDirection="row" justifyContent="space-between" flexGrow={1}>
        <Box display="flex" flexDirection="column">
          <div>{label}</div>
          <SubText>{description}</SubText>
          <SubText>
            {roleLabel}
            <Role>{role}</Role>
          </SubText>
        </Box>
        {isActive && (
          <ActiveCheckMarkContainer>
            <CheckIcon />
          </ActiveCheckMarkContainer>
        )}
      </Box>
    </StyledEnvironmentMenuItem>
  );
};
