import immer from 'immer';
import generateReducer from 'modules/generateReducer';
import { configs } from 'src/legacy/routes/Batch/configs';

export const initialState = {
  fileName: '',
  batchDetails: {},
  batchList: [],
  processingBatches: [],
  processedBatches: [],
  batchType: '',
  batchAs: null,
  transformed: [],
  results: {
    cleaned: [],
    errors: [],
  },
};

const handlers = {
  START_GET_BATCH_LIST: null,
  ERROR_GET_BATCH_LIST: null,
  PARSE_FILE_ERROR: null,
  BATCH_LIST_SUCCESS: (state, action) => {
    const batchList = action.payload.list;
    const processing = batchList.filter(
      batch => batch.status.includes('PROCESSED') || batch.status.includes('NOT_STARTED'),
    );
    const processed = batchList.filter(
      batch => !(batch.status.includes('PROCESSED') || batch.status.includes('NOT_STARTED')),
    );

    return immer(state, draft => {
      draft.batchList = batchList;
      draft.processingBatches = processing;
      draft.processedBatches = processed;
    });
  },
  UPDATE_PROCESSING_LIST_SUCCESS: (state, action) => {
    const batchList = action.payload.list;
    const processing = batchList.filter(
      batch => batch.status.includes('PROCESSED') || batch.status.includes('NOT_STARTED'),
    );
    const processed = batchList.filter(
      batch => !(batch.status.includes('PROCESSED') || batch.status.includes('NOT_STARTED')),
    );

    return immer(state, draft => {
      draft.processingBatches = processing;
      draft.processedBatches = processed;
    });
  },
  BATCH_PARSE_START: null,
  BATCH_PARSE_SAVE_RESULTS: (state, action) =>
    immer(state, draft => {
      const { batchType, results, transformed, file } = action.payload;
      const { batchAs } = configs[batchType];
      draft.batchType = batchType;
      if (batchAs) {
        draft.batchAs = batchAs;
      }
      draft.results = results;
      draft.transformed = transformed;
      draft.fileName = batchAs ? `${batchType}-${file.name}` : file.name;
    }),
  COMPUTE_ACCOUNT_BALANCES: (state, action) =>
    immer(state, draft => {
      const startingIndex = draft.transformed.findIndex(
        row => row.accountNo === action.payload.accountNo,
      );
      let currentBalance = action.payload.startingBalance;
      for (let i = startingIndex + 1; i < draft.transformed.length; i++) {
        if (draft.transformed[i].accountNo !== action.payload.accountNo) break;
        const amount = draft.transformed[i].amount;
        currentBalance += amount;
        draft.transformed[i].balance = currentBalance;
      }
    }),
  CANCEL_UPLOAD: (state, action) =>
    immer(state, draft => {
      draft.fileName = '';
      draft.batchType = '';
      draft.transformed = [];
      draft.results = {
        cleaned: [],
        errors: [],
      };
    }),
  START_SUBMIT: null,
  START_GET_DETAILS: null,
  BATCH_DETAILS_SUCCESS: (state, action) =>
    immer(state, draft => {
      draft.batchDetails[action.payload.details.batchID] = action.payload.details;
    }),
  BATCH_DETAILS_ERROR: null,
};

export default generateReducer(initialState, handlers);
