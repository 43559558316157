//
import request from '../modules/request';

export const getPendingCounts = path =>
  request({
    endpoint: `/${path}/getPendingCounts`,
  }).then(data => data.body);

export const getPending = (path, query) => {
  const queryString = Array.isArray(query) ? `languageList=${query.join(',')}` : `userID=${query}`;

  return request({
    endpoint: `/users/${path}?${queryString}`,
  }).then(data =>
    Object.assign({}, data.body, {
      ttl: Number(data.headers['dw-reviewer-ttl']),
    }),
  );
};
