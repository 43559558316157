import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { fetch } from 'src/app/services';

import { APIPermissionGroup } from '../types/permission';

const endpoint = new URL('/api/manage-permission-groups', window.location.origin).href;

export const useAddPermissionGroup = (): UseMutationResult<
  APIPermissionGroup,
  { errorCode: number; message: string },
  APIPermissionGroup
> => {
  const queryClient = useQueryClient();

  const mutationFn = async (variables: APIPermissionGroup) => {
    const permissionCreateVariables: APIPermissionGroup = {
      active: variables.active,
      description: variables.description,
      permissionList: variables.permissionList,
      role: variables.role,
    };

    const data = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(permissionCreateVariables),
      headers: {
        'Content-type': 'application/json',
      },
    });

    return (await data.json()).data as APIPermissionGroup;
  };

  return useMutation<
    APIPermissionGroup,
    { errorCode: number; message: string },
    APIPermissionGroup
  >({
    mutationFn,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['manage-permission-groups'] });
    },
  });
};
