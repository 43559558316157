const initialState = {
  currentProduct: null,
  showModal: false,
};

export function productsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'OPEN_EDIT_PRODUCT_MODAL':
      return { ...state, currentProduct: payload.product, showModal: true };

    case 'CLOSE_EDIT_PRODUCT_MODAL':
      return { ...state, currentProduct: null, showModal: false };

    default:
      return state;
  }
}
