/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

import { AllViewBatchPermissions } from 'src/app/constants';

const cashTransfer = {
  displayName: 'Cash Transfer',
  permission: AllViewBatchPermissions.CASH_TRANSFER_CREATE_BATCH_VIEW,
  csvInputs: {
    status: {
      label: 'Status',
      textAlign: 'center',
      canSort: false,
      required: false,
      dataType: 'status',
      serverKey: 'status',
    },
    statusmsg: {
      label: 'Status Message',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'statusMsg',
    },
    accountfrom: {
      label: 'Account From',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountFrom',
    },
    accountto: {
      label: 'Account To',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'accountTo',
    },
    cash: {
      label: 'Cash',
      textAlign: 'right',
      canSort: false,
      required: true,
      dataType: 'money',
      serverKey: 'cash',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'comment',
    },
    dnb: {
      label: 'Do Not Batch',
      textAlign: 'center',
      canSort: false,
      required: true,
      dataType: 'boolean',
      serverKey: 'dnb',
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      accountFrom: 'QWERTY12345',
      accountTo: 'BIRDY123456',
      cash: 5,
      comment: 'Some comment',
      dnb: 'true',
    },
  ],
};

export { cashTransfer };
