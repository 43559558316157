const shapeIdToSymbol = instruments => {
  const map = instruments.reduce((acc, current) => {
    acc[current.id] = { symbol: current.symbol, name: current.name };
    return acc;
  }, {});
  return map;
};

const shapeSymbolToId = instruments => {
  const map = instruments.reduce((acc, current) => {
    acc[current.symbol] = { id: current.id, name: current.name };
    return acc;
  }, {});
  return map;
};

export { shapeIdToSymbol, shapeSymbolToId };
