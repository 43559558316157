import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { fetch } from '../../services';
import { KycStatusResponse } from '../../types';
import { KycStatusAPIResponse } from './useUserKycStatus';
import { transformKycStatus } from './utils';

type KycStatusRequest = {
  kycDoc: boolean;
  kycNonDoc: boolean;
  documentIDs?: object | null;
  userID: string;
  watchList: boolean;
};

const endpoint = new URL('/api/kyc/verification', window.location.origin).href;

export const useVerification = (): UseMutationResult<
  KycStatusResponse,
  Error,
  KycStatusRequest
> => {
  const queryClient = useQueryClient();
  return useMutation<KycStatusResponse, Error, KycStatusRequest>({
    mutationFn: async variables => {
      const res = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(variables),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const data = (await res.json()) as KycStatusAPIResponse;

      return transformKycStatus(data);
    },

    onSuccess: (data, variables) => {
      if (!data) return;
      queryClient.setQueryData(['users', variables.userID, 'kyc-status'], data);
    },
  });
};
