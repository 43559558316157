import { AuthStateContext } from './context';
import { useAuthState } from './useAuthState';

export type AuthStateProviderProps = {
  children: React.ReactNode;
};

export const AuthStateProvider = ({ children }: AuthStateProviderProps): JSX.Element => {
  const authState = useAuthState();

  return <AuthStateContext.Provider value={authState}>{children}</AuthStateContext.Provider>;
};
