/*
    displayName - batch name to be used for labels etc.
    permission - permissions needed to create this batch type
    csvInputs - has all the columns used for the Preview and the Details
    csvInputs.COLUMNNAME - the column name MUST be all lowercase
    csvInputs.COLUMNNAME.label - label for the column
    csvInputs.COLUMNNAME.textAlign - how to align the data in the column [left | center | right]
    csvInputs.COLUMNNAME.canSort - should this column be sortable
    csvInputs.COLUMNNAME.required - block upload if this column is missing
    csvInputs.COLUMNNAME.dataType - data type of the column for display [string, number, boolean, money, status]
    csvInputs.COLUMNNAME.serverKey - name of the column as it must be for the server (typically camel cased)
    groupPreviewBy - either a empty string or the name of the column by which to group all the rows
    exampleData - array of objects to populate the example data table (Create Batch screen)
*/

const cancelRebill = {
  displayName: 'Cancel Rebill',
  permission: 'CANCEL_REBILL_CREATE_BATCH',
  csvInputs: {
    orderno: {
      label: 'Order No',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'orderNo',
    },
    type: {
      label: 'Type',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'type',
    },
    reason: {
      label: 'Reason',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'reason',
    },
    approvedby: {
      label: 'Approved By',
      textAlign: 'left',
      canSort: false,
      required: true,
      dataType: 'string',
      serverKey: 'approvedBy',
    },
    erroraccountno: {
      label: 'Error Account No',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'errorAccountNo',
    },
    donotsendnotification: {
      label: 'Do Not Send Notification',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'boolean',
      serverKey: 'doNotSendNotification',
    },
    comment: {
      label: 'Comment',
      textAlign: 'left',
      canSort: false,
      required: false,
      dataType: 'string',
      serverKey: 'comment',
    },
    batchenabled: {
      label: 'Batch Enabled',
      textAlign: 'center',
      canSort: false,
      required: true,
      dataType: 'boolean',
      serverKey: 'batchEnabled',
    },
  },
  checks: {
    columns: {
      batchEnabled: { value: true },
    },
  },
  groupPreviewBy: '',
  doAccumulatingBalance: false,
  exampleData: [
    {
      orderNo: 'KJSW000002',
      type: 'CANCEL',
      reason: 'FRAUD',
      approvedBy: 'UB',
      errorAccountNo: '88ZF000005',
      doNotSendNotification: 'true',
      comment: 'This request is for CANCEL',
      batchEnabled: 'true',
    },
  ],
};

export { cancelRebill };
