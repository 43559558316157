const addRecordToProcessedRecords = (records, recordType, record) => {
  records[recordType][record.accountNo] = record;
  return records;
};

const getSeparatedRowTypes = processed =>
  processed.reduce(
    (rowTypes, record) => {
      if (record.isHeader) {
        rowTypes.header.push(record);
        return rowTypes;
      }
      rowTypes.row.push(record);
      return rowTypes;
    },
    { header: [], row: [] },
  );

const getSeparatedProcessedAccounts = separatedRowTypes =>
  separatedRowTypes.header.reduce(
    (processedRecords, record) => {
      if (record.negativeBalance) {
        return addRecordToProcessedRecords(processedRecords, 'balanceErrorAccounts', record);
      }

      if (record.accountError) {
        return addRecordToProcessedRecords(processedRecords, 'accountErrorAccounts', record);
      }

      return addRecordToProcessedRecords(processedRecords, 'positiveAccounts', record);
    },
    {
      balanceErrorAccounts: {},
      positiveAccounts: {},
      accountErrorAccounts: {},
    },
  );

export const getSeparatedRecords = processed => {
  const separatedRowTypes = getSeparatedRowTypes(processed);
  const separatedProcessedAccounts = getSeparatedProcessedAccounts(separatedRowTypes);
  return processed.reduce(
    (processedRecords, record) => {
      if (separatedProcessedAccounts.balanceErrorAccounts[record.accountNo]) {
        processedRecords.balanceErrors.push(record);
        return processedRecords;
      }

      if (separatedProcessedAccounts.accountErrorAccounts[record.accountNo]) {
        processedRecords.accountErrors.push(record);
        return processedRecords;
      }

      processedRecords.successes.push(record);
      return processedRecords;
    },
    {
      successes: [],
      balanceErrors: [],
      accountErrors: [],
    },
  );
};
