//
import ExtendableError from 'extendable-error-class';

/**
 * @class DriveWealthError
 */
export class DriveWealthError extends ExtendableError {
  constructor(message, body, statusCode, headers, errorCode) {
    super(message);
    this.body = body;
    this.statusCode = statusCode;
    this.headers = headers;
    this.errorCode = errorCode;
  }
}

/**
 * @class DriveWealthSessionError
 */
export class DriveWealthSessionError extends ExtendableError {
  constructor(message, body, statusCode, headers, errorCode) {
    super(message);
    this.body = body;
    this.statusCode = statusCode;
    this.headers = headers;
    this.errorCode = errorCode;
  }
}

export default {
  DriveWealthError,
  DriveWealthSessionError,
};
