import waterfall from 'async/waterfall';
import { ACCOUNT_SUMMARY_REQUEST_BATCH_SIZE } from 'common/constants/account';
import batchUtils from 'common/utils/batch';
import immer, { produce } from 'immer';
import _ from 'lodash';

import request from './request';
import Violation from './Violation';

const { combinePreviousResponsesAndCurrentResponse } = batchUtils;

const replaceIndividualCasing = account => {
  if (account?.taxProfile?.taxRecipientCode === 'Individual') {
    return produce(account, draft => {
      draft.taxProfile.taxRecipientCode = 'INDIVIDUAL';
    });
  }

  return account;
};

/**
 * @module
 * @description Accounts are assigned to a user and a user can have many accounts. There are a
 * few attributes that define the account, how and when it can trade and who is trading it.
 * @example import { Account } from "services";
 */
export default class Account {
  /**
   * @constant
   * @property {string} CASH
   * @property {string} ORDERS
   * @property {string} TRANSACTIONS
   * @property {string} EQUITY
   * @property {string} VIOLATIONS
   * @property {string} ALL
   */
  static BLOTTER_TYPES = {
    CASH: 'cash',
    ORDERS: 'orders',
    TRANSACTIONS: 'transactions',
    EQUITY: 'equity',
    VIOLATIONS: 'violations',
    ALL: null,
  };

  /**
   * Create a new account tied to a user.
   */
  static create(userID, details) {
    return request({
      method: 'POST',
      endpoint: '/accounts',
      body: {
        ...details,
        userID,
      },
    }).then(data => replaceIndividualCasing(data.body));
  }

  /**
   * Get account by accountID.
   */
  static get(accountID) {
    return request({
      endpoint: `/accounts/${accountID}`,
    }).then(data => {
      // Crypto vs Equity account differentiation
      if (data.body?.account) {
        return replaceIndividualCasing(data.body.account);
      }
      return data.body;
    });
  }

  /**
   * Get account by accountNo.
   */
  static getByAccountNo(accountNo) {
    return request({
      endpoint: `/accounts/search?accountNo=${accountNo.toUpperCase()}`,
    })
      .then(data => data.body.result)
      .catch(error => error);
  }

  static async getAccountDetails(accountNumbers) {
    const response = await request({
      method: 'POST',
      endpoint: '/accounts/details',
      body: { accountNumbers },
    });
    return response.body;
  }

  /**
   * Get account by accountNo.
   */
  static async getSummariesForEachAccount(accounts) {
    const accountList = Object.keys(accounts);

    const functions = _.chunk(accountList, ACCOUNT_SUMMARY_REQUEST_BATCH_SIZE).map(
      (list, chunkIndex) => {
        if (!chunkIndex) {
          return function (callback) {
            request({
              method: 'POST',
              endpoint: '/summaries',
              body: {
                type: 'money',
                accounts: list.map(accountNo => ({
                  accountID: accounts[accountNo].accountID,
                  accountNo,
                })),
              },
            }).then(response =>
              callback(null, combinePreviousResponsesAndCurrentResponse(response.body)),
            );
          };
        }
        return function (previous, callback) {
          request({
            method: 'POST',
            endpoint: '/summaries',
            body: {
              type: 'money',
              accounts: list.map(accountNo => ({
                accountID: accounts[accountNo].accountID,
                accountNo,
              })),
            },
          }).then(response => {
            return callback(
              null,
              combinePreviousResponsesAndCurrentResponse(response.body, previous),
            );
          });
        };
      },
    );

    const result = await waterfall(functions);

    return [...result.successes, ...result.failed];
  }

  /**
   * @static
   */
  static edit(accountID, details) {
    return request({
      method: 'PATCH',
      endpoint: `/accounts/${accountID}`,
      body: details,
    }).then(data => replaceIndividualCasing(data.body));
  }

  /**
   * Get Account Summary. Optionally retrieve specific data with a [Blotter Type](#blottertypes).
   */
  static getBlotter(accountID, type) {
    return request({
      method: 'GET',
      endpoint: `/accounts/${accountID}/summary`,
    }).then(({ body: { accountSummary } }) => (type ? accountSummary[type] : accountSummary));
  }

  static getAccountOrders(accountID) {
    return request({
      method: 'GET',
      endpoint: `/accounts/${accountID}/summary/orders`,
    }).then(data => data.body);
  }

  static getAccountPositions(accountID) {
    return request({
      method: 'GET',
      endpoint: `/accounts/${accountID}/summary/positions`,
    }).then(data => data.body);
  }

  static getAccountMoney(accountID) {
    return request({
      method: 'GET',
      endpoint: `/accounts/${accountID}/summary/money`,
    }).then(data => data.body);
  }

  /**
   * Transfer positions and/or cash from one account to another.
   */
  static transfer(details) {
    return request({
      method: 'POST',
      endpoint: '/accounts/transfer',
      body: {
        ...details,
        accountFrom: details.accountFrom.toUpperCase(),
        accountTo: details.accountTo.toUpperCase(),
      },
    }).then(data => data.body);
  }

  /**
   * @static
   */
  static createViolation(accountID, details) {
    return Violation.create(accountID, details);
  }

  /**
   *
   * @static
   */
  static getViolations(accountID) {
    return Violation.get(accountID);
  }

  /**
   * @static
   */
  static deleteViolation(accountID, violationID) {
    return Violation.delete(accountID, violationID);
  }

  /**
   * @static
   */
  static updateCommission(accountID, commissionID) {
    return request({
      endpoint: `/accounts/${accountID}/commissions`,
      method: 'POST',
      body: { commissionID },
    }).then(data => data.body.account);
  }

  /**
   * @static
   */
  static getMarginCallList() {
    return request({
      endpoint: '/accounts/margin-call',
    }).then(data => data.body);
  }

  /**
   * @static
   */
  static getMarginCallAccountSummaries() {
    return Account.getMarginCallList().then(async accounts => {
      const marginSummaries = await request({
        endpoint: `/summaries`,
        method: 'POST',
        body: {
          accounts,
          type: 'margin',
          timeout: 10000,
        },
      });

      const positionsSummary = await request({
        endpoint: `/summaries`,
        method: 'POST',
        body: {
          accounts,
          type: 'positions',
          timeout: 10000,
        },
      });

      const summaries = marginSummaries.body.successes.map((summary, idx) => ({
        ...summary,
        equity: positionsSummary.body.successes[idx],
      }));

      return { accounts, summaries: summaries };
    });
  }

  /**
   * @static
   */
  static editPosition(accountID, symbol, qty, price) {
    return request({
      endpoint: `/accounts/${accountID}/positions`,
      method: 'PATCH',
      body: {
        symbol,
        quantityAdjustment: qty,
        price,
      },
    });
  }

  // Lock/unlock a position:
  static lockUnlockPosition(accountID, body) {
    return request({
      endpoint: `/accounts/${accountID}/lock-unlock-positions`,
      method: 'PATCH',
      body,
    });
  }

  // I know Object is very bad type
  static getPortfolioStatus(accountID) {
    return request({
      endpoint: `/accounts/${accountID}/portfolio`,
    }).then(data => data.body);
  }

  /**
   * @static
   * Get Account Beneficiaries [] | { errorCode, message }
   */
  static getAccountBeneficiaries(accountID) {
    return request({
      endpoint: `/accounts/${accountID}/beneficiaries`,
    }).then(data => data.body);
  }

  /**
   * @static
   * Create Account Beneficiaries
   */
  static createAccountBeneficiaries(accountID, beneficiaries) {
    return request({
      method: 'POST',
      endpoint: `/accounts/${accountID}/beneficiaries`,
      body: beneficiaries,
    }).then(data => {
      return data.body;
    });
  }

  /**
   * @static
   * Delete Account Beneficiaries
   */
  static deleteAccountBeneficiaries(accountID) {
    return request({
      method: 'DELETE',
      endpoint: `/accounts/${accountID}/beneficiaries`,
    }).then(data => data.body); // returns empty 204 | error - will that hit the then or the catch?
  }

  /**
   * @static
   * Create Deposit
   */
  static createDeposit(accountID, options) {
    const body = { ...options, currency: 'USD', accountID };
    return request({
      method: 'POST',
      endpoint: '/funding/deposits',
      body,
    }).then(data => data.body); // returns empty 204 | error - will that hit the then or the catch?
  }

  static getFundingData(accountID, name, options) {
    let endpoint =
      name === 'Deposit'
        ? `/accounts/${accountID}/funding/deposits`
        : `/accounts/${accountID}/funding/redemptions`;

    if (options && options.isPending) {
      endpoint += '?status=pending';
    }
    return request({
      endpoint,
    }).then(data => data.body.map(item => setPaymentType(item, name)));
  }

  static getPayments(accountID, options) {
    return this.getFundingData(accountID, 'Deposit', options);
  }

  static getRedemptions(accountID, options) {
    return this.getFundingData(accountID, 'Redemption', options);
  }

  static getPaymentsAndRedemptions(accountID, options, shouldChangeStatusMessage = true) {
    const payments = async () => await this.getPayments(accountID, options);
    const redemptions = async () => await this.getRedemptions(accountID, options);
    return Promise.all([payments(), redemptions()]).then(results => {
      const [payments, redemptions] = results;
      const setSeasoningDetail = getSeasoningDetailSetter(shouldChangeStatusMessage);
      const data = [...payments, ...redemptions].map(setSeasoningDetail);
      data.sort(sortPaymentType);
      return data;
    });
  }
}

function getSeasoningDetailSetter(shouldChangeStatusMessage) {
  return item => {
    const seasoningPeriodExpires =
      item.seasoningDetails && item.seasoningDetails.seasoningPeriodExpires
        ? item.seasoningDetails.seasoningPeriodExpires
        : '';

    const seasoningStatusMessage =
      item.seasoningDetails && item.seasoningDetails.statusMessage
        ? item.seasoningDetails.statusMessage
        : '';

    return immer(item, draft => {
      draft.seasoningPeriodExpires = seasoningPeriodExpires;
      draft.seasoningStatusMessage = seasoningStatusMessage;
      if (draft.status.message === 'Failed' && shouldChangeStatusMessage) {
        draft.status.message = 'Rejected';
      }
    });
  };
}

function sortPaymentType(a, b) {
  if (a.timestamp < b.timestamp) return 1;
  if (a.timestamp > b.timestamp) return -1;
  return 0;
}

function setPaymentType(data, paymentType) {
  return immer(data, draft => {
    draft.paymentType = paymentType;
  });
}
