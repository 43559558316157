import { createContext } from 'react';

import { UseAuthStateReturnValue } from './useAuthState';

export const AuthStateContext = createContext<UseAuthStateReturnValue>({
  setAuthState: () => {},
  authState: 'login',
  setUsername: () => {},
  username: null,
});
