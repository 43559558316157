import immer from 'immer';
import generateReducer from 'modules/generateReducer';

export const initialState = {
  bankAccountsByUserID: {},
};

const handlers = {
  // get all users bank accounts actions
  GET_USERS_BANK_ACCOUNTS_START: null,
  GET_USERS_BANK_ACCOUNTS_ERROR: null,
  GET_USERS_BANK_ACCOUNTS_SUCCESS: (state, action) =>
    immer(state, draftState => {
      const userBankAccounts = action.payload.bankAccounts.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      draftState.bankAccountsByUserID[action.payload.userID] = userBankAccounts;
    }),

  // get a single bank account actions
  GET_BANK_ACCOUNT_START: null,
  GET_BANK_ACCOUNT_ERROR: null,
  GET_BANK_ACCOUNT_SUCCESS: (state, action) =>
    immer(state, draftState => {
      if (draftState.bankAccountsByUserID[action.payload.userID]) {
        draftState.bankAccountsByUserID[action.payload.userID][action.payload.bankAccount.id] =
          action.payload.bankAccount;
      } else {
        draftState.bankAccountsByUserID[action.payload.userID] = {
          [action.payload.bankAccount.id]: action.payload.bankAccount,
        };
      }
    }),

  // delete a bank accounts actions
  DELETE_BANK_ACCOUNT_START: null,
  DELETE_BANK_ACCOUNT_ERROR: null,
  DELETE_BANK_ACCOUNT_SUCCESS: null,
};

export default generateReducer(initialState, handlers);
