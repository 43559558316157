import { Box, Button, LoadingButton, TextField } from '@drivehub/ui-components';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { isNull } from 'lodash';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useUnmountPromise } from 'react-use';
import { useSnackbar } from 'src/app/hooks';
import { useForgotPassword, useResetPassword } from 'src/app/hooks/useAuth';
import { copyText, replaceFieldInCopyText } from 'src/app/internationalization';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import { AuthStateContext } from './AuthContext';
import { isCognitoError } from './types';

YupPassword(yup);

const {
  formValidation: {
    isARequiredField,
    minLowercaseOne,
    minUppercaseOne,
    minNumberOne,
    min16Characters,
  },
  common: { anUnknownErrorOccurred, submit },
  authorization: {
    code,
    enterCode,
    password,
    invalidCode,
    codeExpired,
    passwordRequirements,
    resendCode: resendCodeCopy,
    resetYourPassword,
  },
} = copyText;

const initialValues = {
  code: '',
  password: '',
};

const validationSchema = yup.object({
  code: yup.string().required(replaceFieldInCopyText(isARequiredField, code)),
  password: yup
    .string()
    .password()
    .required(replaceFieldInCopyText(isARequiredField, password))
    .minLowercase(1, replaceFieldInCopyText(minLowercaseOne, password))
    .minUppercase(1, replaceFieldInCopyText(minUppercaseOne, password))
    .minNumbers(1, replaceFieldInCopyText(minNumberOne, password))
    .minSymbols(0)
    .min(16, replaceFieldInCopyText(min16Characters, password)),
});

export const ResetPassword = (): JSX.Element => {
  const resetPassword = useResetPassword();
  const forgotPassword = useForgotPassword();
  const { username, setAuthState } = useContext(AuthStateContext);
  const { enqueueSnackbar } = useSnackbar({ clearOnNavigate: true });
  const mounted = useUnmountPromise();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      if (!username) {
        throw new Error('Auth is in a bad state');
      }

      try {
        await mounted(resetPassword({ username, ...values }));
        setAuthState('login');
      } catch (e) {
        if (isCognitoError(e)) {
          if (e.code === 'CodeMismatchException') {
            formik.setFieldError('code', invalidCode);
          } else if (e.code === 'NotAuthorizedException') {
            formik.setFieldError('code', codeExpired);
          } else {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        } else {
          enqueueSnackbar(anUnknownErrorOccurred, { variant: 'error' });
        }
      }
    },
  });

  const resendCode = () => {
    if (!username) {
      throw new Error('Auth is in a bad state');
    }

    forgotPassword({ username });
  };

  const passwordHelperText =
    (formik.touched.password && formik.errors.password) || passwordRequirements;

  return (
    <>
      <Typography variant="h5">{resetYourPassword}</Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">{enterCode}</Typography>
          <TextField
            label={code}
            name="code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="one-time-code"
            error={formik.touched.code && !!formik.errors.code}
            helperText={formik.touched.code && formik.errors.code}
            autoFocus
          />
          <TextField
            label={password}
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            margin="normal"
            required
            autoComplete="new-password"
            error={formik.touched.password && !!formik.errors.password}
            helperText={passwordHelperText}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="tertiary" onClick={resendCode}>
              {resendCodeCopy}
            </Button>
            <LoadingButton
              sx={{ ml: 1 }}
              disabled={!isNull(username) && (!formik.dirty || !formik.isValid)}
              type="submit"
              variant="contained"
              color="primary"
              loading={formik.isSubmitting}
            >
              {submit}
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <Helmet>
        <title>{resetYourPassword}</title>
      </Helmet>
    </>
  );
};
